import * as Yup from 'yup';

import type { InferType } from 'yup';

export const BlockchainNetworkSchema = Yup.object({
  name: Yup.string().required('El nombre es obligatorio'),
  chainId: Yup.string().required('El chainId es obligatorio'),
  nativeCurrencySymbol: Yup.string().required('El nativeCurrencySymbol es obligatorio'),
  networkIconUrl: Yup.string().required('El network icon url es obligatorio'),
  blockExplorers: Yup.array()
    .of(Yup.string())
    .min(1, 'Debe agregar al menos un block explorer')
    .required('El campo block explorers es obligatorio'),
  faucets: Yup.array().of(Yup.string()).nullable(),
  rpcUrl: Yup.string().nullable(),
  isTestnet: Yup.boolean().default(false)
})


export type BlockchainNetworkData = InferType<typeof BlockchainNetworkSchema>;

import { Box, Center, Heading, Spinner, VStack } from "@chakra-ui/react";

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Form from "./Form";
import useBlockchainNetwork from "../../hooks/useBlockchainNetwork";

const EditBlockchainNetwork = () => {
  const params = useParams();
  const id = params.id!
  const { editBlockchainNetwork, getBlockchainNetwork, blockchainNetwork, isLoading } = useBlockchainNetwork();

  const onSubmit = async (data: FormData) => {
    editBlockchainNetwork(Number(id), data);
  }

  useEffect(() => {
    getBlockchainNetwork(Number(id));
  }, [id])

  return (
    <>
      <Center w={'full'} pb={4}>
        <VStack color={'white'}>
          <Heading my={2}>
              Editar Blockchain Network
          </Heading>
          {
            isLoading ? (
              <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
                <Spinner />
              </Box>
            ) : (
              <Form blockchainNetwork={blockchainNetwork} onSubmit={onSubmit} />
            )
          }
        </VStack>
      </Center>
    </>
  )
}

export default EditBlockchainNetwork;

import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams, generatePath } from 'react-router-dom'
import { ROUTES } from '../../../consts/routes'
import { useForm } from 'react-hook-form'
import { Center, Heading, VStack, Button, Box, Spinner, useToast, FormControl, FormLabel, Input, FormErrorMessage, Text, Select } from '@chakra-ui/react'
import OlaggForm from '../../../components/Forms/OlaggForm'
import OlaggFormSelect from '../../../components/Forms/OlaggFormSelect'
import OlaggFormInput from '../../../components/Forms/OlaggFormInput'
import { AdminTournamentsEndpoints, BackendError, fetcher, useAdminUsers } from '@olagg/api-hooks'
import { yupResolver } from '@hookform/resolvers/yup'
import { formInscriptionSchema, FormInscriptionData, FormInscriptionBatchData, formInscriptionBatchSchema } from '@olagg/validation-schemas/tournament.schemas'
import useBlockchainTokenCatalog from '../../BlockchainTokenCatalog/hooks/useBlockchainTokenCatalog'
import useBlockchainNetwork from '../../BlockchainTokenCatalog/hooks/useBlockchainNetwork'

const TournamentAddInscription: FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const tournamentId = useParams()?.id;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedNetwork, setSelectedNetwork] = useState<string | null>(null);
  const { all, blockchainTokens } = useBlockchainTokenCatalog();
  const { all: allNetworks, blockchainNetworks } = useBlockchainNetwork();

  const handleNetworkChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedNetwork(event.target.value);
  };

  const filteredBlockchainTokens = selectedNetwork
  ? blockchainTokens?.filter(token => token.network.name === selectedNetwork)
  : blockchainTokens;
  
  const useFormMethods = useForm<FormInscriptionData>({
    resolver: yupResolver(formInscriptionSchema),
    mode: 'onChange',
  });

  const { handleSubmit: handleSubmitFile, register: registerFile, formState: { errors: errorsFile } } = useForm<FormInscriptionBatchData>({
    resolver: yupResolver(formInscriptionBatchSchema),
    mode: 'onChange',
  });

  const { asyncUserOptions } = useAdminUsers({ page: 1, limit: 20 })

  const onSubmit = (dataForm: FormInscriptionData) => {
    setIsLoading(true);

    const data = {
      userId: dataForm.userId.value,
      position: dataForm.position,
      winner: dataForm.winner,
      scoreParticipant: dataForm.scoreParticipant,
      scoreWinner: dataForm.scoreWinner,
      blockchainTokenId: dataForm.blockchainTokenId,
      prize: {
        other: dataForm.prizeOther,
        blockchainToken: dataForm.prizeBlockchainToken
      },
    }

    fetcher(
      AdminTournamentsEndpoints.addInscription(tournamentId!, data)
    ).then(() => {
      toast({
        title: 'Inscripciones',
        colorScheme: 'olaggPink',
        status: 'success',
        description: `Inscripción realizada con éxito`,
        position: 'bottom-right'
      });
      navigate(generatePath(ROUTES.TOURNAMENTS.inscriptions, { id: tournamentId }))
    }).catch((error: BackendError) => {
      toast({
        title: 'Error',
        colorScheme: 'olaggYellow',
        status: 'error',
        description: error.message || "Error al crear la inscripción",
        position: 'bottom-right'
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const onSubmitFile = (dataForm: FormInscriptionBatchData) => {
    setIsLoading(true);
    const body = new FormData();
    body.append('file', dataForm.file[0]);

    fetcher(AdminTournamentsEndpoints.addInscriptionBatch(tournamentId!, body))
      .then(() => {
        toast({
          title: 'Inscripciones',
          colorScheme: 'olaggPink',
          status: 'success',
          description: `Carga masiva realizada con éxito`,
          position: 'bottom-right'
        });
        navigate(generatePath(ROUTES.TOURNAMENTS.inscriptions, { id: tournamentId }))
      })
      .catch((error: any) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    all({ page: 1, limit: 100 });
    allNetworks({ page: 1, limit: 100 });
  }, []);

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Añadir Participantes
        </Heading>

        {isLoading ? (
          <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
            <Spinner />
          </Box>
        ) : (
          <>
            <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
              <Text fontSize='xl' fontWeight='600' mb='2' mt='10'>Carga Masiva</Text>
              <form onSubmit={handleSubmitFile(onSubmitFile)} encType="multipart/form-data" style={{ width: '100%' }}>
                <FormControl
                  id="file"
                  isInvalid={errorsFile.file ? true : false}
                >
                  <FormLabel>
                    Seleccionar archivo .xlsx
                  </FormLabel>
                  <Input
                    type='file'
                    {...registerFile('file')}
                    id='file'
                  />
                  <FormErrorMessage>
                    {errorsFile.file && errorsFile.file.message?.toString()}
                  </FormErrorMessage>
                </FormControl>

                <Box textAlign='left' w='100%'>
                  <a href={`https://${import.meta.env.VITE_APP_ENV === 'staging' ? 'staging.' : ''}olagg.io/downloads/participantes.xlsx`}>
                    <Button
                      variant="filledGradient"
                      flex="1"
                      size={'sm'} py='5px' mt='5px'
                    >
                      Descargar archivo de ejemplo
                    </Button>
                  </a>
                </Box>

                <Button
                  variant='filledGradient'
                  type='submit'
                  mt='10'
                  w='full'
                >
                  Cargar
                </Button>
              </form>
            </VStack>

            <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
              <Text fontSize='xl' fontWeight='600' mb='2' mt='10'>Carga Manual</Text>
              <OlaggForm useFormMethods={useFormMethods} onSubmit={onSubmit} style={{ width: '100%' }}>

                <OlaggFormSelect
                  label='Participante'
                  controlName='userId'
                  isSearchable={true}
                  staticOptions={[{}]}
                  asyncOptions={asyncUserOptions}
                  required={true}
                  placeholder='Buscar por nombre, user id o discord id'
                />

                <OlaggFormInput
                  label='Posición'
                  controlName='position'
                />

                <OlaggFormInput
                  label='Ganador'
                  controlName='winner'
                  inputType='switch'
                />

                <OlaggFormInput
                  label='XP a otorgar por participar'
                  description='Opcional - Si no se especifica se tomará el valor definido en el registro del torneo'
                  controlName='scoreParticipant'
                />

                <OlaggFormInput
                  label='XP a otorgar por ganar'
                  description='Opcional - Si no se especifica se tomará el valor definido en el registro del torneo'
                  controlName='scoreWinner'
                />

                  <Text fontSize={'18px'} fontWeight='700'>Blockchain Token Network Filter</Text>
                  <Select style={{marginBottom: '10px'}} placeholder="Select network" onChange={handleNetworkChange}>
                    {
                      blockchainNetworks?.map(network => (
                        <option key={network.id} value={network.name}>{network.name}</option>
                      ))
                    }
                  </Select>
                  {selectedNetwork && (
                    <>
                      <Text fontSize={'18px'} fontWeight='700'>Blockchain Token</Text>
                      <OlaggFormSelect
                        controlName={'blockchainTokenId'}
                        staticOptions={filteredBlockchainTokens?.map(t => ({
                          value: t.id,
                          label: t.name
                        }))}
                        required={true}
                        newVersion
                        isSearchable
                      />
                    </>
                  )}

                <OlaggFormInput
                  label='Premio en el Blockchain Token'
                  description='Valor en el blockchain token a otorgar al jugador'
                  controlName='prizeBlockchainToken'
                  required={selectedNetwork ? true : false}
                />

                <OlaggFormInput
                  label='Otros premios'
                  description='Opcional - Cualquier otro tipo de premio, ej.: una remera'
                  controlName='prizeOther'
                />

                <Button
                  variant={'filledGradient'}
                  isLoading={isLoading}
                  disabled={isLoading}
                  type="submit"
                  w={'full'}
                  mt='30px'
                  style={{
                    marginTop: '30px',
                    marginBottom: '50px'
                  }}
                >
                  Añadir
                </Button>

              </OlaggForm>
            </VStack>
          </>

        )}
      </VStack>
    </Center>
  )
}

export default TournamentAddInscription;

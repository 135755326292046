import { BlockchainToken, OlaggModule, PermissionEnum } from "@olagg/db-types";
import { Button, Table as ChakraTable, Flex, Input, InputGroup, InputRightElement, Spinner, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { BsSearch } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import Pagination from "@olagg/ui-kit/Pagination";
import { ROUTES } from "../../../../consts/routes";
import moment from "moment";
import useBlockchainTokenCatalog from "../../hooks/useBlockchainTokenCatalog";
import useRoles from "../../../../features/Roles/hooks/useRoles";

const Actions = ({ blockchainToken }: { blockchainToken: BlockchainToken }) => {
  const { missingPermission } = useRoles();
  const { deleteBlockchainToken } = useBlockchainTokenCatalog();
  const navigate = useNavigate();
  const viewBlockchainToken = (blockchainToken: BlockchainToken) => navigate(`/blockchain-token-catalog/${blockchainToken.id}`);
  const removeBlockchainToken = (blockchainToken: BlockchainToken) => {
    if (window.confirm('¿Estás seguro que deseas eliminar esta blockchain?')) {
      deleteBlockchainToken(blockchainToken.id);
    }
  }

  return (
    <Flex gap='10px'>
      <Button isDisabled={missingPermission(OlaggModule.BlockchainToken, PermissionEnum.EDIT)} bgColor={'#37A169'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => viewBlockchainToken(blockchainToken)}>
          Ver
      </Button>
      <Button isDisabled={missingPermission(OlaggModule.BlockchainToken, PermissionEnum.EDIT)} bgColor={'#E53E3E'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => removeBlockchainToken(blockchainToken)}>
          Eliminar
      </Button>
    </Flex>
  )
}

const BlockchainTokenCatalogList = () => {
  const { blockchainTokens, all, isLoading, count } = useBlockchainTokenCatalog();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  
  const [filter, setFilter] = useState({
    name: searchParams.get('name') || '',
    page: Number(searchParams.get('page') || 1),
    limit: Number(searchParams.get('limit') || 10)
  });

  const onFilter = ({
    name,
    value
  }: EventTarget & HTMLInputElement) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  const { page, limit, name } = filter;

  useEffect(() => {
    all(filter);
  }, [filter]);

  return (
    <Flex gap={5} direction="column" mt={4}>
      <Flex flexDirection={'row'} justify="space-between" pr={6}>
        <Flex flex="1">
          <InputGroup mx={5} w="50%">
            <Input
              color={"white"}
              placeholder="Nombre"
              name="name"
              value={name}
              onChange={(e) => onFilter(e.target)}
            />
            <InputRightElement children={<BsSearch color="white" />} />
          </InputGroup>
        </Flex>
        <Button
            variant="outlinedGradient"
            leftIcon={<FaPlus />}
            onClick={() => navigate(ROUTES.BLOCKCHAIN_TOKEN_CATALOG.create)}
            mb='3'
        >
            Crear Blockchain Token
        </Button>
      </Flex>

      <Flex w='full' justifyContent='center' px={'20px'} pb={'15px'}>
        <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="100%" maxH="60vh">
          <ChakraTable
            variant="striped"
            size="sm"
            colorScheme="olaggPurple"
            fontSize="18px"
            position='relative'
            whiteSpace={'normal'}
          >
            <Thead>
              <Tr position='sticky' bg='gray.800' top='0' zIndex='100'>
                <Th py={'20px'} color="white">ID</Th>
                <Th py={'20px'} color="white">Nombre</Th>
                <Th py={'20px'} color="white">Contract Address</Th>
                <Th py={'20px'} color="white">Network</Th>
                <Th py={'20px'} color="white">Token Type</Th>
                <Th py={'20px'} color="white">Fecha Creación</Th>
                <Th py={'20px'} color="white">Acciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {blockchainTokens.map((blockchainToken, index) => (
                <Tr key={index} color="white">
                  <Td py={'20px'}>{blockchainToken?.id}</Td>
                  <Td py={'20px'}>{blockchainToken?.name}</Td>
                  <Td py={'20px'}>{blockchainToken?.contractAddress}</Td>
                  <Td py={'20px'}>{blockchainToken?.network.name}</Td>
                  <Td py={'20px'}>{blockchainToken?.tokenType}</Td>
                  <Td py={'20px'}>{blockchainToken?.createdAt && moment(blockchainToken?.createdAt).format('DD/MM/YYYY')}</Td>
                  <Td py={'20px'}>
                    <Actions blockchainToken={blockchainToken} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </ChakraTable>
        </TableContainer>
      </Flex>
      <Flex px={'20px'} w='full' justifyContent={'center'}>
        <Pagination
          params={{limit, page, count}}
          onClick={(newPage) => 
            onFilter({
              name: 'page',
              value: String(newPage + 1)
            } as EventTarget & HTMLInputElement)
          }
          onLimitChange={(newLimit) => 
            onFilter({
              name: 'limit',
              value: newLimit
            } as EventTarget & HTMLInputElement)
          }
          hideLimitInput
        />
      </Flex>
    </Flex>
  )
};

export default BlockchainTokenCatalogList;
import { useEffect, useState } from 'react';
import usePayments from '../../Payments/hooks/usePayments';
import { FaCheck, FaTimes } from "react-icons/fa";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Select,
  Text,
  Table,
  TableContainer,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';
import useBlockchainTokenCatalog from '../../BlockchainTokenCatalog/hooks/useBlockchainTokenCatalog';
import useBlockchainNetwork from '../../BlockchainTokenCatalog/hooks/useBlockchainNetwork';

// const networks = [
//   { name: 'Ethereum', value: 'ethereum' },
//   { name: 'Polygon',  value: 'polygon' },
//   { name: 'Mumbai', value: 'mumbai' },
//   { name: 'Arbitrum', value: 'arbitrum' },
//   { name: 'Optimism', value: 'optimism' },
//   { name: 'Binance', value: 'binance' },
//   { name: 'Fantom', value: 'fantom' },
//   { name: 'Avalanche Fuji', value: 'avalanche_fuji' },
//   { name: 'Ronin', value: 'ronin' },
// ];

const CreatePaymentsModal = ({
  isOpen,
  onClose,
  tournamentId
}: {
  isOpen: boolean,
  onClose: () => void,
  tournamentId: number
}) => {

  const {
    isLoading,
    tournamentWinnersStatusses,
    tournamentWinnersErrors,
    createPaymentsForTournamentWinners,
    resetTournamentWinnersStatusses
  } = usePayments();

  const handleCreatePayments = async () => {
    createPaymentsForTournamentWinners(tournamentId);
  };

  const handleClose = () => {
    resetTournamentWinnersStatusses();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered size='lg' >
      <ModalOverlay backdropFilter='blur(10px)' />
      <ModalContent>
        <ModalHeader textAlign='center'>Cargar pagos</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {tournamentWinnersStatusses?.length ? (
            <>
              <TableContainer my='6' overflowY='auto' maxH='250px'>
                <Table size='sm'>
                  <Thead>
                    <Tr>
                      <Th>User ID</Th>
                      <Th>Estado</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {tournamentWinnersStatusses?.map((status: { user_id: string, status: string, errors?: string }, index: number) => (
                      <Tr key={index}>
                        <Td>{status.user_id}</Td>
                        <Td display='flex' alignItems='center'>
                          {status.errors
                            ? <FaTimes color='red' style={{ marginRight: '5px' }}/> 
                            : <FaCheck color='green' style={{ marginRight: '5px' }}/>}
                          {status.status}
                          {status.errors && <Text ml='2' noOfLines={1} color='red' fontSize='sm'>{status.errors}</Text>}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
              <Text mt='10' mb='5' color={tournamentWinnersErrors > 0 ? 'red' : 'green'}>
                {tournamentWinnersErrors > 0
                  ? `Hubieron ${tournamentWinnersErrors} errores`
                  : 'Todos los pagos fueron cargados correctamente'}
              </Text>
            </>
          ) : (
            <Button variant='filledGradient' w='full' mt='10' mb='5' isLoading={isLoading}  onClick={handleCreatePayments}>
              Cargar pagos
            </Button>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreatePaymentsModal;

import type { InferType } from 'yup';
import * as Yup from 'yup';

export const storySchema = Yup.object({
    title: Yup.string().required('El título es obligatorio'),
    dueAt: Yup.date().nullable(),
    isImage: Yup.boolean().nullable(),
    storyCategoryId: Yup.number().required('La categoría es obligatoria'),
    contentLink: Yup.string().nullable(),
    description: Yup.string().nullable(),
    cta: Yup.object({
        text: Yup.string().nullable(),
        link: Yup.string().nullable(),
    }).nullable(),
    extraData: Yup.object({
        en: Yup.object({
            title: Yup.string().required('El título es obligatorio'),
            cta: Yup.object({
                text: Yup.string().nullable(),
            }).nullable(),
            description: Yup.string().nullable(),
        }).required('Las traducciones son obligatorias'),
        pt: Yup.object({
            title: Yup.string().required('El título es obligatorio'),
            cta: Yup.object({
                text: Yup.string().nullable(),
            }).nullable(),
            description: Yup.string().nullable(),
        }).required('Las traducciones son obligatorias'),
    }).required('Las traducciones son obligatorias')
})

export type StoryData = InferType<typeof storySchema>;

import { useEffect, useState } from "react";
import { Flex, InputGroup, Input, InputRightElement, Table as ChakraTable, TableContainer, Thead, Tr, Th, Tbody, Td, Button } from "@chakra-ui/react";
import { OlaggModule, PermissionEnum, StoryCategory } from "@olagg/db-types";
import Pagination from "@olagg/ui-kit/Pagination";
import moment from "moment";
import { BsSearch } from "react-icons/bs";
import { useNavigate, useSearchParams } from "react-router-dom";
import useStoryCategories from "../../hooks/useStoryCategories";
import useRoles from "../../../../features/Roles/hooks/useRoles";
import { FaPlus } from "react-icons/fa";
import { ROUTES } from "../../../../consts/routes";

const Actions = ({ category }: { category: StoryCategory }) => {
    const { missingPermission } = useRoles();
    const { deleteCategory } = useStoryCategories();
    const navigate = useNavigate();
    const viewStory = (category: StoryCategory) => navigate(`/story-categories/${category.id}`);
    const removeStory = (category: StoryCategory) => {
        if (window.confirm('¿Estás seguro que deseas eliminar esta categoría?')) {
            deleteCategory(category.id).then(() => {
                window.location.reload();
            })
        }
    }

    return (
        <Flex gap='10px'>
            <Button isDisabled={missingPermission(OlaggModule.Story, PermissionEnum.EDIT)} bgColor={'#37A169'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => viewStory(category)}>
                Ver
            </Button>
            <Button isDisabled={missingPermission(OlaggModule.Story, PermissionEnum.EDIT)} bgColor={'#E53E3E'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => removeStory(category)}>
                Eliminar
            </Button>
        </Flex>
    )
}

const StoryCategoryList = () => {
    const [categories, setCategories] = useState<StoryCategory[]>([]);
    const [count, setCount] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const { getCategories } = useStoryCategories();
    const navigate = useNavigate();

    const [filter, setFilter] = useState({
        name: searchParams.get('name') || '',
        page: Number(searchParams.get('page') || 1),
        limit: Number(searchParams.get('limit') || 10)
    });

    const onFilter = ({
        name,
        value
    }: EventTarget & HTMLInputElement) => {
        setFilter({ ...filter, [name]: value })

        if (value) searchParams.set(name, value)
        else searchParams.delete(name)
        setSearchParams(searchParams)
    }

    const { page, limit, name } = filter

    const initialRequest = () =>
        getCategories({ name, page, limit })
            .then((response) => {
                setCategories(response.categories);
                setCount(response.count);
            });


    useEffect(() => {
        initialRequest();
    }, [filter]);

    return (
        <Flex gap={5} direction="column" mt={4}>
            <Flex flexDirection={'row'} justify="space-between" pr={6}>
                <Flex flex="1">
                    <InputGroup mx={5} w="50%">
                        <Input
                            color={"white"}
                            placeholder="Nombre"
                            name="name"
                            value={name}
                            onChange={(e) => onFilter(e.target)}
                        />
                        <InputRightElement children={<BsSearch color="white" />} />
                    </InputGroup>
                </Flex>
                <Button
                    variant="outlinedGradient"
                    leftIcon={<FaPlus />}
                    onClick={() => navigate(ROUTES.STORY_CATEGORIES.create)}
                    mb='3'
                >
                    Crear Categoría
                </Button>
            </Flex>
            <Flex w='full' justifyContent='center' px={'20px'} pb={'15px'}>
                <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="100%" maxH="60vh">
                    <ChakraTable
                        variant="striped"
                        size="sm"
                        colorScheme="olaggPurple"
                        fontSize="18px"
                        position='relative'
                        whiteSpace={'normal'}
                    >
                        <Thead>
                            <Tr position='sticky' bg='gray.800' top='0' zIndex='100'>
                                <Th py={'20px'} color="white">ID</Th>
                                <Th py={'20px'} color="white">Nombre</Th>
                                <Th py={'20px'} color="white">Fija?</Th>
                                <Th py={'20px'} color="white">Thumbnail</Th>
                                <Th py={'20px'} color="white">Fecha Creación</Th>
                                <Th py={'20px'} color="white">Acciones</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {categories?.map((storyCategory, index) => (
                                <Tr key={index} color="white">
                                    <Td py={'20px'}>{storyCategory.id}</Td>
                                    <Td py={'20px'}>{storyCategory.name}</Td>
                                    <Td py={'20px'}>{storyCategory.fixed ? "Si" : "No"}</Td>
                                    <Td py={'20px'}>{storyCategory.thumbnail}</Td>
                                    <Td py={'20px'}>{storyCategory.createdAt && moment(storyCategory.createdAt).format('DD/MM/YYYY')}</Td>
                                    <Td py={'20px'}>
                                        <Actions category={storyCategory} />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </ChakraTable>
                </TableContainer>
            </Flex>
            <Flex px={'20px'} w='full' justifyContent={'center'}>

                <Pagination
                    params={{ limit, page, count }}
                    onClick={page =>
                        onFilter({
                            name: 'page',
                            value: String(page + 1)
                        } as EventTarget & HTMLInputElement)
                    }
                    onLimitChange={val =>
                        onFilter({
                            name: 'limit',
                            value: val
                        } as EventTarget & HTMLInputElement)
                    }
                    hideLimitInput
                />

            </Flex>
        </Flex>
    )
};

export default StoryCategoryList;
import type { InferType } from 'yup';
import * as Yup from 'yup';

export const formAlertSchema = Yup.object({
  title: Yup
    .string()
    .required('Introduce el título de la alerta'),
  message: Yup
    .string()
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  ctaLabel: Yup
    .string()
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  ctaUrl: Yup
    .string()
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  showInPath: Yup
    .string()
    .required('Introduce las rutas donde se mostrará la alerta'),
  image: Yup
    .string()
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  type: Yup
    .object({
      value: Yup
        .string(),
      label: Yup
        .string(),
    })
    .required('Elije el tipo de alerta'),
  visible: Yup
    .boolean()
    .default(true),
  closable: Yup
    .boolean()
    .default(true),
  startDate: Yup
    .date()
    .default(null)
    .transform((_, val) => (val === '' || val === null) ? null : new Date(val))
    .nullable(),
  expirationDate: Yup
    .date()
    .default(null)
    .transform((_, val) => (val === '' || val === null) ? null : new Date(val))
    .required(),
  })
.required();

export type FormAlertData = InferType<typeof formAlertSchema>;

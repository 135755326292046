import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useState } from "react";
import Create from "./components/stories/Create";
import StoryCategoryList from "./components/storyCategories/List";
import StoryList from "./components/stories/List";

const Stories = () => {

    const [tabIndex, setTabIndex] = useState<number>(0)

    return (
        <>
            <Tabs
                index={tabIndex}
                onChange={setTabIndex}
            >
                <TabList
                    margin='0 20px 0px 20px'
                    borderColor='#343847'
                    position='relative'
                    sx={{
                        '&::-webkit-scrollbar': {
                            display: 'none !important'
                        },
                        overflowX: 'auto !important',
                        overflowY: 'hidden !important',
                    }}
                >
                    <Tab color="white" whiteSpace='nowrap' paddingBottom='4px' _selected={{ fontWeight: '800', borderBottom: '4px solid white', paddingBottom: '2px' }} fontWeight='600'>
                        Categorías
                    </Tab>
                    <Tab color="white" whiteSpace='nowrap' paddingBottom='4px' _selected={{ fontWeight: '800', borderBottom: '4px solid white', paddingBottom: '2px' }} fontWeight='600'>
                        Historias
                    </Tab>
                </TabList>

                <TabPanels p='0'>
                    <TabPanel p='0'>
                        <StoryCategoryList />
                    </TabPanel>
                    <TabPanel p='0'>
                        <StoryList tabIndex={tabIndex} />
                    </TabPanel>
                </TabPanels>
            </Tabs>

        </>
    )
};

export default Stories;
import { Flex, VStack, Heading, useToast, Spinner, InputGroup, Input, InputLeftElement, FormControl, FormLabel, RadioGroup, Button, Box, Text, Stack, Radio, Checkbox } from "@chakra-ui/react";
import { OlaggModule, PermissionEnum } from "@olagg/db-types";
import { useCallback, useEffect, useState } from "react";
import useRoles from "../../../features/Roles/hooks/useRoles";
import { useForm, Controller, FormProvider } from 'react-hook-form'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
    fetcher,
    AdminSurveysEndpoints
} from '@olagg/api-hooks'
import OlaggFormInput from "../../../components/Forms/OlaggFormInput";
import OlaggMarkdown from "@olagg/ui-kit/OlaggMarkdown";

type FormData = {
    title: string,
    cover_image: string,
    background_image: string,
    theme: string,
    description: string,
    short_description: string,
    og: OGAttributes
    slug: string,
    start_date: string,
    end_date: string,
    visible: boolean,
    showPercentage: boolean,
    author_id: number,
    require_login: boolean,
    require_discord: boolean,
    require_wallet: boolean,
    require_deposit_wallet: boolean,
    typeform_id: string,
    score: number,
    notify: boolean,
    urgent: boolean,
    show_statistics_response: boolean,
    show_statistics_end: boolean,
    public_statistics: boolean,
    extra_data: extraDataAttributes,
}

type OGAttributes = {
    title: string,
    description: string
}

type extraDataAttributes = {
    en: {
        title: string,
        description: string,
        short_description: string,
        og: {
            title: string,
            description: string,
        }
    },
    pt: {
        title: string,
        short_description: string,
        og: {
            title: string,
            description: string,
        }
    }
}

const schema: Yup.Schema<FormData> = Yup
    .object({
        title: Yup.string().required('El título es obligatorio'),
        cover_image: Yup.string().required('Imagen de la portada es obligatorio'),
        background_image: Yup.string().required('Imagen de fondo es obligatorio'),
        theme: Yup.string().required('El tema es obligatorio'),
        description: Yup.string().required('La descripción es obligatoria'),
        short_description: Yup.string().required('La descripción corta es obligatoria'),
        og: Yup
            .object({
                title: Yup
                    .string()
                    .required('El OG Title es obligatorio'),
                description: Yup
                    .string()
                    .required('La OG Description es obligatoria'),
            })
            .required('OG es obligatorio'),
        slug: Yup.string().required('El slug es obligatorio'),
        start_date: Yup
            .date()
            .transform((_, val) => (val === '' || val === null) ? null : new Date(val))
            .required('La fecha y hora de inicio es obligatoria'),
        end_date: Yup
            .date()
            .transform((_, val) => (val === '' || val === null) ? null : new Date(val))
            .required('La fecha y hora de finalización es obligatoria'),
        visible: Yup.boolean().required(),
        showPercentage: Yup.boolean().required(),
        require_login: Yup.boolean().required(),
        require_discord: Yup.boolean().required(),
        require_wallet: Yup.boolean().required(),
        require_deposit_wallet: Yup.boolean().required(),
        typeform_id: Yup.string().required('El ID de Typeform es obligatorio'),
        score: Yup.string().required('XP es obligatorio'),
        notify: Yup.boolean().required(),
        urgent: Yup.boolean().required(),
        show_statistics_response: Yup.boolean().required(),
        show_statistics_end: Yup.boolean().required(),
        public_statistics: Yup.boolean().required(),
        extra_data: Yup.object({
            en: Yup.object({
                title: Yup.string().optional().nullable(),
                description: Yup.string().optional().nullable(),
                short_description: Yup.string().optional().nullable(),
                og: Yup.object({
                    title: Yup.string().optional().nullable(),
                    description: Yup.string().optional().nullable(),
                })
            }),
            pt: Yup.object({
                title: Yup.string().optional().nullable(),
                description: Yup.string().optional().nullable(),
                short_description: Yup.string().optional().nullable(),
                og: Yup.object({
                    title: Yup.string().optional().nullable(),
                    description: Yup.string().optional().nullable(),
                })
            })
        })
    })
    .required()

const SurveyForm = () => {
    const { missingPermission } = useRoles();
    const toast = useToast()
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [isLoading, setIsLoading] = useState(false)
    const [markdownDescription, setMarkdownDescription] = useState('')

    const useFormMethods = useForm<FormData>({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const errors = useFormMethods.formState.errors;

    const initForm = () => {
        useFormMethods.setValue('theme', 'light')
        useFormMethods.setValue('visible', false)
        useFormMethods.setValue('showPercentage', false)
        useFormMethods.setValue('require_login', true)
        useFormMethods.setValue('require_discord', false)
        useFormMethods.setValue('require_wallet', false)
        useFormMethods.setValue('require_deposit_wallet', false)
        useFormMethods.setValue('notify', false)
        useFormMethods.setValue('urgent', false)
        useFormMethods.setValue('show_statistics_response', false)
        useFormMethods.setValue('show_statistics_end', false)
        useFormMethods.setValue('public_statistics', false)
    }

    useEffect(() => {
        initForm()
    }, [])


    useEffect(() => {
        console.log(errors);

    }, [errors])

    const onSubmit = (dataForm) => {
        let payload = { survey: dataForm }
        setIsLoading(true)
        fetcher(AdminSurveysEndpoints.new(payload))
            .then(() => {
                toast({
                    title: 'Encuesta creada',
                    colorScheme: 'olaggPink',
                    status: 'success',
                    description: '',
                    position: 'bottom-right'
                })
                //LIMPAR FORM
                useFormMethods.reset()
                useFormMethods.setValue('theme', 'light')
                setIsLoading(false)
            })
            .catch(e => {
                toast({
                    title: 'Error',
                    colorScheme: 'olaggYellow',
                    status: 'error',
                    description: e?.error?.message,
                    position: 'bottom-right'
                })
                setIsLoading(false)
            })
    }

    return (
        <Flex w={'full'} py={'15px'} pb={4} minH='600px' justifyContent='center'>
            <VStack color={'white'} w={'full'} maxW={'60%'}>
                <Flex flexDirection="column" w={'full'}>
                    <Heading fontSize={'30px'}>Cargar encuesta</Heading>
                </Flex>

                <Flex w={'full'} flexDirection='column'>

                    <Flex position={'relative'} zIndex='0' mt={'20px'}>
                        <FormProvider {...useFormMethods}>
                            <form onSubmit={useFormMethods.handleSubmit(onSubmit)} style={{ width: '100%' }}>

                                <OlaggFormInput
                                    required={true}
                                    label='Título en Español'
                                    controlName='title'
                                    placeholder='Título de la encuesta en español'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <Flex direction='column' w='full' bgColor='#20173d' p='8' mb={'40px'} borderRadius={'12px'}>

                                    <OlaggFormInput
                                        required={true}
                                        label='Título en Inglés'
                                        controlName='extra_data.en.title'
                                        placeholder='Título de la encuesta en inglés'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                    <OlaggFormInput
                                        required={true}
                                        label='Título en Portugués'
                                        controlName='extra_data.pt.title'
                                        placeholder='Título de la encuesta en portugués'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                </Flex>

                                <OlaggFormInput
                                    required={true}
                                    label='Imagen de portada (URL)'
                                    controlName='cover_image'
                                    placeholder='Imagen de la portada de la encuesta'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <OlaggFormInput
                                    required={true}
                                    label='Imagen de fondo (URL)'
                                    controlName='background_image'
                                    placeholder='Imagen de fondo de la encuesta'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <OlaggFormInput
                                    required={true}
                                    label='Descripción en Español (puedes usar texto con Markdown)'
                                    inputType="textarea"
                                    controlName='description'
                                    placeholder='Descripción de la encuesta en español'
                                    customStyles={{
                                        fontWeight: '700',
                                        minHeight: '250px'
                                    }}
                                    onChange={(e) => {
                                        setMarkdownDescription(e.target.value)
                                    }}
                                />

                                <Flex direction='column' w='full' bgColor='#20173d' p='8' mb={'40px'} borderRadius={'12px'}>

                                    <OlaggFormInput
                                        required={true}
                                        inputType="textarea"
                                        label='Descripción en Inglés (puedes usar texto con Markdown)'
                                        controlName='extra_data.en.description'
                                        placeholder='Descripción de la encuesta en inglés'
                                        customStyles={{
                                            fontWeight: '700',
                                            minHeight: '250px'
                                        }}
                                    />

                                    <OlaggFormInput
                                        required={true}
                                        inputType="textarea"
                                        label='Descripción en Portugués (puedes usar texto con Markdown)'
                                        controlName='extra_data.pt.description'
                                        placeholder='Descripción de la encuesta en portugués'
                                        customStyles={{
                                            fontWeight: '700',
                                            minHeight: '250px'
                                        }}
                                    />

                                </Flex>

                                <OlaggMarkdown>
                                    {markdownDescription || ''}
                                </OlaggMarkdown>

                                <OlaggFormInput
                                    required={true}
                                    label='Descripción corta en Español (NO USAR Markdown)'
                                    inputType="textarea"
                                    controlName='short_description'
                                    placeholder='Descripción corta de la encuesta en español'
                                    customStyles={{
                                        fontWeight: '700',
                                    }}
                                />

                                <Flex direction='column' w='full' bgColor='#20173d' p='8' mb={'40px'} borderRadius={'12px'}>

                                    <OlaggFormInput
                                        required={true}
                                        inputType="textarea"
                                        label='Descripción corta en Inglés (NO USAR Markdown)'
                                        controlName='extra_data.en.short_description'
                                        placeholder='Descripción corta de la encuesta en inglés'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                    <OlaggFormInput
                                        required={true}
                                        inputType="textarea"
                                        label='Descripción corta en Portugués (NO USAR Markdown)'
                                        controlName='extra_data.pt.short_description'
                                        placeholder='Descripción corta de la encuesta en portugués'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                </Flex>

                                <Box my='20px' sx={{
                                    width: '100%',
                                    height: '1px',
                                    backgroundColor: 'grey',
                                }} />


                                <Text fontSize={'18px'} my='10px' fontWeight='700'>OG para Meta Tags</Text>

                                <OlaggFormInput
                                    required={true}
                                    label='OG Title'
                                    controlName='og.title'
                                    placeholder='Título para meta tags'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <OlaggFormInput
                                    required={true}
                                    label='OG Description (no debe ser muy larga)'
                                    controlName='og.description'
                                    placeholder='Descripción para meta tags'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <Flex direction='column' w='full' bgColor='#20173d' p='8' mb={'40px'} borderRadius={'12px'}>

                                    <OlaggFormInput
                                        required={true}
                                        label='OG Título en Inglés'
                                        controlName='extra_data.en.og.title'
                                        placeholder='OG Título de la encuesta en inglés'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                    <OlaggFormInput
                                        required={true}
                                        label='OG Description en Inglés (no debe ser muy larga)'
                                        controlName='extra_data.en.og.description'
                                        placeholder='OG Descripción para meta tags'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                    <br />

                                    <OlaggFormInput
                                        required={true}
                                        label='OG Título en Portugués'
                                        controlName='extra_data.pt.og.title'
                                        placeholder='OG Título de la encuesta en portugués'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                    <OlaggFormInput
                                        required={true}
                                        label='OG Description en Portugués (no debe ser muy larga)'
                                        controlName='extra_data.pt.og.description'
                                        placeholder='OG Descripción para meta tags en Portugués'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                </Flex>

                                <Box my='20px' sx={{
                                    width: '100%',
                                    height: '1px',
                                    backgroundColor: 'grey',
                                }} />

                                <OlaggFormInput
                                    required={true}
                                    label='Slug'
                                    controlName='slug'
                                    placeholder='Slug de la encuesta'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <OlaggFormInput
                                    label='Fecha y hora de Inicio'
                                    controlName='start_date'
                                    inputType='datetime'
                                    description={`Zona horaria: ${currentTimeZone}`}
                                    datePickerProps={{ backgroundColor: 'white' }}
                                    required={true}
                                />

                                <OlaggFormInput
                                    label='Fecha y hora de Fin'
                                    controlName='end_date'
                                    inputType='datetime'
                                    description={`Zona horaria: ${currentTimeZone}`}
                                    datePickerProps={{ backgroundColor: 'white' }}
                                    required={false}
                                />

                                <Box my='20px' sx={{
                                    width: '100%',
                                    height: '1px',
                                    backgroundColor: 'grey',
                                }} />

                                <Text fontSize={'18px'} my='10px' fontWeight='700'>Requiere</Text>

                                {/* <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='require_login'
                                    />
                                    <Flex mt={'-10px'}>Login</Flex>
                                </Flex> */}

                                <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='require_discord'
                                    />
                                    <Flex mt={'-10px'}>Discord</Flex>
                                </Flex>

                                <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='require_wallet'
                                    />
                                    <Flex mt={'-10px'}>Wallet</Flex>
                                </Flex>

                                <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='require_deposit_wallet'
                                    />
                                    <Flex mt={'-10px'}>Deposit Wallet</Flex>
                                </Flex>

                                <Box my='20px' sx={{
                                    width: '100%',
                                    height: '1px',
                                    backgroundColor: 'grey',
                                }} />

                                <OlaggFormInput
                                    required={true}
                                    label='ID de encuesta en Typeform'
                                    controlName='typeform_id'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <OlaggFormInput
                                    required={true}
                                    label='XP'
                                    controlName='score'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <Text fontSize={'18px'} my='10px' fontWeight='700'>Notificaciones y estadisticas</Text>

                                <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='notify'
                                    />
                                    <Flex mt={'-10px'}>Disparar Notificación In-App cuando inicie la encuesta</Flex>
                                </Flex>

                                <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='show_statistics_response'
                                    />
                                    <Flex mt={'-10px'}>Mostrar Estadísticas de Resultados al responder</Flex>
                                </Flex>

                                <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='public_statistics'
                                    />
                                    <Flex mt={'-10px'}>Mostrar estadisticas publicamente</Flex>
                                </Flex>

                                <Box my='20px' sx={{
                                    width: '100%',
                                    height: '1px',
                                    backgroundColor: 'grey',
                                }} />

                                <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='visible'
                                    />
                                    <Flex mt={'-10px'}>Visible</Flex>
                                </Flex>

                                <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='showPercantage'
                                    />
                                    <Flex mt={'-10px'}>Mostrar en porcentaje?</Flex>
                                </Flex>

                                <Button
                                    variant={'filledGradient'}
                                    isLoading={isLoading}
                                    type="submit"
                                    w={'full'}
                                    style={{
                                        marginTop: '30px'
                                    }}
                                    isDisabled={isLoading || missingPermission(OlaggModule.Survey, PermissionEnum.CREATE)}
                                >
                                    Cargar encuesta
                                </Button>
                            </form>
                        </FormProvider>
                    </Flex>
                </Flex>
            </VStack >
        </Flex >
    )
};

export default SurveyForm;

import { Box, Center, Heading, Spinner, useToast, VStack } from "@chakra-ui/react";
import { AdminStoriesEndpoints, fetcher } from "@olagg/api-hooks";
import { Story } from "@olagg/db-types";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form from "./Form";
import useStories from "../../hooks/useStories";

const Edit = () => {
    const [story, setStory] = useState<Story | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();
    const id = params.id!
    const toast = useToast();
    const navigate = useNavigate();
    const { getStory } = useStories();

    const fetchData = async () => getStory(Number(id)).then(setStory).then(() => setIsLoading(false));

    const onSubmit = async (data: FormData) =>
        fetcher(AdminStoriesEndpoints.update(Number(id), data))
            .then(() => {
                toast({
                    title: 'Historia actualizada',
                    status: 'success',
                    duration: 3000,
                    position: 'top-right',
                    isClosable: true,
                });
                navigate('/stories');
            })
            .catch(e => {
                console.error(e);
                toast({
                    title: 'Error al actualizar la historia: ' + e.errors,
                    position: 'top-right',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            });

    useEffect(() => {
        fetchData();
    }, [id]);

    return (
        <>
            <Center w={'full'} pb={4}>
                <VStack color={'white'}>
                    <Heading my={2}>
                        Editar Historia
                    </Heading>

                    {isLoading ? (
                        <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
                            <Spinner />
                        </Box>
                    ) : (
                        <Form story={story} onSubmit={onSubmit} />
                    )}
                </VStack>
            </Center>
        </>
    )
};

export default Edit;
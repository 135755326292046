import { Button, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { getContract, readContract } from "thirdweb";
import { useSendTransaction } from "thirdweb/react";
import ERC20_ABI from './erc20_abi.json'
import { approve } from "thirdweb/extensions/erc20";

export function ApproveAllowance({
    payments,
    contractAddress,
    airdropContractAddress,
    verifyAllowance,
    count,
    walletAddress,
    client,
    chain
}: {
    payments: any,
    contractAddress: string,
    airdropContractAddress: string,
    verifyAllowance: (value: number) => void,
    count: number,
    walletAddress: string,
    client: any,
    chain: any
}) {

    const toast = useToast()

    let total = 0
    payments.map(p => {
        total += p.quantity
    })

    const { mutateAsync: sendTransaction, isPending } = useSendTransaction();
    const [isApproving, setIsApproving] = useState(false);
    const [isLoadingDecimals, setIsLoadingDecimals] = useState(false);

    const handleApprove = async () => {
        try {
            setIsApproving(true);
            setIsLoadingDecimals(true);

            const contract = getContract({
                client,
                address: contractAddress,
                chain: chain,
                abi: ERC20_ABI,
            });

            const tokenDecimals = await readContract({
                contract,
                method: "decimals",
                params: [],
            });

            const transaction = await approve({
                contract,
                spender: airdropContractAddress,
                amount: total,
            });

            const tx = await sendTransaction(transaction);
            console.log("Transacción enviada:", tx);

            toast({
                title: 'Allowance aprobado',
                colorScheme: 'olaggPink',
                status: 'success',
                description: '',
                position: 'bottom-right'
            })

            verifyAllowance(count + 1)

        } catch (error) {
            console.log("EL ERROR EN LA APROBACION", error);

            toast({
                title: 'Error en la aprobación de allowance',
                colorScheme: 'olaggPink',
                status: 'error',
                description: '',
                position: 'bottom-right'
            })
        } finally {
            setIsApproving(false);
            setIsLoadingDecimals(false);
        }
    };

    return (
        <Button isLoading={isApproving} disabled={isApproving && isLoadingDecimals} onClick={handleApprove} variant={'outline'}>Aprobar Allowance</Button>
    );
}
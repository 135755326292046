import { useState, useEffect, useRef } from 'react';
import {
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import { useAuthStore, useOnboardingStore, useRolesStore } from '@olagg/store'
import { useToast, useDisclosure, Box, Button, Center, Flex, Text, Modal, VStack, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Spinner, Input, Textarea, Heading, Link } from '@chakra-ui/react'
import { FaArrowRight, FaChevronRight, FaDiscord, FaGoogle } from 'react-icons/fa'
import { ColouredLogoWithText } from '../../svg/ColouredLogoWithText'
import Facebook from '../../svg/Facebook'

import Providers from '../providers.types'
import { fetcher, LoginWithProviderEndpoint, ConnectWithProviderEndpoint, ReferralsEndpoints } from '@olagg/api-hooks';
import Cookies from 'js-cookie'
import { supabase, auth, signInWithGoogleProvider, signInWithFacebookProvider } from "@olagg/plugins"
import { analytics, catchSegmentError } from '../../Analytics'
import { FaRegClone } from 'react-icons/fa'
import { ROUTES } from '../../../../apps/app/src/routes';
import Passkey from './Passkey';

export default function OnboardingModal() {
  const { getMe, me } = useAuthStore()
  const { initRoles, initPermissions } = useRolesStore()
  const toast = useToast()
  const { isOnboardingOpen, closeOnboarding, setIsRedirected, setIsSignedIn, forceDiscord } = useOnboardingStore()
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false)
  const [isLoadingFacebook, setIsLoadingFacebook] = useState(false)
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const redirect = useRef<any>()
  const [browserLocale, setBrowserLocale] = useState('es')

  const [urlToOpen, setUrlToOpen] = useState('')
  const [isWebview, setIsWebview] = useState(false)
  const [toggleWebViewMessage, setToggleWebViewMessage] = useState(false)
  const [baseUrlWebView, setBaseUrlWebView] = useState(window.location.href)

  const referrerId =
    searchParams.get('referrerId') ||
    localStorage.getItem('referrerId')

  const REFER_FRIEND = '1';
  const [referredAction, referredId] = referrerId?.split('T') || []; // destruct referrerId into relevant action id and referred user id

  useEffect(() => {
    checkAuthStatus();
    checkAuthStatusSupabase();
    initRedirectPath()
  }, [])

  const validateReferFriend = (newAccount: boolean) => {
    // validate only if it's a new account, the action is refer friend and the referred user id was sent
    if (newAccount && referrerId && referredId && referredAction === REFER_FRIEND) {
      fetcher(ReferralsEndpoints.validateAction(referrerId))
        .then(() => localStorage.removeItem('referrerId'))
        .catch((e) => console.log(e))
    };
  }

  const closeWithDiscordForced = () => {
    closeOnboarding()
    navigate('/mi-perfil')
    toast({
      title: 'No se pudo vincular tu cuenta de Discord',
      status: 'error',
      position: 'top',
      duration: 6000,
      isClosable: true
    })
  }

  function getOperatingSystemName() {
    let operatingSystem = "Unknown";

    if (typeof navigator !== "undefined" && navigator && navigator.userAgent) {
      if (navigator.userAgent.indexOf("X11") !== -1) {
        operatingSystem = "Unix";
      }
      if (navigator.userAgent.indexOf("Win") !== -1) {
        operatingSystem = "Windows";
      }
      if (navigator.userAgent.indexOf("Mac") !== -1) {
        operatingSystem = "macOS";
      }
      if (navigator.userAgent.indexOf("Linux") !== -1) {
        operatingSystem = "Linux";
      }
      if (navigator.userAgent.indexOf("Android") !== -1) {
        operatingSystem = "Android";
      }
      if (
        navigator.userAgent.indexOf("iPhone") !== -1 ||
        navigator.userAgent.indexOf("iPad") !== -1 ||
        navigator.userAgent.indexOf("iPod") !== -1
      ) {
        operatingSystem = "iOS";
      }
    }

    return operatingSystem;
  }

  const checkInApp = () => {
    if (navigator.userAgent.includes('FBAN') || navigator.userAgent.includes('FBAV') || navigator.userAgent.includes('Instagram')) {
      return true
    } else {
      return false
    }
  }

  const modalWebView = () => {
    let url = baseUrlWebView.replace('https://', '')
    // url = baseUrlWebView.replace(/^http?:\/\//, '')
    if (checkInApp() && getOperatingSystemName() == 'iOS') {
      url = `googlechrome://${url}`
      setUrlToOpen(url)
      setIsWebview(true)
    }

    if (checkInApp() && getOperatingSystemName() == 'Android') {
      url = `intent:${url}#Intent;end`
      setUrlToOpen(url)
      setIsWebview(true)
    }

    if (!isOnboardingOpen) {
      setToggleWebViewMessage(false)
    }
  }

  const showWebviewMessage = () => {
    analytics()
      ?.track('User SignIn Attempted', {
        url: baseUrlWebView,
        user_agent: navigator.userAgent
      })
    setToggleWebViewMessage(true)
  }

  const triggerAnalyticsAfterLogin = (provider: string) => {
    analytics()
      ?.track('User SignedIn', {
        provider: provider,
        user_agent: navigator.userAgent
      })

    analytics()
      ?.identify(`${me!.id}`, {
        email: me!.email,
        name: me!.name,
        google_id: me!.authProviderData?.hasOwnProperty('google') ? me!.authProviderData.google.id : '',
        discord_id: me!.authProviderData?.hasOwnProperty('discord') ? me!.authProviderData.discord.id : '',
        game_categories: me!.gameCategories,
        opportunities: me!.opportunities,
        level: me!.level?.value,
        xp: me!.level?.score,
        identity_provider: provider,
        user_agent: navigator.userAgent,
        human_verification_level: me?.verification?.level,
        human_verification_providers: me?.verification?.providers,
      })
  }

  const openInBrowser = () => {
    window.location.href = urlToOpen
  }

  useEffect(() => {
    modalWebView()
  }, [isOnboardingOpen])

  const initRedirectPath = () => {
    redirect.current = searchParams.get('redirect') || localStorage.getItem('redirect')
    if (redirect.current) {
      localStorage.setItem('redirect', redirect.current)
    }
  }

  const handleRedirect = async (provider: string) => {
    setIsSignedIn(true, provider)
    if (redirect.current) setIsRedirected(true)
  }

  const handleViewPolicy = (path: string) => {
    closeOnboarding();
    navigate(`${ROUTES.POSTS}${path}`);
  }

  const checkAuthStatus = async () => {
    const result = await auth.getRedirectResult();
    if (result?.user && result?.credential) {
      await supabase.auth.signOut()
      const providerId = result.credential.providerId ?? 'default';
      const providerIds = {
        'google.com': async () => {
          setIsLoadingSpinner(true)
          await getUserTokenGoogle(result)
        },
        'facebook.com': async () => {
          setIsLoadingSpinner(true)
          await getUserTokenFacebook(result)
        },
        'twitter.com': async () => {
          setIsLoadingSpinner(true)
          await getUserTokenTwitter(result)
        },
        'discord.com': async () => {
          setIsLoadingSpinner(true)
          await getUserTokenDiscord(result)
        },
        'default': () => console.log("provider not found")
      }
      // @ts-ignore
      providerIds[providerId] ? providerIds[providerId]() : providerIds['default']
    }
  }

  const checkAuthStatusSupabase = async () => {
    supabase.auth.onAuthStateChange(async (event) => {
      if (event == 'SIGNED_IN') {
        const { data } = await supabase.auth.getSession()
        const isDiscord = data.session?.user?.user_metadata?.iss.includes('discord');
        const isTwitter = data.session?.user?.user_metadata?.iss.includes('twitter');
        const isGoogle = data.session?.user?.user_metadata?.iss.includes('google');
        if (isDiscord) {
          setIsLoadingSpinner(true)
          await getUserTokenDiscord(data.session?.access_token)
        } else if (isTwitter) {
          setIsLoadingSpinner(true)
          await getUserTokenTwitter(data.session?.access_token)
        } else if (isGoogle) {
          setIsLoadingSpinner(true)
          await getUserTokenGoogleSupabase(data.session?.access_token)
        }
      }
    });
  }

  const getUserTokenGoogleSupabase = async (accessToken: any) => {
    try {
      await fetcher(LoginWithProviderEndpoint.google({
        accessToken: accessToken,
        referrerId: referredAction === REFER_FRIEND ? referredId : undefined,
        provider: 'google'
      })).then(async response => {
        persistUserToken(response?.token);
        validateReferFriend(response?.new_account);
        await getMe().then((user) => {
          closeOnboarding();
          initRoles();
          initPermissions();
          toast({
            title: i18n[browserLocale].toastSuccessLogin,
            colorScheme: 'olaggPink',
            status: 'success',
            description: '',
            position: 'bottom-right'
          })
          triggerAnalyticsAfterLogin('google')
        });
      })
        .finally(async () => {
          await supabase.auth.signOut()
          setIsLoadingSpinner(false)
          handleRedirect('google')
        })

    } catch (error: any) {
      console.log(error.message);
    }
  }

  const getUserTokenGoogle = async (firebaseResponse: any) => {
    try {
      await fetcher(LoginWithProviderEndpoint.google({
        idToken: firebaseResponse?.credential.idToken,
        accessToken: firebaseResponse?.credential.accessToken,
        referrerId: referredAction === REFER_FRIEND ? referredId : undefined,
        provider: 'google'
      })).then(async response => {
        persistUserToken(response?.token);
        validateReferFriend(response?.new_account);
        await getMe().then((user) => {
          closeOnboarding();
          initRoles();
          initPermissions();
          toast({
            title: i18n[browserLocale].toastSuccessLogin,
            colorScheme: 'olaggPink',
            status: 'success',
            description: '',
            position: 'bottom-right'
          })
          triggerAnalyticsAfterLogin('google')
        });
      })
        .finally(async () => {
          await supabase.auth.signOut()
          setIsLoadingSpinner(false)
          handleRedirect('google')
        })

    } catch (error: any) {
      toast({
        title: 'Error al iniciar sesión. Esta cuenta ya esta vinculada a otro usuario.',
        status: 'error',
        position: 'top',
        duration: 6000,
        isClosable: true
      })
    }
  }

  const getUserTokenFacebook = async (firebaseResponse: any) => {
    try {
      await fetcher(LoginWithProviderEndpoint.facebook({
        access_token: firebaseResponse?.credential.accessToken,
        referrerId: referredAction === REFER_FRIEND ? referredId : undefined,
      })).then(async response => {
        persistUserToken(response?.token);
        validateReferFriend(response?.new_account);
        await getMe().then((user) => {
          closeOnboarding();
          initRoles();
          initPermissions();
          toast({
            title: i18n[browserLocale].toastSuccessLogin,
            colorScheme: 'olaggPink',
            status: 'success',
            description: '',
            position: 'bottom-right'
          })
          triggerAnalyticsAfterLogin('facebook')
        });
      })
        .finally(async () => {
          await supabase.auth.signOut()
          setIsLoadingSpinner(false)
          handleRedirect('facebook')
        })

    } catch (error: any) {
      console.log(error.message);
    }
  }

  const getUserTokenDiscord = async (accessToken: any) => {
    try {
      await fetcher(LoginWithProviderEndpoint.discord({
        accessToken: accessToken,
        provider: 'discord',
        referrerId: referredAction === REFER_FRIEND ? referredId : undefined,
      })).then(async response => {
        persistUserToken(response?.token);
        validateReferFriend(response?.new_account);
        await getMe().then((user) => {
          if (response?.user?.authProviderData?.discord?.justLinked) {
            toast({
              title: i18n[browserLocale].toastAccountLinked,
              colorScheme: 'olaggPink',
              status: 'success',
              description: '',
              position: 'bottom-right'
            })
          } else {
            toast({
              title: i18n[browserLocale].toastSuccessLogin,
              colorScheme: 'olaggPink',
              status: 'success',
              description: '',
              position: 'bottom-right'
            })
            triggerAnalyticsAfterLogin('discord')
          }
        })
      })
        .catch((e) => {
          toast({
            title: 'Error al iniciar sesión. Esta cuenta ya esta vinculada a otro usuario.',
            status: 'error',
            position: 'top',
            duration: 6000,
            isClosable: true
          })
        })
        .finally(async () => {
          await supabase.auth.signOut()
          setIsLoadingSpinner(false)
          handleRedirect('discord')
        })

    } catch (error: any) {
      console.log(error.message);
    }
  }

  const getUserTokenTwitter = async (accessToken: any) => {
    try {
      await fetcher(ConnectWithProviderEndpoint.twitter({
        access_token: accessToken,
        provider: 'twitter',
      })).then(async response => {
        persistUserToken(response?.token);
        await getMe();
        closeOnboarding();
        initRoles();
        initPermissions();
        toast({
          title: i18n[browserLocale].toastAccountLinked,
          colorScheme: 'olaggPink',
          status: 'success',
          description: '',
          position: 'bottom-right'
        })
      })
        .finally(async () => {
          await supabase.auth.signOut()
          setIsLoadingSpinner(false)
          // handleRedirect()
        })

    } catch (error: any) {
      toast({
        title: 'Error al vincular cuenta de Twitter. Ya existe una cuenta vinculada a este usuario.',
        status: 'error',
        position: 'top',
        duration: 6000,
        isClosable: true
      })
    }
  }

  const persistUserToken = (token: any) => {
    const cookieOptions = {
      expires: 365,
      domain: import.meta.env.DEV ? 'localhost' : '.olagg.io'
    }
    Cookies.set('auth._token.local', token, cookieOptions)
  }

  const loginWithProvider = async (provider: keyof Providers) => {
    await supabase.auth.signOut()
    const providers = {
      'google': async () => {
        setIsLoadingGoogle(true);
        await signInWithGoogleProvider().then(async response => {
          await getUserTokenGoogle(response)
        })
          .finally(() => {
            setIsLoadingGoogle(false);
          })
      },
      'facebook': async () => {
        setIsLoadingFacebook(true);
        await signInWithFacebookProvider().then(async response => {
          await getUserTokenFacebook(response)
        })
          .finally(() => {
            setIsLoadingFacebook(false);
          })
      },
      'discord': async () => {
        setIsLoadingSpinner(true);
        await supabase.auth.signInWithOAuth({
          provider: 'discord'
        })
          .finally(() => {
            setIsLoadingSpinner(false);
          })
      },
      'twitter': async () => {
        setIsLoadingSpinner(true);
        await supabase.auth.signInWithOAuth({
          provider: 'twitter'
        })
          .finally(() => {
            setIsLoadingSpinner(false);
          })
      },
      'default': () => console.log("provider not found")
    }
    // @ts-ignore
    providers[provider] ? providers[provider]() : providers['default']
  }

  const isLoginRoute = location.pathname == '/login';

  useEffect(() => {
    if (navigator.language.startsWith('es')) {
      setBrowserLocale('es')
    } else {
      setBrowserLocale('en')
    }
    setIsLoading(false)

  }, [])

  const i18n = {
    es: {
      mainText: 'Bienvenido a la comunidad Web3 más grande y activa del mundo hispano.',
      buttonsText: 'Continuar con',
      toastSuccessLogin: '¡Login exitoso!',
      toastAccountLinked: 'La cuenta se vinculó con éxito'
    },
    en: {
      mainText: '', //'Welcome to the largest and most active Web3 community in the Hispanic world.',
      buttonsText: 'Continue with',
      toastSuccessLogin: '¡Successful login!',
      toastAccountLinked: 'The account was successfully linked'
    }
  }

  if (forceDiscord) {
    return (
      <Modal closeOnOverlayClick={false} closeOnEsc={false} isOpen={isOnboardingOpen} onClose={closeWithDiscordForced}>
        <ModalOverlay />
        <ModalContent rounded="xl">
          <ModalHeader>
            <Heading as="h2" fontSize="26px" fontWeight="600" color="olaggPurple.400" textAlign="left" px="4">
              Vincular cuenta de Discord
            </Heading>
          </ModalHeader>
          <ModalCloseButton color="gray" />
          <ModalBody>
            <Flex
              margin="0 auto"
              align="center"
            >
              <VStack px={5} w="full" spacing="0">
                <Box
                  fontSize={{
                    base: "18px",
                    md: "18px",
                  }}
                  fontWeight="600"
                  lineHeight="tall"
                  alignSelf="stretch"
                  textAlign="center"
                  textColor="whiteAlpha.800"
                  px={{ base: 4, md: 30 }}
                >
                  <Text>
                    {i18n[browserLocale].mainText}
                  </Text>
                </Box>

                <Center w="full" flexDir="column">
                  <Flex gap="4" direction="column" w="full">

                    <Text fontSize="18px" fontWeight="600" color="black.800" textAlign="left">
                      Para participar de los torneos y no perderte ninguna información, es necesario vincular tu cuenta de Discord.
                    </Text>
                    <Button
                      variant="filledGradient"

                      rightIcon={<FaArrowRight size={22} />}
                      alignItems="center"
                      fontSize="18px"
                      fontWeight="medium"
                      onClick={() => {
                        localStorage.setItem('redirect', window.location.pathname)
                        initRedirectPath()
                        loginWithProvider('discord')
                      }}
                      isLoading={isLoadingSpinner}
                    >
                      Vincular mi Discord
                    </Button>
                  </Flex>
                </Center>

              </VStack>
            </Flex>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <>
      <Modal closeOnOverlayClick={!isLoginRoute} closeOnEsc={!isLoginRoute} isOpen={isOnboardingOpen} onClose={closeOnboarding}>
        <ModalOverlay />
        <ModalContent background="olaggDark.100" bgImg="https://olagg-p.s3.amazonaws.com/bg-hero-home-pink-top.png" bgPosition="center" rounded="xl">
          <ModalHeader></ModalHeader>
          {
            !isLoginRoute && <ModalCloseButton color="white" />
          }
          <ModalBody>
            {isLoading ? (
              <Flex minH='380px' justifyContent='center' alignItems='center'>
                <Spinner color='white' />
              </Flex>
            ) : (
              <Flex
                margin="0 auto"
                align="center"
              >
                <VStack px={5} w="full" spacing="5">
                  <ColouredLogoWithText />
                  {
                    !isWebview &&
                    <Box
                      fontSize={{
                        base: "18px",
                        md: "18px",
                      }}
                      fontWeight="600"
                      lineHeight="tall"
                      alignSelf="stretch"
                      textAlign="center"
                      textColor="whiteAlpha.800"
                      px={{ base: 4, md: 30 }}
                    >
                      <Text>
                        {i18n[browserLocale].mainText}
                      </Text>
                    </Box>
                  }

                  <Center w="full" flexDir="column">
                    <Flex gap="4" direction="column" w="full">
                      <>
                        {isWebview && toggleWebViewMessage ? (
                          <>
                            <Flex direction='column' color='white' py="10px" textAlign='center' fontSize="20px" fontWeight='600'>
                              <Box>Para iniciar sesión con Google, abre este sitio en tu navegador.</Box>
                              <Button
                                variant="filledGradient"
                                alignItems="center"
                                fontSize="18px"
                                fontWeight="medium"
                                my='15px'
                                onClick={() => openInBrowser()}
                              >
                                Abrir en Navegador
                              </Button>
                              {/* <Box>
                              o copia esta URL y ábrelo en tu navegador:
                              <Button ml='10px' p="0" borderRadius='50%' background={'white'} color="#A0AEC0"
                                onClick={() => { copyText(baseUrlWebView) }}
                              >
                                <FaRegClone color="inherit" />
                              </Button>
                            </Box> */}
                              <Box mt='10px' mb='10px' fontSize='14px'>
                                o copia esta URL y ábrelo en tu navegador:
                              </Box>
                              <Box mb='20px' fontSize='14px'>
                                <Input readOnly value={baseUrlWebView} />
                              </Box>

                            </Flex>
                          </>
                        ) : (
                          <Button
                            variant="filledGradient"
                            leftIcon={<FaGoogle size={18} />}
                            alignItems="center"
                            fontSize="18px"
                            fontWeight="medium"
                            onClick={() => {
                              isWebview ? showWebviewMessage() : loginWithProvider('google')
                            }
                            }
                            isLoading={isLoadingGoogle}
                          >
                            {i18n[browserLocale].buttonsText} Google
                          </Button>

                        )}

                        <Button
                          variant="outlinedGradient"
                          leftIcon={<FaDiscord size={22} />}
                          alignItems="center"
                          fontSize="18px"
                          fontWeight="medium"
                          onClick={() => loginWithProvider('discord')}
                          isLoading={isLoadingSpinner}
                        >
                          {i18n[browserLocale].buttonsText} Discord
                        </Button>
                      </>
                    </Flex>
                  </Center>
                </VStack>
              </Flex>

            )}
          </ModalBody>

          <ModalFooter color='whiteAlpha.600' display='inline-block' textAlign='center' fontSize='0.9rem'>
            Al iniciar sesión, das por aceptada nuestra
            <Link onClick={() => handleViewPolicy('/privacidad')} ml='1'>Política de Privacidad</Link> y nuestros
            <Link onClick={() => handleViewPolicy('/tyc')} ml='1'>Términos y Condiciones</Link>
          </ModalFooter>
        </ModalContent >
      </Modal >
      <Modal closeOnOverlayClick={false} trapFocus={false} isCentered isOpen={isLoadingSpinner} onClose={() => { setIsLoadingSpinner(false) }}>
        <ModalOverlay />
        <ModalContent bgColor={"transparent"} bgPosition="center" rounded="xl">
          <ModalHeader></ModalHeader>
          <ModalBody textAlign="center">
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            ></Spinner>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

import { Flex, VStack, Heading, useToast, Button } from "@chakra-ui/react";
import { OlaggModule, PermissionEnum } from "@olagg/db-types";
import { useEffect, useState } from "react";
import useRoles from "../../../../features/Roles/hooks/useRoles";
import { useForm, FormProvider } from 'react-hook-form'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
    fetcher,
    AdminUserPreferencesEndpoints
} from '@olagg/api-hooks'
import OlaggFormInput from "../../../../components/Forms/OlaggFormInput";
import OlaggFormSelect from "../../../../components/Forms/OlaggFormSelect";
import { UserPreferencesStrings } from '../../types'
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from '../../../../consts/routes'

type FormData = {
    name: string,
    label: string,
    image: string,
    extraData: extraDataAttributes,
}

type extraDataAttributes = {
    en: {
        name: string,
        label: string
    },
    pt: {
        name: string,
        label: string
    }
}

const schema: Yup.Schema<FormData> = Yup
    .object({
        name: Yup.string().required('El Nombre es obligatorio'),
        label: Yup.string().required('El label es obligatorio'),
        image: Yup.string().required('La URL de la imagen es obligatoria'),
        extraData: Yup.object({
            en: Yup.object({
                name: Yup.string().optional().nullable(),
                label: Yup.string().optional().nullable(),
            }),
            pt: Yup.object({
                name: Yup.string().optional().nullable(),
                label: Yup.string().optional().nullable(),
            })
        })
    })
    .required()

const OpportunitiesFormEdit = ({
    selectedPreference
}: {
    selectedPreference: string
}) => {

    const params = useParams()
    const id = params.id!
    const navigate = useNavigate()
    const { missingPermission } = useRoles();
    const toast = useToast()
    const [isLoading, setIsLoading] = useState(false)

    const useFormMethods = useForm<FormData>({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const errors = useFormMethods.formState.errors;

    useEffect(() => {
        console.log(errors);
    }, [errors])

    const initForm = () => {
        fetcher(AdminUserPreferencesEndpoints[selectedPreference].find(id))
            .then((response) => {
                const { gamingPlatform } = response
                useFormMethods.setValue('name', gamingPlatform?.name)
                useFormMethods.setValue('extraData.en.name', gamingPlatform?.extraData?.en?.name)
                useFormMethods.setValue('extraData.pt.name', gamingPlatform?.extraData?.pt?.name)
                useFormMethods.setValue('label', gamingPlatform?.label)
                useFormMethods.setValue('extraData.en.label', gamingPlatform?.extraData?.en?.label)
                useFormMethods.setValue('extraData.pt.label', gamingPlatform?.extraData?.pt?.label)
                useFormMethods.setValue('image', gamingPlatform?.image)
            })
    }

    useEffect(() => {
        initForm()
    }, [id])

    const onSubmit = (dataForm) => {
        let payload = { gamingPlatform: dataForm }
        setIsLoading(true)
        fetcher(AdminUserPreferencesEndpoints[selectedPreference].update(id, payload))
            .then(() => {
                toast({
                    title: 'Preferencia de Usuario actualizada',
                    colorScheme: 'olaggPink',
                    status: 'success',
                    label: '',
                    position: 'bottom-right'
                })
                //LIMPAR FORM
                useFormMethods.reset()
                navigate(`${ROUTES.USER_PREFERENCES.all}?selectedPreference=${selectedPreference}`)
                setIsLoading(false)
            })
            .catch(e => {
                toast({
                    title: 'Error',
                    colorScheme: 'olaggYellow',
                    status: 'error',
                    label: e?.error?.message,
                    position: 'bottom-right'
                })
                setIsLoading(false)
            })
    }

    return (


        <Flex w={'full'} flexDirection='column'>

            <Flex position={'relative'} zIndex='0' mt={'20px'}>
                <FormProvider {...useFormMethods}>
                    <form onSubmit={useFormMethods.handleSubmit(onSubmit)} style={{ width: '100%' }}>

                        <OlaggFormInput
                            required={true}
                            label='Nombre en Español'
                            controlName='name'
                            placeholder='Nombre en español'
                            customStyles={{
                                fontWeight: '700'
                            }}
                        />

                        <Flex direction='column' w='full' bgColor='#20173d' p='8' mb={'40px'} borderRadius={'12px'}>

                            <OlaggFormInput
                                required={true}
                                label='Nombre en Inglés'
                                controlName='extraData.en.name'
                                placeholder='Nombre en inglés'
                                customStyles={{
                                    fontWeight: '700'
                                }}
                            />

                            <OlaggFormInput
                                required={true}
                                label='Nombre en Portugués'
                                controlName='extraData.pt.name'
                                placeholder='Nombre en portugués'
                                customStyles={{
                                    fontWeight: '700'
                                }}
                            />

                        </Flex>

                        <OlaggFormInput
                            required={true}
                            label='Label en Español'
                            controlName='label'
                            placeholder='Label en español'
                            customStyles={{
                                fontWeight: '700'
                            }}
                        />

                        <Flex direction='column' w='full' bgColor='#20173d' p='8' mb={'40px'} borderRadius={'12px'}>

                            <OlaggFormInput
                                required={true}
                                label='Label en Inglés'
                                controlName='extraData.en.label'
                                placeholder='Label en inglés'
                                customStyles={{
                                    fontWeight: '700'
                                }}
                            />

                            <OlaggFormInput
                                required={true}
                                label='Label en Portugués'
                                controlName='extraData.pt.label'
                                placeholder='Label en portugués'
                                customStyles={{
                                    fontWeight: '700'
                                }}
                            />

                        </Flex>

                        <OlaggFormInput
                            required={true}
                            label='URL Image'
                            controlName='image'
                            placeholder='URL de la imagen'
                            customStyles={{
                                fontWeight: '700'
                            }}
                        />


                        <Button
                            variant={'filledGradient'}
                            isLoading={isLoading}
                            type="submit"
                            w={'full'}
                            style={{
                                marginTop: '30px'
                            }}
                            isDisabled={isLoading || missingPermission(OlaggModule.GamingPlatform, PermissionEnum.EDIT)}
                        >
                            Actualizar
                        </Button>
                    </form>
                </FormProvider>
            </Flex>
        </Flex>
    )
};

export default OpportunitiesFormEdit;

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { AdminUserPreferencesEndpoints, fetcher } from '@olagg/api-hooks'
import MultiSelectModal from '@olagg/ui-kit/MultiSelectModal'
import Edit from '@olagg/ui-kit/svg/Edit'
import { useEffect, useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'


export default function ChoosePlatforms({
  platforms,
  setValue
}: {
  platforms?: string[]
  setValue: UseFormSetValue<any>
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [platformOptions, setPlatformOptions] = useState<string[]>([])
  const [selectedPlatforms, setSelectedPlatforms] = useState<
    string[]
  >(platforms || [])

  const handlePlatforms = (platforms: string[]) => {
    setValue('platforms', platforms)
    setSelectedPlatforms(platforms)
    onClose()
  }

  useEffect(() => {
    fetcher(AdminUserPreferencesEndpoints.platforms.all({ page: 1, limit: 100 }))
      .then(({ gamingPlatforms }) => {
        setPlatformOptions(
          gamingPlatforms.map(({ id, name }: { id: number, name: string }) => ({ id: id, label: name }))
        )
      })
  }, [])

  return (
    <FormControl pt={4}>
      <FormLabel htmlFor="categories">Plataformas</FormLabel>
      <Flex
        justify="space-between"
        align="end"
        cursor="pointer"
        onClick={onOpen}
      >
        {!selectedPlatforms?.length ? (
          <Text
            color="gray.500"
            fontWeight="400"
            fontSize="18px"
          >
            Selecciona las plataformas
          </Text>
        ) : (
          <Box
            display="inline-flex"
            flexWrap="wrap"
            w="100%"
            gap="1"
          >
            {selectedPlatforms?.map(platform => (
              <Flex
                key={platform}
                bg="olaggPink.100"
                p={1}
                borderRadius="4px"
              >
                <Text fontSize="15px" fontWeight="600">
                  {platformOptions.find(({ id }) => id === platform)?.label}
                </Text>
              </Flex>
            ))}
          </Box>
        )}
        <Edit color="#CBD5E0" />
      </Flex>

      <MultiSelectModal
        title="Selecciona las plataformas"
        defaultValues={platformOptions}
        loadedValues={selectedPlatforms}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handlePlatforms}
      />
    </FormControl>
  )
}

import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { VStack, Button, Box, Text, Flex, Switch } from '@chakra-ui/react'
import OlaggForm from '../../components/Forms/OlaggForm'
import OlaggFormSelect from '../../components/Forms/OlaggFormSelect'
import OlaggFormInput from '../../components/Forms/OlaggFormInput'
import OlaggMarkdown from '@olagg/ui-kit/OlaggMarkdown'
import { useAdminGames, fetcher, useAdminTournaments, AdminTokensEndpoints } from '@olagg/api-hooks'
import { TournamentData, TournamentOrganizerData, TokenData, Owner } from '@olagg/db-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { formTournamentSchema, FormTournamentData } from '@olagg/validation-schemas/tournament.schemas'
import { convertUTCToLocalISOString, toLocalTimezone } from '../../utils'
import useBlockchainTokenCatalog from '../BlockchainTokenCatalog/hooks/useBlockchainTokenCatalog'

interface ITournamentFormProps {
  tournament?: TournamentData;
  onSubmit: (dataForm: FormTournamentData) => void;
  section?: string | null;
};

type Instructions = {
  es?: string;
  en?: string;
  pt?: string;
}

const buildMatchInstructions = (tournament?: TournamentData) => ({
  es: tournament?.instructions || '',
  en: tournament?.extraData?.en?.instructions || '',
  pt: tournament?.extraData?.pt?.instructions || ''
}
)

const TournamentForm: FC<ITournamentFormProps> = ({ tournament, onSubmit, section }) => {
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [matchInstructions, setMatchInstructions] = useState<Instructions>(buildMatchInstructions(tournament));
  const editMatch = section === 'matches';
  const [autoMatchStart, setAutoMatchStart] = useState<boolean>(false);
  const [autoMatchStartValue, setAutoMatchStartValue] = useState<number>(5);
  const [staticOrganizers, setStaticOrganizers] = useState<any[]>([]);
  const [otherLangs, setOtherLangs] = useState<boolean>(tournament?.extraData?.translated || false);
  const [games, setGames] = useState<any[]>([]);
  const { all, blockchainTokens } = useBlockchainTokenCatalog();

  const useFormMethods = useForm<FormTournamentData>({
    resolver: yupResolver(formTournamentSchema),
    mode: 'onChange',
    defaultValues: {
      ...tournament,
      winnerTokenId: tournament?.winnerToken?.id?.toString(),
      participationTokenId: tournament?.participationToken?.id?.toString(),
    }
  });

  const { fetchOrganizers } = useAdminTournaments()

  const getOrganizerOptions = async () => fetchOrganizers().then(({ owners }) => {
    const organizersOptions = owners.map((organizer: Owner) => ({
      value: organizer.id, label: organizer.name
    }))
    setStaticOrganizers(organizersOptions)
  })

  const convertToLocalTimeZone = () => {
    // Convert timezones
    if (!tournament) return;

    const tournamentData: TournamentData = { ...tournament };
    tournamentData.startDate = toLocalTimezone(tournamentData.startDate);
    tournamentData.endDate = toLocalTimezone(tournamentData.endDate);
    tournamentData.inscriptionBases.openAt = toLocalTimezone(tournamentData.inscriptionBases.openAt);
    tournamentData.inscriptionBases.closeAt = toLocalTimezone(tournamentData.inscriptionBases.closeAt);
    tournamentData.matchStartDate = toLocalTimezone(tournamentData.matchStartDate);
    useFormMethods.reset(tournamentData);
  }

  const { asyncGameOptions } = useAdminGames({ page: 1, limit: 20 })

  const getInitialGames = async () => {
    const gamesOptions = await asyncGameOptions('');
    setGames(gamesOptions as any[]);
  }

  useEffect(() => {
    getOrganizerOptions()
    convertToLocalTimeZone()
    getInitialGames()
    all({
      page: 1,
      limit: 100
    });
  }, [tournament])

  const tokenOptions = tournament?.winnerToken ? [{ value: tournament.winnerToken.id, label: `${tournament.winnerToken.tokenId} - ${tournament.winnerToken.address}` }] : [];
  const participationTokenOptions = tournament?.participationToken ? [{ value: tournament.participationToken.id, label: `${tournament.participationToken.tokenId} - ${tournament.participationToken.address}` }] : [];
  const asyncTokenOptions = async (input: string) => {
    return new Promise(async (resolve) => {
      const { tokens } = await fetcher<{ tokens: TokenData[], meta: { count: number } }>(AdminTokensEndpoints.all({ name: input, page: 1, limit: 100 }))

      const tokenOptions = tokens?.map((token: TokenData) => ({
        value: token.id, label: `${token.tokenId} - ${token.address}`
      }));

      resolve(tokenOptions);
    });
  }

  const beforeSubmit = () => {
    if (autoMatchStart) {
      const autoMatchStartDate = new Date(Date.now() + autoMatchStartValue * 60000);
      useFormMethods.setValue('matchStartDate', convertUTCToLocalISOString(autoMatchStartDate.toISOString()).slice(0, -1) as any);
    }
    useFormMethods.setValue('extraData', {
      ...useFormMethods.getValues('extraData'),
      translated: otherLangs
    });
  }

  return (
    <OlaggForm
      useFormMethods={useFormMethods}
      onSubmit={onSubmit}
      style={{
        width: '100%'
      }}
    >
      <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
        {/* Main section */}
        <Flex
          w="full"
          justify="start"
          align="center"
          style={{ marginTop: '30px' }}
        >
          <label
            htmlFor="enableShowLocale"
            style={{ cursor: 'pointer' }}
          >
            Otros idiomas
          </label>
          <Switch
            id="enableShowLocale"
            colorScheme="purple"
            isChecked={otherLangs}
            size="md"
            ml={2}
            onChange={() => setOtherLangs(prev => !prev)}
          />
        </Flex>
        <Box w='full' display={!editMatch ? 'block' : 'none'}>
          <OlaggFormInput
            label='Título'
            controlName='title'
            required={true}
          />
          {otherLangs && <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
            <OlaggFormInput
              label='Título Inglés'
              controlName='extraData.en.title'
            />
            <OlaggFormInput
              label='Título Portugués'
              controlName='extraData.pt.title'
            />

          </Flex>}

          <OlaggFormInput
            label='Imagen de la Card'
            controlName='image'
            description='URL de la imagen para mostrar en la card, Ej.: https://...'
            required={true}
          />

          {otherLangs && <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
            <OlaggFormInput
              label='Imagen de la Card Inglés'
              controlName='extraData.en.image'
            />
            <OlaggFormInput
              label='Imagen de la Card Portugués'
              controlName='extraData.pt.image'
            />

          </Flex>}

          <OlaggFormInput
            label='Imagen del Banner (se recomienda tamaño 1366x400)'
            controlName='banner'
            description='URL de la imagen para mostrar como banner, Ej.: https://...'
            required={true}
          />

          {otherLangs && <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
            <OlaggFormInput
              label='Imagen del Banner Inglés'
              controlName='extraData.en.banner'
            />
            <OlaggFormInput
              label='Imagen del Banner Portugués'
              controlName='extraData.pt.banner'
            />

          </Flex>}

          <OlaggFormSelect
            label='Juego'
            controlName='gameId'
            required={true}
            isSearchable={true}
            staticOptions={games}
            asyncOptions={asyncGameOptions}
            newVersion
          />

          <OlaggFormSelect
            label='Organizador'
            controlName='organizerId'
            required={true}
            isSearchable={true}
            staticOptions={staticOrganizers}
            newVersion
          />

          <OlaggFormInput
            label='Slug GGTech'
            controlName='externalSlug'
            required={false}
          />

          <OlaggFormInput
            label='Región'
            controlName='region'
            required={false}
          />

          <OlaggFormInput
            label='Premios'
            controlName='reward'
            required={true}
          />

          {otherLangs && <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
            <OlaggFormInput
              label='Premios en Inglés'
              controlName='extraData.en.reward'
            />
            <OlaggFormInput
              label='Premios en Portugués'
              controlName='extraData.pt.reward'
            />

          </Flex>}

          <OlaggFormInput
            label='Fecha de inicio del torneo'
            controlName='startDate'
            inputType='datetime'
            description={`Zona horaria: ${currentTimeZone}`}
            datePickerProps={{ backgroundColor: 'white' }}
            required={true}
          />

          <OlaggFormInput
            label='Fecha de finalización del torneo'
            controlName='endDate'
            inputType='datetime'
            description={`Zona horaria: ${currentTimeZone} - Si no se especifica fecha, el torneo se considerará como finalizado 2 horas después de la fecha y hora de inicio`}
            datePickerProps={{ backgroundColor: 'white' }}
            required={false}
          />

          <OlaggFormInput
            label='Tipo de torneo'
            controlName='type'
            required={false}
          />

          <OlaggFormInput
            label='Instrucciones'
            controlName='instructions'
            description='Escribe las instrucciones (puedes usar texto con Markdown)'
            inputType='textarea'
            onChange={(e) => setMatchInstructions({ ...matchInstructions, es: e.target.value })}
          />

          <Text lineHeight='normal' fontWeight={600} mb='2'>
            Instrucciones (Vista previa)
          </Text>
          <Box w='full' mb='5' border='1px solid white' minH='10' borderRadius='5' p='3'>
            <OlaggMarkdown>
              {matchInstructions.es || tournament?.instructions || ''}
            </OlaggMarkdown>
          </Box>

          {otherLangs && <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
            <OlaggFormInput
              label='Instrucciones Inglés'
              controlName='extraData.en.instructions'
              onChange={(e) => setMatchInstructions({ ...matchInstructions, en: e.target.value })}
            />
            <Text lineHeight='normal' fontWeight={600} mb='2'>
              Instrucciones Inglés (Vista previa)
            </Text>
            <Box w='full' mb='5' border='1px solid white' minH='10' borderRadius='5' p='3'>
              <OlaggMarkdown>
                {matchInstructions.en || tournament?.extraData?.en?.instructions}
              </OlaggMarkdown>
            </Box>
            <OlaggFormInput
              label='Instrucciones Portugués'
              controlName='extraData.pt.instructions'
              onChange={(e) => setMatchInstructions({ ...matchInstructions, pt: e.target.value })}
            />
            <Text lineHeight='normal' fontWeight={600} mb='2'>
              Instrucciones Portugués (Vista previa)
            </Text>
            <Box w='full' mb='5' border='1px solid white' minH='10' borderRadius='5' p='3'>
              <OlaggMarkdown>
                {matchInstructions.pt || tournament?.extraData?.pt?.instructions}
              </OlaggMarkdown>
            </Box>

          </Flex>}

          <OlaggFormInput
            label='Cupos mínimos'
            controlName='inscriptionBases[min]'
            required={true}
          />

          <OlaggFormInput
            label='Cupos máximos'
            controlName='inscriptionBases[max]'
            required={false}
          />

          <OlaggFormInput
            label='Inicio de inscripción'
            controlName='inscriptionBases[openAt]'
            inputType='datetime'
            description={`Zona horaria: ${currentTimeZone}`}
            datePickerProps={{ backgroundColor: 'white' }}
            required={true}
          />

          <OlaggFormInput
            label='Fin de inscripción'
            controlName='inscriptionBases[closeAt]'
            inputType='datetime'
            description={`Zona horaria: ${currentTimeZone}`}
            datePickerProps={{ backgroundColor: 'white' }}
            required={false}
          />

          <OlaggFormInput
            label='Precio de entrada'
            controlName='inscriptionBases[price]'
            required={false}
          />

          <OlaggFormInput
            label='Link para participar'
            controlName='inscriptionBases[link]'
            required={true}
          />

          <OlaggFormInput
            label='XP de participación'
            controlName='participationScore'
            description='XP que recibe el usuario por participar del torneo'
            required={false}
          />

          <OlaggFormInput
            label='XP de ganador'
            controlName='winnerScore'
            description='XP que recibe el ganador del torneo'
            required={false}
          />

          <OlaggFormInput
            label='Link con resultados del torneo'
            controlName='resultsUrl'
            required={false}
          />

          <OlaggFormSelect
            label='Token para ganador/es'
            controlName='winnerTokenId'
            isSearchable={true}
            staticOptions={tokenOptions}
            asyncOptions={asyncTokenOptions}
            placeholder='Buscar por token Id o dirección del contrato'
            required={false}
            newVersion
          />

          <OlaggFormSelect
            label='Token para participante/s'
            controlName='participationTokenId'
            isSearchable={true}
            staticOptions={participationTokenOptions}
            asyncOptions={asyncTokenOptions}
            placeholder='Buscar por token Id o dirección del contrato'
            required={false}
            newVersion
          />

          <OlaggFormSelect
            label='Blockchain Token'
            controlName='blockchainTokenId'
            staticOptions={blockchainTokens?.map(blockchainToken => ({
                value: blockchainToken.id,
                label: blockchainToken.name
            }))}
            required={false}
            newVersion
          />

          <OlaggFormInput
            label='Require Login'
            controlName='requiredAuth'
            inputType='switch'
            required={false}
          />

          <OlaggFormInput
            label='Requiere Discord vinculado'
            description='Será necesario vincular el Discord para poder participar del torneo'
            controlName='requiredDiscord'
            inputType='switch'
            required={false}
          />

          <OlaggFormInput
            label='Mostrar en Banner'
            controlName='showInBanner'
            inputType='switch'
            required={false}
          />

          <OlaggFormInput
            label='Torneo Cancelado'
            controlName='canceled'
            inputType='switch'
            required={false}
            chakraStyles={{ colorScheme: 'red' }}
          />

          {/* Button Text section */}
          <Text lineHeight='normal' fontWeight={600} mb='2'>
            Antes de abrir inscripciones
          </Text>
          <Flex px={8} py={4} direction={"column"}>
            <Flex justifyContent={'space-between'} gap={4}>
              <OlaggFormInput
                label='Español'
                controlName='buttonText.beforeInscription.text'
                required={false}
              />
              {otherLangs && (
                <>
                  <OlaggFormInput
                    label='Inglés'
                    controlName='buttonText.en.beforeInscription.text'
                    required={false}
                  />
                  <OlaggFormInput
                    label='Portugués'
                    controlName='buttonText.pt.beforeInscription.text'
                    required={false}
                  />
                </>)
              }
            </Flex>
            <Flex>
              <OlaggFormInput
                label='Link'
                controlName='buttonText.beforeInscription.link'
                required={false}
              />
            </Flex>
          </Flex>

          <Text lineHeight='normal' fontWeight={600} mb='2'>
            Inscripciones abiertas, antes de iniciar la partida
          </Text>
          <Flex px={8} py={4} direction={"column"}>
            <Flex justifyContent={'space-between'} gap={4}>
              <OlaggFormInput
                label='Español'
                controlName='buttonText.betweenInscriptionAndTournament.text'
                required={false}
              />
              {otherLangs && (
                <>
                  <OlaggFormInput
                    label='Inglés'
                    controlName='buttonText.en.betweenInscriptionAndTournament.text'
                    required={false}
                  />
                  <OlaggFormInput
                    label='Portugués'
                    controlName='buttonText.pt.betweenInscriptionAndTournament.text'
                    required={false}
                  />
                </>
              )}
            </Flex>
            <Flex>
              <OlaggFormInput
                label='Link'
                controlName='buttonText.betweenInscriptionAndTournament.link'
                required={false}
              />
            </Flex>
          </Flex>

          <Text lineHeight='normal' fontWeight={600} mb='2'>
            Durante el torneo
          </Text>
          <Flex px={8} py={4} direction={"column"}>
            <Flex justifyContent={'space-between'} gap={4}>
              <OlaggFormInput
                label='Español'
                controlName='buttonText.inTournament.text'
                required={false}
              />
              {otherLangs && (
                <>
                  <OlaggFormInput
                    label='Inglés'
                    controlName='buttonText.en.inTournament.text'
                    required={false}
                  />
                  <OlaggFormInput
                    label='Portugués'
                    controlName='buttonText.pt.inTournament.text'
                    required={false}
                  />
                </>
              )}
            </Flex>
            <Flex>
              <OlaggFormInput
                label='Link'
                controlName='buttonText.inTournament.link'
                required={false}
              />
            </Flex>
          </Flex>

          <Text lineHeight='normal' fontWeight={600} mb='2'>
            Después el torneo
          </Text>
          <Flex px={8} py={4} direction={"column"}>
            <Flex justifyContent={'space-between'} gap={4}>
              <OlaggFormInput
                label='Español '
                controlName='buttonText.afterTournament.text'
                required={false}
              />
              {otherLangs && (
                <>
                  <OlaggFormInput
                    label='En Inglés'
                    controlName='buttonText.en.afterTournament.text'
                    required={false}
                  />

                  <OlaggFormInput
                    label='En Portugués'
                    controlName='buttonText.pt.afterTournament.text'
                    required={false}
                  />
                </>
              )}
            </Flex>
            <Flex>
              <OlaggFormInput
                label='Link'
                controlName='buttonText.afterTournament.link'
                required={false}
              />
            </Flex>
          </Flex>
        </Box>

        {/* Edit Match section */}
        <Box w='full' display={editMatch ? 'block' : 'none'}>
          <OlaggFormInput
            label='Código de la partida'
            controlName='matchCode'
            required={editMatch}
          />

          <OlaggFormInput
            label='Inicio de la partida'
            controlName='matchStartDate'
            inputType='datetime'
            description={`Zona horaria: ${currentTimeZone}`}
            datePickerProps={{ backgroundColor: 'white' }}
            required={editMatch}
            chakraStyles={{ isDisabled: autoMatchStart }}
          />

          <Flex mb='5' alignItems='center'>
            <Switch
              mr='3'
              onChange={() => { setAutoMatchStart(!autoMatchStart) }}
              isChecked={autoMatchStart}
            >
            </Switch>
            Iniciar automáticamente dentro de
            <input
              type='number' min={1}
              value={autoMatchStartValue}
              onChange={(e) => { setAutoMatchStartValue(Number(e.target.value)); setAutoMatchStart(true) }}
              style={{
                marginInline: '5px',
                padding: '5px',
                background: 'none',
                border: '1px solid white',
                borderRadius: '6px',
                maxWidth: '40px',
                textAlign: 'center',
              }}
            ></input>
            minutos una vez guardados los cambios
          </Flex>

          <OlaggFormInput
            label='Instrucciones de la partida'
            controlName='matchInstructions'
            description='Escribe las instrucciones para la partida (puedes usar texto con Markdown)'
            inputType='textarea'
            required={false}
            onChange={(e) => setMatchInstructions({ ...matchInstructions, es: e.target.value })}
          />

          <Text lineHeight='normal' fontWeight={600} mb='2'>
            Instrucciones de la partida (Vista previa)
          </Text>
          <Box w='full' mb='5' border='1px solid white' minH='10' borderRadius='5' p='3'>
            <OlaggMarkdown>
              {matchInstructions.es || ""}
            </OlaggMarkdown>
          </Box>

          {otherLangs && <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
            <OlaggFormInput
              label='Instrucciones de la partida en Inglés'
              controlName='extraData.en.matchInstructions'
              onChange={(e) => setMatchInstructions({ ...matchInstructions, en: e.target.value })}
            />
            <Text lineHeight='normal' fontWeight={600} mb='2'>
              Instrucciones Inglés (Vista previa)
            </Text>
            <Box w='full' mb='5' border='1px solid white' minH='10' borderRadius='5' p='3'>
              <OlaggMarkdown>
                {matchInstructions.en || ""}
              </OlaggMarkdown>
            </Box>
            <OlaggFormInput
              label='Instrucciones de la partida en Portugués'
              controlName='extraData.pt.matchInstructions'
              onChange={(e) => setMatchInstructions({ ...matchInstructions, pt: e.target.value })}
            />
            <Text lineHeight='normal' fontWeight={600} mb='2'>
              Instrucciones Portugués (Vista previa)
            </Text>
            <Box w='full' mb='5' border='1px solid white' minH='10' borderRadius='5' p='3'>
              <OlaggMarkdown>
                {matchInstructions.pt || ""}
              </OlaggMarkdown>
            </Box>

          </Flex>}

          <OlaggFormInput
            label='Canal de Discord'
            controlName='discordChannel'
            required={false}
          />

          <OlaggFormInput
            label='URL del canal de Discord'
            controlName='discordUrl'
            required={false}
          />

          <OlaggFormInput
            label='Enviar notificacion por Discord'
            controlName='discordNotification'
            inputType='switch'
            required={false}
          />
        </Box>

        <Button
          onClick={beforeSubmit}
          variant={'filledGradient'}
          type="submit"
          w={'full'}
          style={{
            marginTop: '30px'
          }}
        >
          Guardar
        </Button>

      </VStack>
    </OlaggForm >
  )
}

export default TournamentForm;

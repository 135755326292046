import { Button, useToast, Text } from "@chakra-ui/react";
import {
    fetcher,
    AdminPaymentsEndpoints
} from '@olagg/api-hooks'
import { useEffect, useState } from "react";
import { useSendTransaction } from "thirdweb/react";
import { getContract, readContract } from "thirdweb";
import ERC20_ABI from './erc20_abi.json'
import AIRDROP_ABI from './airdrop_abi.json'
import { ethers } from "ethers";

export function ExecuteAirdrop({
    payments,
    contractAddress,
    airdropContractAddress,
    setIsOwnerConnected,
    walletAddress,
    chain,
    client
}: {
    payments: any,
    contractAddress: string,
    airdropContractAddress: string,
    setIsOwnerConnected: (value: boolean) => void,
    walletAddress: string,
    client: any,
    chain: any
}) {

    const toast = useToast()

    const { mutateAsync: sendTransaction, isPending } = useSendTransaction();
    const [isExecuting, setIsExecuting] = useState(false);
    const [isLoadingDecimals, setIsLoadingDecimals] = useState(true);
    const [owner, setOwner] = useState<string | null>(null);
    const [decimals, setDecimals] = useState<number | null>(null);

    useEffect(() => {
        const fetchContractData = async () => {
            try {
                const contract = getContract({
                    client,
                    address: contractAddress,
                    chain: chain,
                    abi: ERC20_ABI,
                });

                const airdropContract = getContract({
                    client,
                    address: airdropContractAddress,
                    chain: chain,
                    abi: AIRDROP_ABI,
                });

                const ownerAddress = await readContract({
                    contract: airdropContract,
                    method: "owner",
                    params: [],
                });

                setOwner(ownerAddress);

                const tokenDecimals = await readContract({
                    contract,
                    method: "decimals",
                    params: [],
                });

                setDecimals(Number(tokenDecimals));
                setIsLoadingDecimals(false);
            } catch (error) {
                console.error("Error obteniendo datos del contrato:", error);
                setIsLoadingDecimals(false);
            }
        };

        fetchContractData();
    }, [walletAddress]);

    const formatAirdropData = (data: { nonce: string; quantity: number; address: string }[]) => {
        const paymentsMap: Record<string, bigint> = {};

        data.forEach(({ address, quantity }) => {
            if (decimals !== null) {
                const amountInWei = BigInt(Math.floor(quantity * 10 ** decimals));
                paymentsMap[address] = (paymentsMap[address] || 0n) + amountInWei;
            }
        });

        return Object.entries(paymentsMap).map(([recipient, amount]) => ({
            recipient,
            amount: amount.toString(),
        }));
    };

    const handleAirdrop = async () => {
        try {
            setIsExecuting(true);
            const data = formatAirdropData(payments);

            const iface = new ethers.utils.Interface(AIRDROP_ABI);
            const encodedData = iface.encodeFunctionData("airdropERC20", [contractAddress, data]);

            const tx = await sendTransaction({
                to: airdropContractAddress,
                data: encodedData,
                chain: chain,
                client
            });

            const { transactionHash } = tx

            const transformedPayload = {
                data: payments.map(({ nonce }) => ({ tx_id: transactionHash, nonce }))
            };

            toast({
                title: 'Transacción enviada',
                colorScheme: 'olaggPink',
                status: 'success',
                description: 'Airdrop ejecutado con éxito',
                position: 'bottom-right'
            })

            fetcher(AdminPaymentsEndpoints.bulk_update_after_airdrop(transformedPayload))
                .then(() => {
                    toast({
                        title: `Pagos actualizados`,
                        colorScheme: 'olaggPink',
                        status: 'success',
                        description: `TX_ID: ${transactionHash}`,
                        position: 'bottom-right'
                    })

                    window.location.reload()
                })
                .catch(e => {
                    console.error("Error ejecutando el airdrop:", e);
                    toast({
                        title: 'Error al guardar tx_id de pago',
                        colorScheme: 'olaggPink',
                        status: 'error',
                        description: `TX_ID: ${transactionHash}. Verifica que efectivamente se hizo la transferencia antes de re intentar.`,
                        position: 'bottom-right'
                    })
                })

        } catch (error) {
            console.error("Error ejecutando el airdrop:", error);
            toast({
                title: "Error ejecutando el airdrop",
                colorScheme: "red",
                status: "error",
                position: "bottom-right",
            });
        } finally {
            setIsExecuting(false);
        }
    };

    if (owner?.toLowerCase() !== walletAddress.toLowerCase()) {
        setIsOwnerConnected(false)
        return <Text>Para ejecutar el Airdrop debes conectar la billetera del owner del contrato</Text>
    } else {
        setIsOwnerConnected(true)
    }

    return (
        <Button
            w="full"
            variant={"outline"}
            isLoading={isExecuting || isLoadingDecimals}
            onClick={handleAirdrop}
            disabled={isExecuting || isLoadingDecimals}
        >
            Ejecutar Airdrop
        </Button>
    )
}

import {
    Flex,
    useToast,
    Badge,
    TableContainer,
    Table as ChakraTable,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Button,
    useDisclosure,
    Select,
    Text,
    Input,
    InputGroup,
    InputRightElement
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import moment from 'moment/moment.js'
import 'moment/min/locales'

import {
    fetcher,
    AdminPaymentsEndpoints,
} from '@olagg/api-hooks'
import ModalCancel from "./modalCancel";
import ModalConfirm from "./modalConfirm";
import ModalDetail from "./modalDetail";
import Cookies from "js-cookie";
import { useSearchParams } from 'react-router-dom'
import Pagination from "@olagg/ui-kit/Pagination";
import useRoles from "../../../features/Roles/hooks/useRoles";
import { OlaggModule, PermissionEnum } from "@olagg/db-types";
import { BsSearch } from "react-icons/bs";
import useBlockchainNetwork from "../../BlockchainTokenCatalog/hooks/useBlockchainNetwork";
import useBlockchainTokenCatalog from "../../BlockchainTokenCatalog/hooks/useBlockchainTokenCatalog";

const History = ({ tabIndex }: { tabIndex: number }) => {
    const { missingPermission } = useRoles();
    const toast = useToast()
    const { onOpen: onOpenCancelModal, onClose: onCloseCancelModal, isOpen: isOpenCancelModal } = useDisclosure();
    const { onOpen: onOpenConfirmModal, onClose: onCloseConfirmModal, isOpen: isOpenConfirmModal } = useDisclosure();
    const { onOpen: onOpenDetailModal, onClose: onCloseDetailModal, isOpen: isOpenDetailModal } = useDisclosure();
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingExport, setIsLoadingExport] = useState(false)
    const [payments, setPayments] = useState([])
    const [selectedPayment, setSelectedPayment] = useState({})

    const { all: allNetworks, blockchainNetworks } = useBlockchainNetwork();
    const { all: allTokens, blockchainTokens } = useBlockchainTokenCatalog();

    const networksArr = ['polygon', 'ronin', 'ethereum']
    const tokensArr = ['USDC', 'USDT', 'RON', 'AXS', 'ETH']
    const [selectedNetwork, setSelectedNetwork] = useState('')
    const [selectedToken, setSelectedToken] = useState('')

    const filteredBlockchainTokens = selectedNetwork
    ? blockchainTokens?.filter(token => token.network.name === selectedNetwork)
    : blockchainTokens;

    const [searchParams, setSearchParams] = useSearchParams()
    const [count, setCount] = useState(0)
    const [filter, setFilter] = useState({
        userId: searchParams.get('userId') || '',
        budget: searchParams.get('budget') || '',
        page: Number(searchParams.get('page') || 1),
        limit: Number(searchParams.get('limit') || 10)
    })

    const onFilter = ({
        name,
        value
    }: EventTarget & HTMLInputElement) => {
        setFilter({ ...filter, [name]: value })

        if (value) searchParams.set(name, value)
        else searchParams.delete(name)
        setSearchParams(searchParams)
    }

    const { page, limit } = filter

    const initRequest = useCallback(async () => {
        setIsLoading(true)
        await fetcher(
            AdminPaymentsEndpoints
                .all(filter)
        ).then((response) => {
            const { payments, meta } = response
            setPayments(payments)
            setCount(meta.count)
        })
        setIsLoading(false)
    }, [])

    useEffect(() => {
        initRequest();
        allNetworks({ page: 1, limit: 100 });
        allTokens({ page: 1, limit: 100 });
    }, [])

    useEffect(() => {
        if (tabIndex == 0) initRequest()

    }, [tabIndex])

    const filterPayments = async () => {
        setIsLoading(true)
        await fetcher(
            AdminPaymentsEndpoints
                .all(filter)
        ).then((response) => {
            const { payments, meta } = response
            setPayments(payments)
            setCount(meta.count)
        })
        setIsLoading(false)
    }

    useEffect(() => {
        filterPayments()
    }, [filter])

    const Status = (props) => {
        switch (props.status) {
            case 'pending':
                return <Badge borderRadius={'20px'} textAlign='center' fontSize={'14px'} py={'2px'} width={'75px'} color='black' bgColor={'#FFD600'} fontWeight='500' textTransform={'none'}>Pendiente</Badge>
                break;
            case 'approved':
                return <Badge borderRadius={'20px'} textAlign='center' fontSize={'14px'} py={'2px'} width={'75px'} color='black' bgColor={'#38ABFF'} fontWeight='500' textTransform={'none'}>Aprobado</Badge>
                break;
            case 'paid':
                return <Badge borderRadius={'20px'} textAlign='center' fontSize={'14px'} py={'2px'} width={'75px'} color='black' bgColor={'#37A169'} fontWeight='500' textTransform={'none'}>Pagado</Badge>
                break;
            case 'canceled':
                return <Badge borderRadius={'20px'} textAlign='center' fontSize={'14px'} py={'2px'} width={'75px'} color='black' bgColor={'#FF6838'} fontWeight='500' textTransform={'none'}>Cancelado</Badge>
                break;
            case 'paying':
                return <Badge borderRadius={'20px'} textAlign='center' fontSize={'14px'} py={'2px'} width={'75px'} color='black' bgColor={'white'} fontWeight='500' textTransform={'none'}>Pagando</Badge>
                break;
            default:
                return <></>
                break
        }
    }

    const Actions = (props) => {
        switch (props.status) {
            case 'pending':
                return (
                    <Flex gap='10px'>
                        <Button isDisabled={missingPermission(OlaggModule.Payment, PermissionEnum.EDIT)} bgColor={'#E53E3E'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => cancelPayment(props)}>Cancelar pago</Button>
                        <Button isDisabled={missingPermission(OlaggModule.Payment, PermissionEnum.VIEW)} bgColor={'#3182CE'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => openDetail(props)}>Ver detalle</Button>
                    </Flex>
                )
                break;
            case 'approved':
                return (
                    <Flex gap='10px'>
                        <Button isDisabled={missingPermission(OlaggModule.Payment, PermissionEnum.EDIT)} bgColor={'#E53E3E'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => cancelPayment(props)}>Cancelar pago</Button>
                        <Button isDisabled={missingPermission(OlaggModule.Payment, PermissionEnum.EDIT)} bgColor={'#37A169'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => confirmPayment(props)}>Confirmar pago</Button>
                        <Button isDisabled={missingPermission(OlaggModule.Payment, PermissionEnum.VIEW)} bgColor={'#3182CE'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => openDetail(props)}>Ver detalle</Button>
                    </Flex>
                )
                break;
            case 'canceled':
                return (
                    <Button isDisabled={missingPermission(OlaggModule.Payment, PermissionEnum.VIEW)} bgColor={'#3182CE'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => openDetail(props)}>Ver detalle</Button>
                )
                break;
            case 'paying':
                return (
                    <Flex gap='10px'>
                        <Button isDisabled={missingPermission(OlaggModule.Payment, PermissionEnum.EDIT)} bgColor={'#37A169'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => confirmPayment(props)}>Confirmar pago</Button>
                        <Button isDisabled={missingPermission(OlaggModule.Payment, PermissionEnum.VIEW)} bgColor={'#3182CE'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => openDetail(props)}>Ver detalle</Button>
                    </Flex>
                )
                break;
            default:
                return <Button isDisabled={missingPermission(OlaggModule.Payment, PermissionEnum.VIEW)} bgColor={'#3182CE'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => openDetail(props)}>Ver detalle</Button>
                break
        }
    }

    const confirmPayment = async (data) => {
        console.log(data.id)
        setSelectedPayment(data)
        onOpenConfirmModal()
    }

    const cancelPayment = async (data) => {
        console.log(data.id)
        setSelectedPayment(data)
        onOpenCancelModal()
    }

    const openDetail = async (data) => {
        setSelectedPayment(data)
        onOpenDetailModal()
    }

    const exportCSV = async () => {
        setIsLoadingExport(true)
        try {
            const apiUrl = `${import.meta.env.VITE_API_URL}/admin/payments/export?network=${selectedNetwork}&token=${selectedToken}`
            const token = Cookies.get('auth._token.local') || null
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'text/csv'
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${new Date().toLocaleDateString('es')}_${selectedNetwork}_${selectedToken}`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);

            setIsLoadingExport(false)
        } catch (error) {
            console.log(error)
            setIsLoadingExport(false)
        }
    }

    const filterStatus = (status: string | null) => {
        onFilter({
            name: 'status',
            value: status
        } as EventTarget & HTMLInputElement)
    }

    return (
        <>
            <Flex w={'full'} pb={4} justifyContent='center' flexDirection={'column'}>
                <Flex w='full' justifyContent='start' px={'20px'} pb={'15px'} mt="6" gap="4" align="center">
                    <InputGroup w="400px">
                        <Input placeholder='Buscar por user ID o username...' color={'white'} onChange={(e) => onFilter({
                            name: 'userId',
                            value: e.target.value
                        } as EventTarget & HTMLInputElement)} />
                        <InputRightElement
                            children={<BsSearch color="white" />}
                        />
                    </InputGroup>
                    <InputGroup w="400px">
                        <Input placeholder='Buscar por presupuesto...' color={'white'} onChange={(e) => onFilter({
                            name: 'budget',
                            value: e.target.value
                        } as EventTarget & HTMLInputElement)} />
                        <InputRightElement
                            children={<BsSearch color="white" />}
                        />
                    </InputGroup>
                    <Text color={'white'} fontSize='18px' fontWeight="bold">Total: {count} pagos</Text>
                </Flex>
                <Flex flexDirection={'row'}>
                    <Flex px={'20px'} py={'15px'} gap='10px'>
                        <Select
                            placeholder='Seleccionar red'
                            color={'white'}
                            maxW='200px'
                            h={'34px'}
                            onChange={(e) => {
                                setSelectedNetwork(e.target.value)
                                onFilter({
                                    name: 'network',
                                    value: e.target.value
                                } as EventTarget & HTMLInputElement)
                            }}
                        >
                            {blockchainNetworks?.map(t => (
                                <option selected={selectedNetwork == t.name} value={t.name}>{t.name}</option>
                            ))}
                        </Select>
                        <Select
                            placeholder='Seleccionar token'
                            color={'white'}
                            maxW='200px'
                            h={'34px'}
                            onChange={(e) => {
                                setSelectedToken(e.target.value)
                                onFilter({
                                    name: 'token',
                                    value: e.target.value
                                } as EventTarget & HTMLInputElement)
                            }}
                        >
                            {filteredBlockchainTokens?.map(t => (
                                <option selected={selectedToken == t.name} value={t.name}>{t.name}</option>
                            ))}
                        </Select>
                    </Flex>
                    <Flex px={'20px'} py={'15px'} ml='auto' gap='10px'>
                        <Button onClick={() => filterStatus(null)} variant={!filter.status || filter?.status == null ? 'solid' : 'outline'} h='30px' p='1px 10px 0 10px'>Todos</Button>
                        <Button onClick={() => filterStatus('pending')} variant={filter?.status && filter?.status == 'pending' ? 'solid' : 'outline'} h='30px' p='1px 10px 0 10px'>Pendientes</Button>
                        <Button onClick={() => filterStatus('approved')} variant={filter?.status && filter?.status == 'approved' ? 'solid' : 'outline'} h='30px' p='1px 10px 0 10px'>Aprobados</Button>
                        <Button onClick={() => filterStatus('paid')} variant={filter?.status && filter?.status == 'paid' ? 'solid' : 'outline'} h='30px' p='1px 10px 0 10px'>Pagados</Button>
                        <Button onClick={() => filterStatus('canceled')} variant={filter?.status && filter?.status == 'canceled' ? 'solid' : 'outline'} h='30px' p='1px 10px 0 10px'>Cancelados</Button>
                        <Button onClick={() => filterStatus('paying')} variant={filter?.status && filter?.status == 'paying' ? 'solid' : 'outline'} h='30px' p='1px 10px 0 10px'>Pagando</Button>
                    </Flex>
                </Flex>
                <Flex w='full' justifyContent='center' px={'20px'} pb={'15px'}>
                    <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="100%" maxH="60vh">
                        <ChakraTable
                            variant="striped"
                            size="sm"
                            colorScheme="olaggPurple"
                            fontSize="18px"
                            position='relative'
                            whiteSpace={'normal'}
                        >
                            <Thead>
                                <Tr position='sticky' bg='gray.800' top='0' zIndex='100'>
                                    <Th py={'20px'} color="white">ID</Th>
                                    <Th py={'20px'} color="white">Creado por</Th>
                                    <Th py={'20px'} color="white">Fecha Creación</Th>
                                    <Th py={'20px'} color="white">Fecha Actualización</Th>
                                    <Th py={'20px'} color="white">Para usuario de OLA</Th>
                                    <Th py={'20px'} color="white">Token y cantidad</Th>
                                    <Th py={'20px'} color="white">Red</Th>
                                    <Th py={'20px'} color="white">Estado del pago</Th>
                                    <Th py={'20px'} color="white">Acciones</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {payments?.map((payment, index) => (
                                    <Tr key={index} color="white">
                                        <Td py={'20px'}>{payment?.id}</Td>
                                        <Td py={'20px'}>{payment?.creator.name}</Td>
                                        <Td py={'20px'}>{payment?.createdAt && moment(payment?.createdAt).format('DD/MM/YYYY')}</Td>
                                        <Td py={'20px'}>{payment?.updatedAt && moment(payment?.updatedAt).format('DD/MM/YYYY')}</Td>
                                        <Td py={'20px'}>{payment?.user.name}</Td>
                                        <Td py={'20px'}>{payment?.token} {payment?.quantity}</Td>
                                        <Td py={'20px'} textTransform='capitalize'>{payment?.network}</Td>
                                        <Td py={'20px'}><Status status={payment?.status} /></Td>
                                        <Td py={'20px'}>
                                            <Actions {...payment} />
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </ChakraTable>
                    </TableContainer>
                </Flex>

                <Flex px={'20px'} w='full' justifyContent={'center'}>

                    <Pagination
                        params={{ limit, page, count }}
                        onClick={page =>
                            onFilter({
                                name: 'page',
                                value: String(page + 1)
                            } as EventTarget & HTMLInputElement)
                        }
                        onLimitChange={val =>
                            onFilter({
                                name: 'limit',
                                value: val
                            } as EventTarget & HTMLInputElement)
                        }
                        hideLimitInput
                    />

                </Flex>

                <Flex px={'20px'} gap='20px' alignItems={'center'}>
                    <Button isDisabled={selectedToken == '' || selectedNetwork == '' || isLoadingExport || missingPermission(OlaggModule.Payment, PermissionEnum.EDIT)} isLoading={isLoadingExport} variant={'filledGradient'} p='0 10px' h='34px' fontSize={'14px'} onClick={exportCSV}>
                        Descargar CSV de aprobados red: {selectedNetwork == '' ? 'SIN RED' : selectedNetwork}, token: {selectedToken == '' ? 'SIN TOKEN' : selectedToken}
                    </Button>
                    {(selectedToken == '' || selectedNetwork == '') && (
                        <Text color={'red'} fontSize='14px'>Seleccionar Network y Token para exportar CSV de aprobados</Text>
                    )}
                </Flex>
            </Flex>
            <ModalCancel
                isOpen={isOpenCancelModal}
                onClose={onCloseCancelModal}
                selectedPayment={selectedPayment}
                refresh={initRequest}
            />
            <ModalConfirm
                isOpen={isOpenConfirmModal}
                onClose={onCloseConfirmModal}
                selectedPayment={selectedPayment}
                refresh={initRequest}
            />
            <ModalDetail
                isOpen={isOpenDetailModal}
                onClose={onCloseDetailModal}
                selectedPayment={selectedPayment}
            />
        </>
    )
};

export default History;

import { Center, Heading, useToast, VStack } from "@chakra-ui/react";
import { AdminStoriesEndpoints, fetcher } from "@olagg/api-hooks";
import { useNavigate } from "react-router-dom";
import Form from "./Form";

const Edit = () => {
    const toast = useToast();
    const navigate = useNavigate();

    const onSubmit = async (data: FormData) =>
        fetcher(AdminStoriesEndpoints.create(data))
            .then(() => {
                toast({
                    title: 'Historia creada',
                    status: 'success',
                    duration: 3000,
                    position: 'top-right',
                    isClosable: true,
                });
                navigate('/stories');
            })
            .catch(e => {
                console.error(e);
                toast({
                    title: 'Error al crear la historia: ' + e.errors,
                    position: 'top-right',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            });

    return (
        <>
            <Center w={'full'} pb={4}>
                <VStack color={'white'}>
                    <Heading my={2}>
                        Crear Historia
                    </Heading>
                    <Form onSubmit={onSubmit} />
                </VStack>
            </Center>
        </>
    )
};

export default Edit;
import {
  Button,
  Center,
  Flex,
  useToast,
  VStack,
  Heading,
  Switch,
  Box,
  Text
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  fetcher,
  AdminOwnersEndpoints,
  useAdminGames,
  useAdminOwnerQuests,
  useAdminQuestsByOwnerId
} from '@olagg/api-hooks'
import { OlaggModule, PermissionEnum, QuestOwner } from '@olagg/db-types'
import OlaggMarkdown from '@olagg/ui-kit/OlaggMarkdown'
import { formOwnerSchema, OwnerForm } from '@olagg/validation-schemas'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import useRoles from '../../features/Roles/hooks/useRoles'
import { shortenString } from '../../utils'
import OlaggForm from './OlaggForm'
import OlaggFormInput from './OlaggFormInput'
import OlaggFormSelect from './OlaggFormSelect'

interface IProps {
  owner: QuestOwner | null
  mode: 'edit' | 'create'
  mutate: () => void
}

const QuestOwnerForm: FC<IProps> = ({ mode, owner = {}, mutate }) => {

  const [gameFilter, setGameFilter] = useState()
  const [questsOptions, setQuestsOptions] = useState<[]>([])

  const {
    quests,
    error,
    isLoading,
  } = useAdminQuestsByOwnerId({ id: import.meta.env.VITE_OLA_OWNER_ID })

  const toast = useToast()
  const navigate = useNavigate()

  const isCreate = mode === 'create'
  const isEdit = mode === 'edit'

  const [heading, setHeading] = useState(`${isCreate ? 'Creando nuevo' : 'Editar'} Owner`)
  const { missingPermission } = useRoles();

  const { games } = useAdminGames({
    title: gameFilter,
    page: 1,
    limit: 1000
  })

  const { owners } = useAdminOwnerQuests({
    page: 1,
    limit: 1000
  })

  const useFormMethods = useForm<OwnerForm>({
    resolver: yupResolver(formOwnerSchema),
    mode: 'onChange',
    defaultValues: owner!
  });

  const [isEditable, setIsEditable] = useState(isEdit ? false : true)

  const [isPartner, setIsPartner] = useState(owner?.isPartner || false)
  const [isOrganizer, setIsOrganizer] = useState(owner?.organizer || false)
  const [instructions, setInstructions] = useState<string>('');

  const gameOptions = games.map(game => ({
    value: game.id,
    label: game.title
  })) || [{ value: '', label: 'No hay juegos disponibles' }]

  const orderOptions = owners?.map(owner => ({
    value: owner.order,
    label: `${owner.order} - ${owner.name}`
  })) || [{ value: '', label: 'No hay owners disponibles' }]

  const onSubmit = (data: OwnerForm) => {
    const body: Partial<QuestOwner> = {
      ...data,
      site: data.site || undefined,
      discordId: data.discordId || undefined,
      discordUsername: data.discordUsername || undefined,
      twitterId: data.twitterId || undefined,
      twitterUsername: data.twitterUsername || undefined,
      twitchId: data.twitchId || undefined,
      twitchUsername: data.twitchUsername || undefined,
      gameId: data.gameId || undefined,
      order: data.order,
      isPartner: data?.isPartner || false,
      organizer: isOrganizer,
      title: data?.title || '',
      darkMode: data?.darkMode || false,
      essentialQuests: { quests: data?.essentialQuests } || undefined,
      extraData: data?.extraData || undefined,
      instructions: data?.instructions || undefined,
      logoUrl: data?.logoUrl || undefined,
      mainColor: data?.mainColor || undefined,
      urlPath: data?.urlPath || undefined
    }
    if (isCreate) {
      fetcher(AdminOwnersEndpoints.create(body))
        .then(() => onSuccess('Owner creado correctamente'))
        .catch(e => onError(e))
    }

    if (isEdit) {
      console.log('body', body)
      fetcher(
        AdminOwnersEndpoints.update(owner!.id!, body)
      ).then(() =>
        onSuccess('Owner editado correctamente', 'El Owner fue editado con éxito')
      ).catch(onError)
    }
  }

  const onSuccess = async (title: string, body?: string) => {
    if (mode === 'create') useFormMethods.reset()

    toast({
      title,
      colorScheme: 'olaggPink',
      status: 'success',
      description: body || 'El Owner fue creado con éxito',
      position: 'bottom-right'
    })
    if (mode === 'edit') {
      mutate();
    }
  }

  const onError = (e: { message: string } | string) => {
    const message = typeof e === 'string' ? e : e.message
    toast({
      title: 'Error',
      colorScheme: 'olaggYellow',
      status: 'error',
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: message
          }}
        ></div>
      ),
      position: 'bottom-right'
    })
  }

  useEffect(() => {
    if (isEdit)
      return setHeading(
        `${isEditable ? 'Editar ' : ''}Owner ${shortenString({
          string: owner!.name!,
          characters: 18
        })}`
      )
  }, [isCreate, isEditable])

  const handleIsPartner = () => {
    if (isPartner) useFormMethods.setValue('isPartner', true)
    if (!isPartner) {
      useFormMethods.setValue('isPartner', false)
      useFormMethods.resetField('title')
      useFormMethods.resetField('darkMode')
      useFormMethods.resetField('essentialQuests')
      useFormMethods.resetField('extraData')
      useFormMethods.resetField('instructions')
      useFormMethods.resetField('logoUrl')
      useFormMethods.resetField('mainColor')
      useFormMethods.resetField('urlPath')
    }
  }

  useEffect(() => {
    handleIsPartner()
    // if (isEditable) {

    useFormMethods.setValue('title', owner?.title || '')
    useFormMethods.setValue('darkMode', owner?.darkMode || false)
    useFormMethods.setValue('essentialQuests', owner?.essentialQuests?.quests || [])
    useFormMethods.setValue('extraData', owner?.extraData || null)
    useFormMethods.setValue('instructions', owner?.instructions || '')
    useFormMethods.setValue('logoUrl', owner?.logoUrl || '')
    useFormMethods.setValue('mainColor', owner?.mainColor || '')
    useFormMethods.setValue('urlPath', owner?.urlPath || '')
    // }
  }, [isPartner])

  const mapQuestsOptions = () => {
    let qo: [] = []
    quests.map(q => {
      let obj = { value: q.id, label: q.title }
      qo.push(obj)
    })
    setQuestsOptions(qo)
  }

  useEffect(() => {
    quests.length > 0 && mapQuestsOptions()
  }, [quests])

  return (
    <Center w={'full'}>
      <VStack color={'white'}>
        <Heading my={2}>{heading}</Heading>
        {isEdit && (
          <Flex
            w="full"
            justify="end"
            align="center"
            style={{ marginTop: '30px' }}
          >
            <label
              htmlFor="enableEdition"
              style={{ cursor: 'pointer' }}
            >
              Habilitar edición
            </label>
            <Switch
              id="enableEdition"
              colorScheme="purple"
              size="md"
              ml={2}
              onChange={() => setIsEditable(prev => !prev)}
              isDisabled={missingPermission(OlaggModule.Owner, PermissionEnum.EDIT)}
            />
          </Flex>
        )}
        <OlaggForm
          useFormMethods={useFormMethods}
          onSubmit={onSubmit}
          style={{
            width: '100%'
          }}
        >
          <fieldset
            disabled={!(isEditable || isCreate)}
          >
            <VStack
              w={{ base: '70vw', lg: '50vw' }}
              mt={2}
              mb={10}
            >
              <OlaggFormInput
                label='Nombre'
                controlName='name'
                placeholder='Ej: Ola Guild Games'
                required={true}
              />

              <OlaggFormInput
                label='Sitio web del owner'
                controlName='site'
                placeholder='https://olagg.io/'
              />

              <OlaggFormSelect
                label='Juego'
                controlName='gameId'
                required={false}
                isSearchable={true}
                staticOptions={gameOptions}
                onInputChange={setGameFilter}
                newVersion
              />

              <OlaggFormInput
                label='Discord ID'
                controlName='discordId'
              />

              <OlaggFormInput
                label='Discord Username'
                controlName='discordUsername'
              />

              <OlaggFormInput
                label='Twitter ID'
                controlName='twitterId'
              />

              <OlaggFormInput
                label='Twitter Username'
                controlName='twitterUsername'
              />

              <OlaggFormInput
                label='Twitch ID'
                controlName='twitchId'
              />

              <OlaggFormInput
                label='Twitch Username'
                controlName='twitchUsername'
              />

              <OlaggFormSelect
                label='Orden'
                controlName='order'
                required={true}
                isSearchable={false}
                staticOptions={orderOptions}
                onInputChange={setGameFilter}
                newVersion
              />

              <Flex
                w="full"
                justify="start"
                align="center"
                style={{ marginTop: '30px' }}
              >
                <label
                  htmlFor="organizer"
                  style={{ cursor: 'pointer' }}
                >
                  Es organizador de torneos?
                </label>
                <Switch
                  id="organizer"
                  colorScheme="purple"
                  size="md"
                  ml={2}
                  onChange={() => setIsOrganizer(!isOrganizer)}
                  isChecked={isOrganizer}
                  defaultChecked={isOrganizer}
                />
              </Flex>

              {isOrganizer && (
                <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'}>
                  <OlaggFormInput
                    label='Logo para fondo blanco'
                    controlName='extraData.blackLogo'
                    required={true}
                  />

                  <OlaggFormInput
                    label='Logo para fondo oscuro'
                    controlName='extraData.whiteLogo'
                    required={true}
                  />
                </Flex>
              )}

              <Flex
                w="full"
                justify="start"
                align="center"
                style={{ marginTop: '30px' }}
              >
                <label
                  htmlFor="isPartner"
                  style={{ cursor: 'pointer' }}
                >
                  Es Partner
                </label>
                <Switch
                  id="isPartner"
                  colorScheme="purple"
                  size="md"
                  ml={2}
                  onChange={() => setIsPartner(!isPartner)}
                  isChecked={isPartner}
                  defaultChecked={isPartner}
                />
              </Flex>

              <OlaggFormInput
                controlName='isPartner'
                inputType='hidden'
                required={false}
              />

              {isPartner && (
                <>
                  <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'}>
                    <OlaggFormInput
                      label='URL path'
                      controlName='urlPath'
                      description='Slug del partner'
                      required={true}
                    />

                    <OlaggFormInput
                      label='Título'
                      controlName='title'
                      required={true}
                    />

                    <Flex direction='column' w='full' bgColor='#150B36' p='8' borderRadius={'12px'}>
                      <OlaggFormInput
                        label='Título Inglés'
                        controlName='extraData.en.title'
                      />
                      <OlaggFormInput
                        label='Título Portugés'
                        controlName='extraData.pt.title'
                      />
                    </Flex>
                    <br />

                    <OlaggFormInput
                      label='URL Imagen Logo'
                      controlName='logoUrl'
                      required={true}
                    />

                    <OlaggFormInput
                      label='URL Imagen de fondo'
                      controlName='extraData.bgImage'
                      required={false}
                    />

                    <OlaggFormInput
                      label='Color principal'
                      controlName='mainColor'
                      required={true}
                    />
                    <OlaggFormInput
                      label='Tema Oscuro ?'
                      controlName='darkMode'
                      inputType='switch'
                      description='Por defecto el tema es claro. Aqui puedes elegir por Oscuro'
                      required={false}
                    />

                    <OlaggFormInput
                      label='Instrucciones'
                      controlName='instructions'
                      description='Escribe las instrucciones (puedes usar texto con Markdown)'
                      inputType='textarea'
                      required={false}
                      onChange={(e) => setInstructions(e.target.value)}
                    />

                    <Flex direction='column' w='full' bgColor='#150B36' p='8' borderRadius={'12px'}>
                      <OlaggFormInput
                        label='Instrucciones (Inglés)'
                        controlName='extraData.en.instructions'
                        description='Escribe en Inglés las instrucciones (puedes usar texto con Markdown)'
                        inputType='textarea'
                        required={false}
                      />
                      <OlaggFormInput
                        label='Instrucciones (Portugués)'
                        controlName='extraData.pt.instructions'
                        description='Escribe en Portugués las instrucciones (puedes usar texto con Markdown)'
                        inputType='textarea'
                        required={false}
                      />
                    </Flex>
                    <br />

                    <Text lineHeight='normal' fontWeight={600} mb='2'>
                      Instrucciones (Vista previa)
                    </Text>
                    <Box w='full' border='1px solid white' minH='10' borderRadius='5' p='3' mb={'20px'}>
                      <OlaggMarkdown>
                        {instructions}
                      </OlaggMarkdown>
                    </Box>

                    <OlaggFormSelect
                      label='Seleccione misiones esenciales'
                      controlName='essentialQuests'
                      required={false}
                      isSearchable={true}
                      staticOptions={questsOptions}
                      multiple={true}
                      onChange={(selectedOptions) => {
                        useFormMethods.setValue('essentialQuests', selectedOptions);
                      }}
                    />

                  </Flex>
                  <br />

                  <Flex direction='column' w='full' bgColor='#150B36' p='8' borderRadius={'12px'}>
                    <Text sx={{ fontSize: '22px', fontWeight: '700', textAlign: 'center', width: '100%' }}>Meta tags</Text>

                    <OlaggFormInput
                      label='Title Español'
                      controlName='extraData.es.metas.title'
                    />
                    <OlaggFormInput
                      label='Title Inglés'
                      controlName='extraData.en.metas.title'
                    />
                    <OlaggFormInput
                      label='Title Portugés'
                      controlName='extraData.pt.metas.title'
                    />
                    <br />

                    <OlaggFormInput
                      label='Description Español'
                      controlName='extraData.es.metas.description'
                    />
                    <OlaggFormInput
                      label='Description Inglés'
                      controlName='extraData.en.metas.description'
                    />
                    <OlaggFormInput
                      label='Description Portugés'
                      controlName='extraData.pt.metas.description'
                    />
                    <br />

                    <OlaggFormInput
                      label='URL Image Cover Español (tamaño: 1200x630px)'
                      controlName='extraData.es.metas.image'
                    />
                    <OlaggFormInput
                      label='URL Image Cover Inglés (tamaño: 1200x630px)'
                      controlName='extraData.en.metas.image'
                    />
                    <OlaggFormInput
                      label='URL Image Cover Portugés (tamaño: 1200x630px)'
                      controlName='extraData.pt.metas.image'
                    />
                  </Flex>
                  <br />

                </>
              )}

              {isEditable && (
                <Flex w="full" style={{ marginTop: '20px' }}>
                  <Button
                    variant="filledGradient"
                    type="submit"
                    flex="1"
                  >
                    {isCreate ? 'Crear Owner' : 'Guardar cambios'}
                  </Button>
                  {isEdit && (
                    <Button
                      ml={2}
                      variant="outlinedGradient"
                      onClick={() => navigate(-1)}
                      flex="1"
                    >
                      Descartar Cambios
                    </Button>
                  )}
                </Flex>
              )}
            </VStack>
          </fieldset>
        </OlaggForm>
      </VStack>
    </Center>
  )
}

export default QuestOwnerForm

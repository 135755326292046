import { Flex, TableContainer, Table as ChakraTable, Thead, Tbody, Td, Th, Tr, Input, InputGroup, InputRightElement, Button, } from "@chakra-ui/react";
import { OlaggModule, PermissionEnum, Story } from "@olagg/db-types";
import Pagination from "@olagg/ui-kit/Pagination";
import moment from "moment";
import { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../../consts/routes";
import useRoles from "../../../../features/Roles/hooks/useRoles";
import useStories from "../../hooks/useStories";

type IProps = {
    tabIndex: number;
};

const Actions = ({ story }: { story: Story }) => {
    const { missingPermission } = useRoles();
    const { deleteStory } = useStories();
    const navigate = useNavigate();
    const viewStory = (story: Story) => navigate(`/stories/${story.id}`);
    const removeStory = (story: Story) => {
        if (window.confirm('¿Estás seguro que deseas eliminar esta historia?')) {
            deleteStory(story.id).then(() => {
                window.location.reload();
            })
        }
    }

    return (
        <Flex gap='10px'>
            <Button isDisabled={missingPermission(OlaggModule.Story, PermissionEnum.EDIT)} bgColor={'#37A169'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => viewStory(story)}>
                Ver
            </Button>
            <Button isDisabled={missingPermission(OlaggModule.Story, PermissionEnum.EDIT)} bgColor={'#E53E3E'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => removeStory(story)}>
                Eliminar
            </Button>
        </Flex>
    )
}

const List = ({ tabIndex }: IProps) => {
    const [stories, setStories] = useState<Story[]>([]);
    const [count, setCount] = useState(0);
    const { getAll } = useStories();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const loadStories = () =>
        getAll({ title, page, limit })
            .then((response) => {
                setStories(response.stories);
                setCount(response.count);
            });

    const [filter, setFilter] = useState({
        title: searchParams.get('title') || '',
        page: Number(searchParams.get('page') || 1),
        limit: Number(searchParams.get('limit') || 10)
    })

    const onFilter = ({
        name,
        value
    }: EventTarget & HTMLInputElement) => {
        setFilter({ ...filter, [name]: value })

        if (value) searchParams.set(name, value)
        else searchParams.delete(name)
        setSearchParams(searchParams)
    }

    const { page, limit, title } = filter

    useEffect(() => {
        loadStories();
    }, [filter]);

    return (
        <Flex gap={5} direction="column" mt={4}>
            <Flex flexDirection={'row'} justify="space-between" pr={6}>
                <Flex flex="1">
                    <InputGroup mx={5} w="50%">
                        <Input
                            color={"white"}
                            placeholder="Título"
                            name="title"
                            value={title}
                            onChange={(e) => onFilter(e.target)}
                        />
                        <InputRightElement children={<BsSearch color="white" />} />
                    </InputGroup>
                </Flex>
                <Button
                    variant="outlinedGradient"
                    leftIcon={<FaPlus />}
                    onClick={() => navigate(ROUTES.STORIES.create)}
                    mb='3'
                >
                    Crear Historia
                </Button>
            </Flex>
            <Flex w='full' justifyContent='center' px={'20px'} pb={'15px'}>
                <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="100%" maxH="60vh">
                    <ChakraTable
                        variant="striped"
                        size="sm"
                        colorScheme="olaggPurple"
                        fontSize="18px"
                        position='relative'
                        whiteSpace={'normal'}
                    >
                        <Thead>
                            <Tr position='sticky' bg='gray.800' top='0' zIndex='100'>
                                <Th py={'20px'} color="white">ID</Th>
                                <Th py={'20px'} color="white">Categoría</Th>
                                <Th py={'20px'} color="white">Título</Th>
                                <Th py={'20px'} color="white">Creado por</Th>
                                <Th py={'20px'} color="white">Fecha Creación</Th>
                                <Th py={'20px'} color="white">Fecha Vencimiento</Th>
                                <Th py={'20px'} color="white">Tipo</Th>
                                <Th py={'20px'} color="white">Acciones</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {stories?.map((story, index) => (
                                <Tr key={index} color="white">
                                    <Td py={'20px'}>{story?.id}</Td>
                                    <Td py={'20px'}>{story?.category?.name}</Td>
                                    <Td py={'20px'}>{story?.title}</Td>
                                    <Td py={'20px'}>{story?.author.name}</Td>
                                    <Td py={'20px'}>{story?.createdAt && moment(story?.createdAt).format('DD/MM/YYYY')}</Td>
                                    <Td py={'20px'}>{story?.dueAt && moment(story?.dueAt).format('DD/MM/YYYY')}</Td>
                                    <Td py={'20px'}>{story?.isImage ? "Imagen" : "Video"}</Td>
                                    <Td py={'20px'}>
                                        <Actions story={story} />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </ChakraTable>
                </TableContainer>
            </Flex>
            <Flex px={'20px'} w='full' justifyContent={'center'}>

                <Pagination
                    params={{ limit, page, count }}
                    onClick={page =>
                        onFilter({
                            name: 'page',
                            value: String(page + 1)
                        } as EventTarget & HTMLInputElement)
                    }
                    onLimitChange={val =>
                        onFilter({
                            name: 'limit',
                            value: val
                        } as EventTarget & HTMLInputElement)
                    }
                    hideLimitInput
                />

            </Flex>
        </Flex>
    )
};

export default List;
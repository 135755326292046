import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { VStack, Button, Box } from '@chakra-ui/react'
import OlaggForm from '../../components/Forms/OlaggForm'
import OlaggFormSelect from '../../components/Forms/OlaggFormSelect'
import OlaggFormInput from '../../components/Forms/OlaggFormInput'
import { Alert } from '@olagg/db-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { formAlertSchema, FormAlertData } from '@olagg/validation-schemas/alert.schemas'
import { typeOptions } from './constants'
import { toLocalTimezone } from '../../utils'

interface IAlertFormProps {
  alert?: Alert;
  onSubmit: (dataForm: FormAlertData) => void;
};

const AlertForm: FC<IAlertFormProps> = ({ alert, onSubmit }) => {
  const useFormMethods = useForm<FormAlertData>({
    resolver: yupResolver(formAlertSchema),
    mode: 'onChange',
  });

  useEffect(
    () => {
      if (alert) {
        const dataForm: FormAlertData = {
          ...alert,
          type: {
            value: alert.type,
            label: typeOptions.find(val => val.value === alert.type)?.label
          },
          showInPath: alert.showInPath.join(', ')
        }

        // convert timezones
        dataForm.startDate = toLocalTimezone(dataForm.startDate);
        dataForm.expirationDate = toLocalTimezone(dataForm.expirationDate);

        useFormMethods.reset(dataForm);
      }
    }, []
  )

  return (
    <OlaggForm
      useFormMethods={useFormMethods}
      onSubmit={onSubmit}
      style={{
        width: '100%'
      }}
    >
      <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
        <Box w='full'>
          <OlaggFormInput
            label='Titulo'
            controlName='title'
            required={true}
          />

          <OlaggFormInput
            label='Mensaje'
            controlName='message'
            description=''
            required={false}
          />

          <OlaggFormInput
            label='Mostrar en las rutas'
            controlName='showInPath'
            description='Para mostrar en mas de una ruta separa con comas. Ej.: "/torneos, /mis-tokens"'
            required={true}
          />

          <OlaggFormInput
            label='Texto del CTA'
            controlName='ctaLabel'
            description='Si el Url se deja en blanco, el botón no se muestra'
            required={false}
          />

          <OlaggFormInput
            label='Url del CTA'
            controlName='ctaUrl'
            description='Ej.: /torneos, https://www.google.com'
            required={false}
          />

          <OlaggFormInput
            label='Imagen'
            controlName='image'
            description={`Tamaño recomendado alto: 52px, ancho mínimo: 380px`}
            required={false}
          />

          <OlaggFormSelect
            label='Tipo de alerta'
            controlName='type'
            required={true}
            isSearchable={false}
            staticOptions={typeOptions}
          />
 
          <OlaggFormInput
            label='Fecha de inicio'
            controlName='startDate'
            inputType='datetime'
            description='Si se especifica, la alerta se mostrará a partir de esta fecha'
            datePickerProps={{ backgroundColor: 'white' }}
            required={false}
          />

          <OlaggFormInput
            label='Fecha de expiración'
            controlName='expirationDate'
            inputType='datetime'
            description='Pasada esta fecha la alerta ya no se mostrará'
            datePickerProps={{ backgroundColor: 'white' }}
            required={true}
          />

          <OlaggFormInput
            label='Visible'
            controlName='visible'
            inputType='switch'
            description=''
            required={false}
          />

          <OlaggFormInput
            label='Se puede cerrar'
            controlName='closable'
            inputType='switch'
            description=''
            required={false}
          />
        </Box>

        <Button
          variant={'filledGradient'}
          type="submit"
          w={'full'}
          style={{
            marginTop: '30px'
          }}
        >
          Guardar
        </Button>

      </VStack>
    </OlaggForm>
  )
}

export default AlertForm;

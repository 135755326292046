import { Center, Heading, VStack } from "@chakra-ui/react";

import Form from "./Form";
import useBlockchainNetwork from "../../hooks/useBlockchainNetwork";

const CreateBlockchainNetwork = () => {
  const { createBlockchainNetwork } = useBlockchainNetwork();

  return (
    <>
      <Center w={'full'} pb={4}>
        <VStack color={'white'}>
          <Heading my={2}>
              Crear Blockchain Network
          </Heading>
          <Form onSubmit={createBlockchainNetwork} />
        </VStack>
      </Center>
    </>
  )
};

export default CreateBlockchainNetwork;
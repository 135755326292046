import { BlockchainNetwork, OlaggModule, PermissionEnum } from "@olagg/db-types";
import { BlockchainNetworkSchema, BlockchainNetworkData } from "@olagg/validation-schemas";
import { Button, Flex } from "@chakra-ui/react";


import OlaGGFormInput from "../../../../components/Forms/OlaggFormInput";
import OlaggForm from "../../../../components/Forms/OlaggForm";
import { useForm } from "react-hook-form";
import useRoles from "../../../../features/Roles/hooks/useRoles";
import { yupResolver } from '@hookform/resolvers/yup'
import ArrayStringInput from "../customInput";

type IProps = {
  blockchainNetwork?: BlockchainNetwork | null;
  onSubmit: (data: FormData) => void;
};

const Form = ({ blockchainNetwork, onSubmit }: IProps) => {
  const { missingPermission } = useRoles();

  const useFormMethods = useForm<BlockchainNetworkData>({
    resolver: yupResolver(BlockchainNetworkSchema),
    mode: 'onChange',
    defaultValues: {
      ...blockchainNetwork
    }
  })

  return (
    <Flex w={'60vw'} mx={4} pb={4} minH='600px' justifyContent='center' direction={"column"}>
      <OlaggForm
        onSubmit={onSubmit}
        useFormMethods={useFormMethods}
        style={{
          width: '100%'
        }}
      >
        <OlaGGFormInput
          label='Nombre'
          controlName='name'
          placeholder='Nombre de la network'
          required={true}
        />
        <OlaGGFormInput
          label='Chain ID'
          controlName='chainId'
          placeholder='Chain Id de la network'
          required={true}
        />
        <OlaGGFormInput
          label='Native Currency Symbol'
          controlName='nativeCurrencySymbol'
          placeholder='ETH'
          required
        />
        <OlaGGFormInput
          label='Network Icon Url'
          controlName='networkIconUrl'
          placeholder=''
          required
        />
        <ArrayStringInput
          label='Block Explorers'
          controlName='blockExplorers'
          required
        />
        <ArrayStringInput
          label='Faucets'
          controlName='faucets'
        />
        <OlaGGFormInput
          label='Rpc Url'
          controlName='rpcUrl'
          placeholder=''
        />
        <OlaGGFormInput
          label='¿Es una testnet?'
          controlName='isTestnet'
          inputType='checkbox'
        />
        <Button
          variant={'filledGradient'}
          type="submit"
          w={'full'}
          style={{
            marginTop: '30px'
          }}
          isDisabled={missingPermission(OlaggModule.BlockchainTokenNetwork, blockchainNetwork ? PermissionEnum.CREATE : PermissionEnum.EDIT)}
        >
          Guardar
        </Button>
      </OlaggForm>
    </Flex>
  )

}

export default Form;

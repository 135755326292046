import { OlaggModule, PermissionEnum, Story, StoryCategory } from "@olagg/db-types";
import { useForm } from "react-hook-form";
import OlaggForm from "../../../../components/Forms/OlaggForm";
import { StoryData, storySchema } from "@olagg/validation-schemas"
import { yupResolver } from '@hookform/resolvers/yup'
import OlaGGFormInput from "../../../../components/Forms/OlaggFormInput";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import OlaggFormSelect from "../../../../components/Forms/OlaggFormSelect";
import useStoryCategories from "../../hooks/useStoryCategories";
import { useEffect, useState } from "react";
import useRoles from "../../../../features/Roles/hooks/useRoles";
import OlaggMarkdown from "@olagg/ui-kit/OlaggMarkdown";

type IProps = {
    story?: Story | null;
    onSubmit: (data: FormData) => void;
};

const StoryForm = ({ story, onSubmit }: IProps) => {
    const { missingPermission } = useRoles();
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [storyCategories, setStoryCategories] = useState<StoryCategory[]>([]);
    const [description, setDescription] = useState({
        es: story?.description || '',
        en: story?.extraData?.en?.description || '',
        pt: story?.extraData?.pt?.description || ''
    });
    const useFormMethods = useForm<StoryData>({
        resolver: yupResolver(storySchema),
        mode: 'onChange',
        defaultValues: {
            ...story,
            storyCategoryId: story?.category.id,
        }
    })

    const storyCategoriesOptions = storyCategories.map(category => ({
        value: category.id,
        label: category.name
    })) || [];

    const { getCategories } = useStoryCategories();

    useEffect(() => {
        getCategories({
            limit: 10,
            page: 1
        }).then(({ categories }) => setStoryCategories(categories));
    }, []);

    return (
        <Flex w={'60vw'} mx={4} pb={4} minH='600px' justifyContent='center' direction={"column"}>
            <OlaggForm
                onSubmit={onSubmit}
                useFormMethods={useFormMethods}
                style={{
                    width: '100%'
                }}
            >
                <OlaGGFormInput
                    label='Título'
                    controlName='title'
                    placeholder='Título de la historia'
                />
                <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
                    <OlaGGFormInput
                        label='Título en inglés'
                        controlName='extraData.en.title'
                        placeholder='History title'
                    />
                    <OlaGGFormInput
                        label='Título en portugués'
                        controlName='extraData.pt.title'
                        placeholder='Título da história'
                    />
                </Flex>
                <OlaGGFormInput
                    label='Descripción'
                    inputType="textarea"
                    controlName='description'
                    placeholder='Descripción de la historia'
                    onChange={(e) => setDescription({ ...description, es: e.target.value })}
                />
                <Box color='white'>
                    <Text fontSize='lg' fontWeight='bold' mb={2}>Vista previa de la descripción</Text>
                    <OlaggMarkdown>
                        {description.es || ''}
                    </OlaggMarkdown>
                </Box>
                <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
                    <OlaGGFormInput
                        label='Descripción en inglés'
                        controlName='extraData.en.description'
                        inputType="textarea"
                        placeholder='History description'
                        onChange={(e) => setDescription({ ...description, en: e.target.value })}
                    />
                    <Box color='white'>
                        <Text fontSize='lg' fontWeight='bold' mb={2}>Vista previa de la descripción en ingles</Text>
                        <OlaggMarkdown>
                            {description.en || ''}
                        </OlaggMarkdown>
                    </Box>
                    <OlaGGFormInput
                        label='Descripción en portugués'
                        controlName='extraData.pt.description'
                        inputType="textarea"
                        placeholder='Descrição da história'
                        onChange={(e) => setDescription({ ...description, pt: e.target.value })}
                    />
                    <Box color='white'>
                        <Text fontSize='lg' fontWeight='bold' mb={2}>Vista previa de la descripción en portugués</Text>
                        <OlaggMarkdown>
                            {description.pt || ''}
                        </OlaggMarkdown>
                    </Box>
                </Flex>
                <OlaggFormSelect
                    label="Categoría"
                    controlName={'storyCategoryId'}
                    staticOptions={storyCategoriesOptions}
                    required={true}
                    newVersion
                />
                <OlaGGFormInput
                    label='Fecha de vencimiento'
                    controlName='dueAt'
                    inputType='datetime'
                    description={`Zona horaria: ${currentTimeZone}`}
                    datePickerProps={{ backgroundColor: 'white' }}
                />
                <OlaGGFormInput
                    label='¿Es una imagen?'
                    controlName='isImage'
                    inputType='checkbox'
                />
                <OlaGGFormInput
                    label='Link de la imagen/video'
                    controlName='contentLink'
                    placeholder='Link de la imagen/video'
                />
                <OlaGGFormInput
                    label='Duración'
                    controlName='duration'
                    placeholder="Duración en segundos"
                />
                <OlaGGFormInput
                    label='Texto del CTA'
                    controlName='cta.text'
                    placeholder='Texto del CTA'
                />
                <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
                    <OlaGGFormInput
                        label='Texto del CTA en inglés'
                        controlName='extraData.en.cta.text'
                        placeholder='CTA text'
                    />
                    <OlaGGFormInput
                        label='Texto del CTA en portugués'
                        controlName='extraData.pt.cta.text'
                        placeholder='Texto do CTA'
                    />
                </Flex>
                <OlaGGFormInput
                    label='Link del CTA'
                    controlName='cta.link'
                    placeholder='Link del CTA'
                />
                <Button
                    variant={'filledGradient'}
                    type="submit"
                    w={'full'}
                    style={{
                        marginTop: '30px'
                    }}
                    isDisabled={missingPermission(OlaggModule.Story, story ? PermissionEnum.CREATE : PermissionEnum.EDIT)}
                >
                    Guardar
                </Button>
            </OlaggForm>
        </Flex>
    )

}

export default StoryForm;

import {
    Flex,
    useToast,
    Badge,
    TableContainer,
    Table as ChakraTable,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Button,
    useDisclosure,
    Select,
    Text,
    InputGroup,
    Input,
    InputLeftElement,
    Popover,
    PopoverTrigger,
    Portal,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverBody
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import moment from 'moment/moment.js'
import 'moment/min/locales'
import { UserPreferencesStrings } from '../../types'

import {
    fetcher,
    AdminUserPreferencesEndpoints
} from '@olagg/api-hooks'
import { useSearchParams, useNavigate, generatePath } from 'react-router-dom'
import Pagination from "@olagg/ui-kit/Pagination";
import useRoles from "../../../../features/Roles/hooks/useRoles";
import { OlaggModule, PermissionEnum } from "@olagg/db-types";
import { ROUTES } from '../../../../consts/routes'
import { BsSearch } from "react-icons/bs";

const List = ({
    tabIndex,
    selectedPreference
}: {
    tabIndex: number,
    selectedPreference: string
}) => {
    const { missingPermission } = useRoles();
    const toast = useToast()
    const [isLoading, setIsLoading] = useState(true)
    const [list, setList] = useState([])
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()
    const [count, setCount] = useState(0)
    const [filter, setFilter] = useState({
        page: Number(searchParams.get('page') || 1),
        limit: Number(searchParams.get('limit') || 10)
    })

    const onFilter = ({
        name,
        value
    }: EventTarget & HTMLInputElement) => {
        setFilter({ ...filter, [name]: value })

        if (value) searchParams.set(name, value)
        else searchParams.delete(name)
        setSearchParams(searchParams)
    }

    const { page, limit } = filter

    useEffect(() => {
        if (tabIndex == 0) filterList()

    }, [tabIndex])

    const filterList = async () => {
        setIsLoading(true)
        await fetcher(
            AdminUserPreferencesEndpoints[selectedPreference]
                .all(filter)
        ).then((response) => {
            setList(response[selectedPreference])
            setCount(response.meta.count)
        })
        setIsLoading(false)
    }

    useEffect(() => {
        filterList()
    }, [filter, selectedPreference])

    const handleSearch = async (e: any) => {
        let currentFilter = filter
        currentFilter.name = e.target.value
        setFilter({ ...currentFilter })
    }

    const deleteItem = async c => {
        setIsLoading(true)
        await fetcher(
            AdminUserPreferencesEndpoints[selectedPreference].delete(c.id)
        ).then(() => {
          toast({
            title: 'Eliminado',
            colorScheme: 'olaggPink',
            status: 'success',
            description: '',
            position: 'bottom-right'
          })
          window.location.reload();
        })
        .catch(e => {
          toast({
            title: 'Error',
            colorScheme: 'olaggYellow',
            status: 'error',
            description: e.message,
            position: 'bottom-right'
          })
        })
    
        setIsLoading(false)
      }

    return (
        <>
            <Flex w={'full'} pb={4} justifyContent='center' flexDirection={'column'}>

                <Flex flexDirection={'row'}>
                    <Flex px={'20px'} py={'15px'} gap='10px'>
                        <InputGroup>
                            <Input
                                fontWeight={600}
                                placeholder="Buscar por nombre"
                                name="query"
                                autoComplete="off"
                                onChange={handleSearch}
                                color='white'
                            />
                            <InputLeftElement
                                children={<BsSearch color="white" />}
                            />
                        </InputGroup>
                    </Flex>
                </Flex>

                <Flex w='full' justifyContent='center' px={'20px'} pb={'15px'}>
                    <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="100%" maxH="60vh">
                        <ChakraTable
                            variant="striped"
                            size="sm"
                            colorScheme="olaggPurple"
                            fontSize="18px"
                            position='relative'
                            whiteSpace={'normal'}
                        >
                            <Thead>
                                <Tr position='sticky' bg='gray.800' top='0' zIndex='100'>
                                    <Th py={'20px'} color="white">Nombre</Th>
                                    <Th py={'20px'} color="white">Descripción</Th>
                                    <Th py={'20px'} color="white">Fecha Creación</Th>
                                    <Th py={'20px'} color="white">Fecha Actualización</Th>
                                    <Th py={'20px'} color="white">Acciones</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {list?.map((item, index) => (
                                    <Tr key={index} color="white">
                                        <Td py={'20px'}>{item?.name}</Td>
                                        <Td py={'20px'}>{item?.description}</Td>
                                        <Td py={'20px'}>{item?.createdAt && moment(item?.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Td>
                                        <Td py={'20px'}>{item?.updatedAt && moment(item?.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</Td>
                                        <Td py={'20px'}>
                                            <Button mx='5px' isDisabled={missingPermission(OlaggModule.Opportunity, PermissionEnum.EDIT)} bgColor={'#37A169'} p='0 10px' h='30px' fontSize={'14px'}
                                                onClick={() => navigate(generatePath(ROUTES.USER_PREFERENCES.update, { selectedPreference: selectedPreference, id: item?.id }))}
                                            >
                                                Editar
                                            </Button>
                                            <Popover>
                                                <PopoverTrigger>
                                                    <Button mx='5px' p='0 10px' h='30px' fontSize={'14px'} isDisabled={missingPermission(OlaggModule.Opportunity, PermissionEnum.DELETE)} colorScheme="red">Eliminar</Button>
                                                </PopoverTrigger>
                                                <Portal>
                                                    <PopoverContent>
                                                        <PopoverArrow />
                                                        <PopoverCloseButton />
                                                        <PopoverBody>
                                                            <Button width={'100%'} colorScheme='blue' onClick={() => {
                                                                deleteItem(item)
                                                            }}>
                                                                Confirmar Eliminar
                                                            </Button>
                                                        </PopoverBody>
                                                    </PopoverContent>
                                                </Portal>
                                            </Popover>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </ChakraTable>
                    </TableContainer>
                </Flex>

                <Flex px={'20px'} w='full' justifyContent={'center'}>

                    <Pagination
                        params={{ limit, page, count }}
                        onClick={page =>
                            onFilter({
                                name: 'page',
                                value: String(page + 1)
                            } as EventTarget & HTMLInputElement)
                        }
                        onLimitChange={val =>
                            onFilter({
                                name: 'limit',
                                value: val
                            } as EventTarget & HTMLInputElement)
                        }
                        hideLimitInput
                    />

                </Flex>
            </Flex>
        </>
    )
};

export default List;

import {
    Flex,
    useToast,
    Badge,
    TableContainer,
    Table as ChakraTable,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Button,
    useDisclosure,
    Select,
    Text,
    InputGroup,
    Input,
    InputLeftElement
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import moment from 'moment/moment.js'
import 'moment/min/locales'

import {
    fetcher,
    AdminSurveysEndpoints,
} from '@olagg/api-hooks'
import ModalDetail from "./modalDetail";
import Cookies from "js-cookie";
import { useSearchParams, useNavigate, generatePath } from 'react-router-dom'
import Pagination from "@olagg/ui-kit/Pagination";
import useRoles from "../../../features/Roles/hooks/useRoles";
import { OlaggModule, PermissionEnum } from "@olagg/db-types";
import { ROUTES } from '../../../consts/routes'
import { BsSearch } from "react-icons/bs";

const List = ({ tabIndex }: { tabIndex: number }) => {
    const { missingPermission } = useRoles();
    const toast = useToast()
    const { onOpen: onOpenDetailModal, onClose: onCloseDetailModal, isOpen: isOpenDetailModal } = useDisclosure();
    const [isLoading, setIsLoading] = useState(true)
    const [surveys, setSurveys] = useState([])
    const [selectedSurvey, setSelectedSurvey] = useState({})
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()
    const [count, setCount] = useState(0)
    const [filter, setFilter] = useState({
        page: Number(searchParams.get('page') || 1),
        limit: Number(searchParams.get('limit') || 10)
    })

    const onFilter = ({
        name,
        value
    }: EventTarget & HTMLInputElement) => {
        setFilter({ ...filter, [name]: value })

        if (value) searchParams.set(name, value)
        else searchParams.delete(name)
        setSearchParams(searchParams)
    }

    const { page, limit } = filter

    const initRequest = useCallback(async () => {
        setIsLoading(true)
        await fetcher(
            AdminSurveysEndpoints
                .all(filter)
        ).then((response) => {
            const { surveys, count } = response
            setSurveys(surveys)
            setCount(count)
        })
        setIsLoading(false)
    }, [])

    useEffect(() => {
        initRequest();
    }, [])

    useEffect(() => {
        if (tabIndex == 0) initRequest()

    }, [tabIndex])

    const filterSurveys = async () => {
        setIsLoading(true)
        await fetcher(
            AdminSurveysEndpoints
                .all(filter)
        ).then((response) => {
            const { surveys, count } = response
            setSurveys(surveys)
            setCount(count)
        })
        setIsLoading(false)
    }

    useEffect(() => {
        filterSurveys()
    }, [filter])

    const Status = (props) => {
        let start = props.start_date

        let end = props.end_date
        let targetDate = moment();
        let isWithinRange = targetDate.isBetween(start, end, undefined, '[]');
        switch (props.visible) {
            case true:
                return (
                    <Flex gap='8px'>
                        <Badge borderRadius={'20px'} textAlign='center' fontSize={'14px'} py={'2px'} width={'75px'} color='black' bgColor={'#37A169'} fontWeight='500' textTransform={'none'}>Visible</Badge>
                        {isWithinRange && <Badge borderRadius={'20px'} textAlign='center' fontSize={'14px'} py={'2px'} width={'85px'} color='black' bgColor={'#38ABFF'} fontWeight='500' textTransform={'none'}>En progreso</Badge>}
                    </Flex>
                )
                break;
            default:
                return <></>
                break
        }
    }

    const Actions = (props) => {

        let start = props.start_date
        let end = props.end_date
        let targetDate = moment();
        let isWithinRange = targetDate.isBetween(start, end, undefined, '[]');
        const isBefore = targetDate.isBefore(start)
        const isAfter = targetDate.isAfter(end)

        return (
            <Flex gap='10px'>
                <Button isDisabled={missingPermission(OlaggModule.Survey, PermissionEnum.VIEW)} bgColor={'#3182CE'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => openDetail(props)}>Ver</Button>
                <Button isDisabled={missingPermission(OlaggModule.Survey, PermissionEnum.EDIT)} bgColor={'#37A169'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => navigate(generatePath(ROUTES.SURVEYS.update, { id: props?.uuid }))}>Editar</Button>
                {(isWithinRange || isAfter) && (
                    <Button isDisabled={missingPermission(OlaggModule.Survey, PermissionEnum.VIEW)} bgColor={'black'} p='0 10px' h='30px' fontSize={'14px'} onClick={() => exportCSV(props)}>Exportar</Button>
                )}
            </Flex>
        )
    }

    const openDetail = async (data) => {
        setSelectedSurvey(data)
        onOpenDetailModal()
    }

    const exportCSV = async (data) => {
        try {
            const apiUrl = `${import.meta.env.VITE_API_URL}/admin/surveys/${data.uuid}/export`
            const token = Cookies.get('auth._token.local') || null
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'text/csv'
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${new Date().toLocaleDateString('es')}_${data.uuid}`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.log(error)
        }
    }

    const handleSearch = async (e: any) => {
        onFilter({
            name: 'title',
            value: e.target.value
        } as EventTarget & HTMLInputElement)
    }

    return (
        <>
            <Flex w={'full'} pb={4} justifyContent='center' flexDirection={'column'}>

                <Flex flexDirection={'row'}>
                    <Flex px={'20px'} py={'15px'} gap='10px'>
                        <InputGroup>
                            <Input
                                fontWeight={600}
                                placeholder="Buscar por título"
                                name="query"
                                autoComplete="off"
                                onChange={handleSearch}
                                color='white'
                            />
                            <InputLeftElement
                                children={<BsSearch color="white" />}
                            />
                        </InputGroup>
                    </Flex>
                    <Flex px={'20px'} py={'15px'} ml='auto' gap='10px'>
                        <Button onClick={() => {
                            if (filter?.visible) {
                                let currentFilter = filter
                                delete currentFilter['visible']
                                setFilter({ ...currentFilter })
                            } else {
                                onFilter({
                                    name: 'visible',
                                    value: 'true'
                                } as EventTarget & HTMLInputElement)
                            }
                        }} variant={filter?.visible && filter?.visible == 'true' ? 'solid' : 'outline'} h='30px' p='1px 10px 0 10px' mr='30px'>Visibles</Button>

                        <Button onClick={() => {
                            let currentFilter = filter
                            delete currentFilter['date']
                            setFilter({ ...currentFilter })
                        }} variant={!filter.date || filter?.date == null ? 'solid' : 'outline'} h='30px' p='1px 10px 0 10px'>Todos</Button>
                        <Button onClick={() => {
                            onFilter({
                                name: 'date',
                                value: 'next'
                            } as EventTarget & HTMLInputElement)
                        }} variant={filter?.date && filter?.date == 'nexr' ? 'solid' : 'outline'} h='30px' p='1px 10px 0 10px'>Próximos</Button>
                        <Button onClick={() => {
                            onFilter({
                                name: 'date',
                                value: 'in_progress'
                            } as EventTarget & HTMLInputElement)
                        }} variant={filter?.date && filter?.date == 'in_progress' ? 'solid' : 'outline'} h='30px' p='1px 10px 0 10px'>En progreso</Button>
                        <Button onClick={() => {
                            onFilter({
                                name: 'date',
                                value: 'ended'
                            } as EventTarget & HTMLInputElement)
                        }} variant={filter?.date && filter?.date == 'ended' ? 'solid' : 'outline'} h='30px' p='1px 10px 0 10px'>Finalizadas</Button>
                    </Flex>
                </Flex>

                <Flex w='full' justifyContent='center' px={'20px'} pb={'15px'}>
                    <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="100%" maxH="60vh">
                        <ChakraTable
                            variant="striped"
                            size="sm"
                            colorScheme="olaggPurple"
                            fontSize="18px"
                            position='relative'
                            whiteSpace={'normal'}
                        >
                            <Thead>
                                <Tr position='sticky' bg='gray.800' top='0' zIndex='100'>
                                    <Th py={'20px'} color="white">Título</Th>
                                    <Th py={'20px'} color="white">Slug</Th>
                                    <Th py={'20px'} color="white">Fecha Inicio</Th>
                                    <Th py={'20px'} color="white">Fecha Fin</Th>
                                    <Th py={'20px'} color="white">Autor</Th>
                                    <Th py={'20px'} color="white">XP</Th>
                                    <Th py={'20px'} color="white">Estado</Th>
                                    <Th py={'20px'} color="white">Acciones</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {surveys?.map((survey, index) => (
                                    <Tr key={index} color="white">
                                        <Td py={'20px'}>{survey?.title}</Td>
                                        <Td py={'20px'}>{survey?.slug}</Td>
                                        <Td py={'20px'}>{survey?.start_date && moment(survey?.start_date).format('DD/MM/YYYY HH:mm:ss')}</Td>
                                        <Td py={'20px'}>{survey?.end_date && moment(survey?.end_date).format('DD/MM/YYYY HH:mm:ss')}</Td>
                                        <Td py={'20px'}>{survey?.author?.name}</Td>
                                        <Td py={'20px'}>{survey?.score}</Td>
                                        <Td py={'20px'}><Status {...survey} /></Td>
                                        <Td py={'20px'}>
                                            <Actions {...survey} />
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </ChakraTable>
                    </TableContainer>
                </Flex>

                <Flex px={'20px'} w='full' justifyContent={'center'}>

                    <Pagination
                        params={{ limit, page, count }}
                        onClick={page =>
                            onFilter({
                                name: 'page',
                                value: String(page + 1)
                            } as EventTarget & HTMLInputElement)
                        }
                        onLimitChange={val =>
                            onFilter({
                                name: 'limit',
                                value: val
                            } as EventTarget & HTMLInputElement)
                        }
                        hideLimitInput
                    />

                </Flex>
            </Flex>
            {/* <ModalCancel
                isOpen={isOpenCancelModal}
                onClose={onCloseCancelModal}
                selectedSurvey={selectedSurvey}
                refresh={initRequest}
            />
            <ModalConfirm
                isOpen={isOpenConfirmModal}
                onClose={onCloseConfirmModal}
                selectedSurvey={selectedSurvey}
                refresh={initRequest}
            /> */}

            <ModalDetail
                isOpen={isOpenDetailModal}
                onClose={onCloseDetailModal}
                selectedSurvey={selectedSurvey}
            />
        </>
    )
};

export default List;

import { OlaggModule, PermissionEnum, Story, StoryCategory } from "@olagg/db-types";
import { useForm } from "react-hook-form";
import OlaggForm from "../../../../components/Forms/OlaggForm";
import { StoryCategoryData, storyCategorySchema } from "@olagg/validation-schemas"
import { yupResolver } from '@hookform/resolvers/yup'
import OlaGGFormInput from "../../../../components/Forms/OlaggFormInput";
import { Button, Flex } from "@chakra-ui/react";
import OlaggFormSelect from "../../../../components/Forms/OlaggFormSelect";
import useRoles from "../../../../features/Roles/hooks/useRoles";

type IProps = {
    storyCategory?: StoryCategory | null;
    onSubmit: (data: FormData) => void;
};

const StoryCategoryForm = ({ storyCategory, onSubmit }: IProps) => {
    const { missingPermission } = useRoles();
    const useFormMethods = useForm<StoryCategoryData>({
        resolver: yupResolver(storyCategorySchema),
        mode: 'onChange',
        defaultValues: { ...storyCategory }
    })

    return (
        <Flex w={'60vw'} mx={4} pb={4} minH='600px' justifyContent='center' direction={"column"}>
            <OlaggForm
                onSubmit={onSubmit}
                useFormMethods={useFormMethods}
            >
                <OlaGGFormInput
                    label='Nombre'
                    controlName='name'
                    placeholder='Nombre de la categoría'
                />
                <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
                    <OlaGGFormInput
                        label='Nombre en inglés'
                        controlName='extraData.en.name'
                        placeholder='Nombre de la categoría'
                    />
                    <OlaGGFormInput
                        label='Nombre en portugués'
                        controlName='extraData.pt.name'
                        placeholder='Nombre de la categoría'
                    />
                </Flex>
                <OlaGGFormInput
                    label='Icono'
                    controlName='icon'
                    placeholder='Aparecerá a la izquierda del nombre'
                />
                <OlaGGFormInput
                    label='Thumbnail'
                    controlName='thumbnail'
                    placeholder='URL de la imagen'
                />
                <OlaggFormSelect
                    label='Fija?'
                    controlName='fixed'
                    staticOptions={[
                        { label: 'Sí', value: true },
                        { label: 'No', value: false },
                    ]}
                    newVersion
                />
                <Button
                    variant={'filledGradient'}
                    type="submit"
                    w={'full'}
                    style={{
                        marginTop: '30px'
                    }}
                    isDisabled={missingPermission(OlaggModule.Story, storyCategory ? PermissionEnum.CREATE : PermissionEnum.EDIT)}
                >
                    Guardar
                </Button>
            </OlaggForm>
        </Flex>
    )

}

export default StoryCategoryForm;

import { Flex, Spinner } from "@chakra-ui/react";
import { useWalletBalance } from "thirdweb/react";

export function ReadBalanceNative({ walletAddress, chain, client }: { walletAddress: string, chain: any, client: any }) {
    const { data: balance, isLoading, isError } = useWalletBalance({
        chain,
        address: walletAddress,
        client,
    });

    return (
        <Flex w='full' fontSize={'18px'}>
            <Flex>Balance:</Flex>
            <Flex ml='auto' fontWeight={'700'}>
                {(isLoading) ? <Spinner w='22px' h='22px' color='white' /> : <>{balance?.displayValue} {balance?.symbol}</>}
            </Flex>
        </Flex>
    );
}
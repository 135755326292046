import * as Yup from 'yup';

import type { InferType } from 'yup';

export const BlockchainTokenSchema = Yup.object({
  name: Yup.string().required('El nombre es obligatorio'),
  blockchainTokenNetworkId: Yup.number().required('La network es obligatoria'),
  contractAddress: Yup.string().nullable(),
  airdropContractAddress: Yup.string().nullable(),
  tokenType: Yup.string().required('El token type es obligatorio'),
  tokenIconUrl: Yup.string().required('El icono del token type es obligatorio'),
  tokenId: Yup.string().nullable()
})

export type BlockchainTokenData = InferType<typeof BlockchainTokenSchema>;

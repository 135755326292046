import { Flex, VStack, Heading } from "@chakra-ui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import GameCategoriesFormEdit from "./components/gameCategories/formEdit";
import OpportunitiesFormEdit from "./components/opportunities/formEdit";
import PlatformsFormEdit from "./components/platforms/formEdit";

const UserPreferencesEdit = () => {

    const params = useParams()
    const id = params.id!
    const selectedPreference = params.selectedPreference!

    const Form = () => {
        switch (selectedPreference) {
            case 'gameCategories':
                return <GameCategoriesFormEdit selectedPreference={selectedPreference} />
                break;
            case 'opportunities':
                return <OpportunitiesFormEdit selectedPreference={selectedPreference} />
                break;
            case 'platforms':
                return <PlatformsFormEdit selectedPreference={selectedPreference} />
                break;
            default:
                return null
                break;
        }
    };

    return (
        <Flex w={'full'} py={'15px'} pb={4} minH='600px' justifyContent='center'>
            <VStack color={'white'} w={'full'} maxW={'60%'}>
                <Flex flexDirection="column" w={'full'}>
                    <Heading fontSize={'30px'}>Editar</Heading>
                </Flex>
                <Form />
            </VStack >
        </Flex >
    )
};

export default UserPreferencesEdit;
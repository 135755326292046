import { Flex, VStack, Heading, useToast, Button } from "@chakra-ui/react";
import { OlaggModule, PermissionEnum } from "@olagg/db-types";
import { useEffect, useState } from "react";
import useRoles from "../../../../features/Roles/hooks/useRoles";
import { useForm, FormProvider } from 'react-hook-form'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
    fetcher,
    AdminUserPreferencesEndpoints
} from '@olagg/api-hooks'
import OlaggFormInput from "../../../../components/Forms/OlaggFormInput";
import OlaggFormSelect from "../../../../components/Forms/OlaggFormSelect";
import { UserPreferencesStrings } from '../../types'

type FormData = {
    name: string,
    longName: string,
    categoryType: string,
    image: string,
    extra_data: extraDataAttributes,
}

type extraDataAttributes = {
    en: {
        name: string,
        longName: string
    },
    pt: {
        name: string,
        longName: string
    }
}

const schema: Yup.Schema<FormData> = Yup
    .object({
        name: Yup.string().required('El Nombre es obligatorio'),
        longName: Yup.string().required('La descripción es obligatorio'),
        categoryType: Yup.string().required('Tipo de categoría es obligatorio'),
        image: Yup.string().required('URL imagen de la categoría es obligatoria'),
        extra_data: Yup.object({
            en: Yup.object({
                name: Yup.string().optional().nullable(),
                longName: Yup.string().optional().nullable(),
            }),
            pt: Yup.object({
                name: Yup.string().optional().nullable(),
                longName: Yup.string().optional().nullable(),
            })
        })
    })
    .required()

const UserPreferencesForm = ({
    tabIndex,
    selectedPreference
}: {
    tabIndex: number,
    selectedPreference: string
}) => {

    const { missingPermission } = useRoles();
    const toast = useToast()
    const [isLoading, setIsLoading] = useState(false)

    const useFormMethods = useForm<FormData>({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const errors = useFormMethods.formState.errors;

    useEffect(() => {
        console.log(errors);
        
    }, [errors])

    const onSubmit = (dataForm) => {
        let payload = { gameCategory: dataForm }
        setIsLoading(true)
        fetcher(AdminUserPreferencesEndpoints[selectedPreference].new(payload))
            .then(() => {
                toast({
                    title: 'Preferencia de Usuario creada',
                    colorScheme: 'olaggPink',
                    status: 'success',
                    description: '',
                    position: 'bottom-right'
                })
                //LIMPAR FORM
                useFormMethods.reset()
                setIsLoading(false)
            })
            .catch(e => {
                toast({
                    title: 'Error',
                    colorScheme: 'olaggYellow',
                    status: 'error',
                    description: e?.error?.message,
                    position: 'bottom-right'
                })
                setIsLoading(false)
            })
    }

    return (
        <Flex w={'full'} py={'15px'} pb={4} minH='600px' justifyContent='center'>
            <VStack color={'white'} w={'full'} maxW={'60%'}>
                <Flex flexDirection="column" w={'full'}>
                    <Heading fontSize={'30px'}>Cargar {UserPreferencesStrings[selectedPreference]}</Heading>
                </Flex>

                <Flex w={'full'} flexDirection='column'>

                    <Flex position={'relative'} zIndex='0' mt={'20px'}>
                        <FormProvider {...useFormMethods}>
                            <form onSubmit={useFormMethods.handleSubmit(onSubmit)} style={{ width: '100%' }}>

                                <OlaggFormInput
                                    required={true}
                                    label='Nombre en Español'
                                    controlName='name'
                                    placeholder='Nombre de la categoría en español'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <Flex direction='column' w='full' bgColor='#20173d' p='8' mb={'40px'} borderRadius={'12px'}>

                                    <OlaggFormInput
                                        required={true}
                                        label='Nombre en Inglés'
                                        controlName='extra_data.en.name'
                                        placeholder='Nombre de la categoría en inglés'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                    <OlaggFormInput
                                        required={true}
                                        label='Nombre en Portugués'
                                        controlName='extra_data.pt.name'
                                        placeholder='Nombre de la categoría en portugués'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                </Flex>

                                <OlaggFormInput
                                    required={true}
                                    label='Descripción en Español'
                                    controlName='longName'
                                    placeholder='Descripción de la categoría en español'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <Flex direction='column' w='full' bgColor='#20173d' p='8' mb={'40px'} borderRadius={'12px'}>

                                    <OlaggFormInput
                                        required={true}
                                        label='Descripción en Inglés'
                                        controlName='extra_data.en.longName'
                                        placeholder='Descripción de la categoría en inglés'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                    <OlaggFormInput
                                        required={true}
                                        label='Descripción en Portugués'
                                        controlName='extra_data.pt.longName'
                                        placeholder='Descripción de la categoría en portugués'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                </Flex>

                                <OlaggFormInput
                                    required={true}
                                    label='URL Image'
                                    controlName='image'
                                    placeholder='URL de la imagen'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <OlaggFormSelect
                                    label='Tipo de categoría'
                                    controlName='categoryType'
                                    required={true}
                                    isSearchable={false}
                                    staticOptions={[
                                        {
                                            value: 'genre',
                                            label: 'Género'
                                        },
                                        {
                                            value: 'ola',
                                            label: 'Ola'
                                        }
                                    ]}
                                    newVersion
                                />

                                <Button
                                    variant={'filledGradient'}
                                    isLoading={isLoading}
                                    type="submit"
                                    w={'full'}
                                    style={{
                                        marginTop: '30px'
                                    }}
                                    isDisabled={isLoading || missingPermission(OlaggModule.GameCategory, PermissionEnum.CREATE)}
                                >
                                    Cargar Nuevo
                                </Button>
                            </form>
                        </FormProvider>
                    </Flex>
                </Flex>
            </VStack >
        </Flex >
    )
};

export default UserPreferencesForm;

import { BlockchainToken, OlaggModule, PermissionEnum } from "@olagg/db-types";
import { BlockchainTokenData, BlockchainTokenSchema } from "@olagg/validation-schemas";
import { Button, Flex } from "@chakra-ui/react";

import OlaGGFormInput from "../../../../components/Forms/OlaggFormInput";
import OlaggForm from "../../../../components/Forms/OlaggForm";
import OlaggFormSelect from "../../../../components/Forms/OlaggFormSelect";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import useRoles from "../../../../features/Roles/hooks/useRoles";
import { yupResolver } from '@hookform/resolvers/yup'
import useBlockchainNetwork from "../../hooks/useBlockchainNetwork";

type IProps = {
  blockchainToken?: BlockchainToken | null;
  onSubmit: (data: FormData) => void;
};

const Form = ({ blockchainToken, onSubmit }: IProps) => {
    const { missingPermission } = useRoles();
    const { all, blockchainNetworks } = useBlockchainNetwork();
    const tokenTypes = [
      {value: 'ERC20', label: 'ERC20'}, 
      {value: 'ERC1155', label: 'ERC1155'}, 
      {value: 'ERC721', label: 'ERC721'},
      {value: 'NATIVE', label: 'NATIVE'}
    ]

    const useFormMethods = useForm<BlockchainTokenData>({
      resolver: yupResolver(BlockchainTokenSchema),
      mode: 'onChange',
      defaultValues: {
        ...blockchainToken,
        blockchainTokenNetworkId: blockchainToken?.network.id,
      }
    })

    const { watch, setValue } = useFormMethods;
    const selectedTokenType = watch('tokenType');

    const blockchainNetworksOptions = blockchainNetworks.map(blockchainNetwork => ({
      value: blockchainNetwork.id,
      label: blockchainNetwork.name
    })) || [];

    useEffect(() => {
      all({
          limit: 10,
          page: 1
      });
    }, []);

    useEffect(() => {
      if (selectedTokenType === 'ERC20') {
        setValue('tokenId', null);
      }
    }, [selectedTokenType, setValue]);

    return (
      <Flex w={'60vw'} mx={4} pb={4} minH='600px' justifyContent='center' direction={"column"}>
        <OlaggForm
          onSubmit={onSubmit}
          useFormMethods={useFormMethods}
          style={{
              width: '100%'
          }}
        >
          <OlaGGFormInput
            label='Nombre'
            controlName='name'
            placeholder='Nombre del blockchain token'
            required={true}
          />
          <OlaggFormSelect
            label="Network"
            controlName={'blockchainTokenNetworkId'}
            staticOptions={blockchainNetworksOptions}
            required={true}
            newVersion
            isSearchable
          />
          <OlaGGFormInput
            label='Contract Address'
            controlName='contractAddress'
            placeholder='0x6B175474E8909...'
            required={selectedTokenType !== 'NATIVE'} // Contract address is not required for native tokens
          />
          <OlaGGFormInput
            label='Airdrop Contract Address'
            controlName='airdropContractAddress'
            placeholder='0x6B175474E8909...'
          />
          <OlaggFormSelect
            label="Tipo de Token"
            controlName={'tokenType'}
            staticOptions={tokenTypes}
            required={true}
            newVersion
          />
          {
            selectedTokenType === 'ERC1155' || selectedTokenType === 'ERC721' ? (
              <OlaGGFormInput
              label='Token ID'
              controlName='tokenId'
              placeholder={selectedTokenType === 'ERC1155' ? 'Token ID mandatorio para ERC1155' : 'Token ID opcional para ERC721'}
              required={selectedTokenType === 'ERC1155' ? true : false}
            />
            ) : null
          }
          <OlaGGFormInput
            label='Url del icono del token'
            controlName='tokenIconUrl'
            placeholder=''
            required={true}
          />
          <Button
            variant={'filledGradient'}
            type="submit"
            w={'full'}
            style={{
                marginTop: '30px'
            }}
            isDisabled={missingPermission(OlaggModule.BlockchainToken, blockchainToken ? PermissionEnum.CREATE : PermissionEnum.EDIT)}
          >
              Guardar
          </Button>
        </OlaggForm>
      </Flex>
    )

}

export default Form;

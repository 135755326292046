import { Button, useToast, Text } from "@chakra-ui/react";
import {
    fetcher,
    AdminPaymentsEndpoints
} from '@olagg/api-hooks'

import { ethers } from 'ethers'
import { useEffect, useState } from "react";
import { getContract, readContract } from "thirdweb";
import { useSendTransaction, useWalletBalance } from "thirdweb/react";
import AIRDROP_ABI from "./airdrop_abi.json";

export function ExecuteAirdropNative({
    payments,
    airdropContractAddress,
    setIsOwnerConnected,
    rpc,
    walletAddress,
    chain,
    client
}: {
    payments: any,
    airdropContractAddress: string,
    setIsOwnerConnected: (value: boolean) => void,
    rpc: string,
    walletAddress: string,
    chain: any,
    client: any
}) {

    const toast = useToast();
    const { mutateAsync: sendTransaction, isPending } = useSendTransaction();

    const { data: balance } = useWalletBalance({
        chain,
        address: walletAddress,
        client,
    });

    const [isExecuting, setIsExecuting] = useState(false);
    const [isOwner, setIsOwner] = useState(false);

    useEffect(() => {
        const fetchContractData = async () => {
            try {
                // Obtener el contrato Airdrop
                const airdropContract = getContract({
                    client,
                    address: airdropContractAddress,
                    chain: chain,
                    abi: AIRDROP_ABI,
                });

                const ownerAddress = await readContract({
                    contract: airdropContract,
                    method: "owner",
                    params: [],
                });

                if (ownerAddress.toLowerCase() === walletAddress.toLowerCase()) {
                    setIsOwner(true);
                } else {
                    setIsOwner(false);
                }

                // Obtener el precio del gas
                const provider = new ethers.providers.JsonRpcProvider(rpc); // RPC de la red
                const gasPriceData = await provider.getGasPrice();
                console.log("Gas Price:", ethers.utils.formatUnits(gasPriceData, "wei"), "wei");

            } catch (error) {
                console.error("Error obteniendo datos del contrato:", error);
            }
        };

        fetchContractData();
    }, [walletAddress]);

    const formatAirdropData = (data: { nonce: string; quantity: number; address: string }[]) => {
        const paymentsMap: Record<string, bigint> = {};

        // Agrupar por dirección y sumar las cantidades
        data.forEach(({ address, quantity }) => {
            const amountInWei = ethers.utils.parseUnits(quantity.toString(), balance?.decimals); // Conversión segura a Wei
            paymentsMap[address] = (paymentsMap[address] || ethers.BigNumber.from(0)).add(amountInWei);
        });

        // Convertir el objeto agrupado en un array de pagos
        return Object.entries(paymentsMap).map(([recipient, amount]) => ({
            recipient,
            amount: amount.toString(), // Se envía como string porque Solidity usa BigInt en string
        }));
    };

    const handleAirdrop = async () => {
        setIsExecuting(true);

        try {
            const formattedData = formatAirdropData(payments);

            // Codificar la transacción `airdropNativeToken()`
            const iface = new ethers.utils.Interface(AIRDROP_ABI);
            const encodedData = iface.encodeFunctionData("airdropNativeToken", [formattedData]);

            // Calcular `gasLimit`
            let gasLimit = ethers.BigNumber.from("500000"); // Default para 1-5 wallets
            if (formattedData.length > 5) gasLimit = ethers.BigNumber.from("1000000"); // 10 wallets
            if (formattedData.length > 20) gasLimit = ethers.BigNumber.from("3000000"); // 50 wallets
            if (formattedData.length > 50) gasLimit = ethers.BigNumber.from("5000000"); // 100 wallets

            // Sumar el total a enviar
            const totalAmount = formattedData.reduce(
                (acc, r) => acc.add(ethers.BigNumber.from(r.amount)),
                ethers.BigNumber.from(0)
            );

            // Convertir totalAmount a bigint
            const totalAmountBigInt = BigInt(totalAmount.toString());

            // Enviar la transacción
            const tx = await sendTransaction({
                to: airdropContractAddress,
                data: encodedData,
                chain: chain,
                value: totalAmountBigInt,
                gasLimit,
                client
            });

            console.log("Airdrop ejecutado:", tx);

            const { transactionHash } = tx

            const transformedPayload = {
                data: payments.map(({ nonce }) => ({ tx_id: transactionHash, nonce }))
            };

            toast({
                title: 'Transacción enviada',
                colorScheme: 'olaggPink',
                status: 'success',
                description: 'Airdrop ejecutado con éxito',
                position: 'bottom-right'
            })

            fetcher(AdminPaymentsEndpoints.bulk_update_after_airdrop(transformedPayload))
                .then(() => {
                    toast({
                        title: `Pagos actualizados`,
                        colorScheme: 'olaggPink',
                        status: 'success',
                        description: `TX_ID: ${transactionHash}`,
                        position: 'bottom-right'
                    })

                    window.location.reload()
                })
                .catch(e => {
                    console.error("Error ejecutando el airdrop:", e);
                    toast({
                        title: 'Error al guardar tx_id de pago',
                        colorScheme: 'olaggPink',
                        status: 'error',
                        description: `TX_ID: ${transactionHash}. Verifica que efectivamente se hizo la transferencia antes de re intentar.`,
                        position: 'bottom-right'
                    })
                })

        } catch (error) {
            console.error("Error ejecutando el airdrop:", error);
            toast({
                title: "Error ejecutando el airdrop",
                colorScheme: "red",
                status: "error",
                position: "bottom-right",
            });
        } finally {
            setIsExecuting(false);
        }
    };

    if (!isOwner) {
        setIsOwnerConnected(false)
        return <Text>Para ejecutar el Airdrop debes conectar la billetera del owner del contrato</Text>
    } else {
        setIsOwnerConnected(true)
    }

    return (
        <Button w='full' variant={'outline'} isLoading={isExecuting} onClick={handleAirdrop} disabled={isExecuting}>
            Ejecutar Airdrop
        </Button>
    );
}

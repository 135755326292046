import {
  Alert,
  BatchCompleteQuest,
  BatchPaymentsUpload,
  CreateTournamentParams,
  Device,
  Game,
  GameCredentials,
  InscriptionData,
  Notification,
  Quest,
  QuestOwner,
  RelevantAction,
  Role,
  RolePermission,
  RouletteValue,
  TreasuresGame,
  UpdateTournamentData,
  User,
  UserContent
} from '@olagg/db-types'
import { CreateTokenData, UpdateTokenData } from '@olagg/web3/dist/domain/types/token.type'
import { Endpoint, Method } from "./constants";

import { IUseAlerts } from "../interfaces/IUseAlerts";
import { IUseAssignments } from "../interfaces/IUseAssignments";
import { IUseCredentials } from "../interfaces/IUseCredentials";
import { IUseInscriptions } from "../interfaces/IUseInscriptions";
import { IUseItems } from "../interfaces/IUseItems";
import { IUseQuests } from '../interfaces/IUseQuests'
import { IUseQuestsOwnerId } from '../interfaces/IUseQuestsOwnerId'
import { IUseRelevantActions } from "../interfaces/IUseRelevantActions";
import { IUseTokens } from "../interfaces/IUseTokens";
import { IUseTournaments } from "../interfaces/IUseTournaments";
import { IUseTreasuresGame } from "../interfaces/IUseTreasuresGame";
import { IUseUserContent } from "../interfaces/IUserUserContent";
import { IUseUsers } from '../interfaces/IUseUsers'

const parameterize = (params: object) => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (value) {
      return `${acc}&${key}=${value}`
    }
    return acc
  }, '')
}

export const AdminQuestEndpoints = {
  find: (id: string) => ({
    method: Method.GET,
    path: `/admin/quests/${id}`
  }),
  all: (filter: IUseQuests) => ({
    method: Method.GET,
    path: `/admin/quests?${parameterize(filter)}`
  }),
  ByOwnerId: ({ id }: IUseQuestsOwnerId) => ({
    method: Method.GET,
    path: `/quests?owner_id=${id}`
  }),
  create: (data: Partial<Quest>) =>
  ({
    method: Method.POST,
    path: '/admin/quests',
    body: { quest: data }
  } as Endpoint<Partial<Quest>>),
  update: (id: string, data: Partial<Quest>) =>
  ({
    method: Method.PUT,
    path: `/admin/quests/${id}`,
    body: data
  } as Endpoint<Partial<Quest>>),
  delete: (id: string) => ({
    method: Method.DELETE as Method.DELETE,
    path: `/admin/quests/${id}`
  }),
  batchComplete: (id: string, data: BatchCompleteQuest) =>
  ({
    method: Method.PUT,
    path: `/admin/quests/bulk_complete`,
    body: data,
    isForm: true
  } as Endpoint<Partial<Quest>>),
}

export const AdminGameEndpoints = {
  all: ({ page, limit, title }: { page: number, limit: number, title?: string }) => ({
    method: Method.GET,
    path: `/admin/games?page=${page}&limit=${limit}&title=${title ?? ''}`
  }),
  find: (slug: string) => ({
    method: Method.GET,
    path: `/admin/games/${slug}`
  }),
  create: (data: Partial<Game>) => ({
    method: Method.POST,
    path: '/admin/games',
    body: { game: data }
  } as Endpoint<Partial<Quest>>),
  update: (slug: string, data: Partial<Game>) => ({
    method: Method.PUT,
    path: `/admin/games/${slug}`,
    body: { game: data }
  } as Endpoint<Partial<Quest>>),
}

export const AdminOwnersEndpoints = {
  all: ({ name, page, limit }: { name?: string, page: number, limit: number }) => ({
    method: Method.GET,
    path: `/admin/owners?name=${name ?? ''}&page=${page}&limit=${limit}`
  }),
  find: (address: string) => ({
    method: Method.GET,
    path: `/admin/owners/${address}`
  }),
  create: (data: Partial<QuestOwner>) => ({
    method: Method.POST,
    path: '/admin/owners',
    body: { owner: data }
  } as Endpoint<Partial<QuestOwner>>),
  update: (address: string, data: Partial<QuestOwner>) => ({
    method: Method.PUT,
    path: `/admin/owners/${address}`,
    body: { owner: data }
  } as Endpoint<Partial<QuestOwner>>),
}

export const AdminNotificationEndpoints = {
  all: ({
    page,
    limit,
    title,
    userId
  }: {
    page: number,
    limit: number,
    title?: string,
    userId?: number,
  }) => ({
    method: Method.GET,
    path: `/admin/notifications?page=${page}&limit=${limit}&title=${title ?? ''}&userId=${userId ?? ''}`
  }),
  create: (data: Partial<Notification>) => ({
    method: Method.POST,
    path: '/admin/notifications',
    body: { notification: data }
  }),
  subscribe: (data: Partial<Device>) => ({
    method: Method.POST,
    path: '/admin/notifications/subscriptions',
    body: data
  }),
}

export const AdminSegmentEndpoints = {
  all: ({ page, limit, name }: { page: number, limit: number, name?: string }) => ({
    method: Method.GET,
    path: `/admin/segments?page=${page}&limit=${limit}&name=${name ?? ''}`
  }),
  create: (body: { name: string, description: string }) => ({
    method: Method.POST,
    path: `/admin/segments`,
    body: body
  })
}

export const AdminUserEndpoints = {
  all: ({ page, limit, country, query, withWallets }: IUseUsers) => ({
    method: Method.GET,
    path: `/admin/users?page=${page}&limit=${limit}&country=${country ?? ''}&query=${query ?? ''}${withWallets ? '&withWallets=true' : ''}`
  }),
  find: (id: string) => ({
    method: Method.GET,
    path: `/admin/users/${id}`
  }),
  update: (id: string, data: Partial<User>) => ({
    method: Method.PUT,
    path: `/admin/users/${id}`,
    body: { user: data }
  }),
}

export const AdminUserContentEndpoints = {
  all: ({ page, limit, title, gameId }: IUseUserContent) => ({
    method: Method.GET,
    path: `/admin/user_contents?page=${page}&limit=${limit}&title=${title ?? ''}&gameId=${gameId ?? ''}`
  }),
  find: (id: string) => ({
    method: Method.GET,
    path: `/admin/user_contents/${id}`
  }),
  update: (id: string, data: Partial<UserContent>) => ({
    method: Method.PUT,
    path: `/admin/user_contents/${id}`,
    body: { userContent: data }
  }),
  approve: (id: string, data: Partial<UserContent>) => ({
    method: Method.PUT,
    path: `/admin/user_contents/${id}/approve`,
    body: { userContent: data }
  }),
  reject: (id: string, data: Partial<UserContent>) => ({
    method: Method.PUT,
    path: `/admin/user_contents/${id}/reject`,
    body: { userContent: data }
  }),
}

export const AdminMarketPlaceEndpoints = {
  all: ({ page, limit, title, type, name }: IUseItems) => ({
    method: Method.GET,
    path: `/admin/items?page=${page}&limit=${limit}&title=${title ?? ''}&name=${name ?? ''}&type=${type ?? ''}`
  }),
  find: (id: string) => ({
    method: Method.GET,
    path: `/admin/items/${id}`
  }),
  create: (data: any) => ({
    method: Method.POST,
    path: '/admin/items',
    body: data,
    isForm: true,
  }),
  update: (id: string, data: any) => ({
    method: Method.PUT,
    path: `/admin/items/${id}`,
    body: data,
    isForm: true
  }),
  delete: (id: string) => ({
    method: Method.DELETE,
    path: `/admin/items/${id}`
  }),
  stocks: (id: string) => ({
    method: Method.GET,
    path: `/admin/items/${id}/stocks`
  }),
}

export const AdminTournamentsEndpoints = {
  all: ({ page, limit, title, game, sortByStartDate }: IUseTournaments) => ({
    method: Method.GET,
    path: `/admin/tournaments?page=${page}&limit=${limit}&title=${title ?? ''}&slug=${game ?? ''}&sortByStartDate=${sortByStartDate ?? 'asc'}`
  }),
  find: (id: string) => ({
    method: Method.GET,
    path: `/admin/tournaments/${id}`
  }),
  create: (data: CreateTournamentParams) => ({
    method: Method.POST,
    path: '/admin/tournaments',
    body: { tournament: data }
  }),
  update: (id: string, data: UpdateTournamentData) => ({
    method: Method.PUT,
    path: `/admin/tournaments/${id}`,
    body: { tournament: data }
  }),
  delete: (id: string) => ({
    method: Method.DELETE,
    path: `/admin/tournaments/${id}`
  }),
  inscriptions: (filters: IUseInscriptions) => ({
    method: Method.GET,
    path: `/admin/tournaments/${filters.tournamentId}/inscriptions?${parameterize(filters)}`
  }),
  deleteInscription: (id: number, inscriptionId: string) => ({
    method: Method.DELETE,
    path: `/admin/tournaments/${id}/inscriptions/${inscriptionId}`
  }),
  addInscription: (id: string, data: Partial<InscriptionData>) => ({
    method: Method.POST,
    path: `/admin/tournaments/${id}/inscriptions`,
    body: { inscription: data },
  }),
  addInscriptionBatch: (id: string, data: FormData) => ({
    method: Method.POST,
    path: `/admin/tournaments/${id}/inscriptions/batch`,
    body: data,
    isForm: true
  }),
  organizers: () => ({
    method: Method.GET,
    path: `/admin/tournaments/organizers`
  }),
}

export const AdminTokensEndpoints = {
  all: ({ page, limit, name }: IUseTokens) => ({
    method: Method.GET,
    path: `/admin/tokens?page=${page}&limit=${limit}&name=${name ?? ''}`
  }),
  find: (id: string) => ({
    method: Method.GET,
    path: `/admin/tokens/${id}`
  }),
  create: (data: CreateTokenData) => ({
    method: Method.POST,
    path: '/admin/tokens',
    body: data
  }),
  update: (id: string, data: UpdateTokenData) => ({
    method: Method.PUT,
    path: `/admin/tokens/${id}`,
    body: { token: data }
  }),
  delete: (id: string) => ({
    method: Method.DELETE,
    path: `/admin/tokens/${id}`
  }),
}

export const AdminTokenDropsEndpoints = {
  all: ({ page, limit, title }: { page: number, limit: number, title?: string }) => ({
    method: Method.GET,
    path: `/admin/token-drops?page=${page}&limit=${limit}&title=${title}`
  }),
  find: (id: string) => ({
    method: Method.GET,
    path: `/admin/token-drops/${id}`
  }),
  create: (data: any) => ({
    method: Method.POST,
    path: '/admin/token-drops',
    body: data,
  }),
  update: (id: string, data: any) => ({
    method: Method.PUT,
    path: `/admin/token-drops/${id}`,
    body: data,
  }),
  delete: (id: string) => ({
    method: Method.DELETE,
    path: `/admin/token-drops/${id}`
  }),
}

export const AdminAssignmentEndpoints = {
  all: ({ page, limit, userId, tokenId }: IUseAssignments) => ({
    method: Method.GET,
    path: `/admin/tokens/${tokenId}/assignments?page=${page}&limit=${limit}&userId=${userId ?? ''}`
  }),
  create: (tokenId: string, userId: number, name: string, image: string) => ({
    method: Method.POST,
    path: `/admin/tokens/${tokenId}/assignments`,
    body: {
      assignment: { userId, token: { name, image } },
    }
  }),
  createBatch: (tokenId: string, data: FormData) => ({
    method: Method.POST,
    path: `/admin/tokens/${tokenId}/assignments/batch`,
    body: data,
    isForm: true
  }),
  delete: (tokenId: string, id: string) => ({
    method: Method.DELETE,
    path: `/admin/tokens/${tokenId}/assignments/${id}`
  }),
}

export const AdminScholarshipEndpoints = {
  all: (params: URLSearchParams) => ({
    method: Method.GET,
    path: `/admin/scholarships?${params.toString()}`
  }),
  find: (id: string) => ({
    method: Method.GET,
    path: `/admin/scholarships/${id}`
  })
}

export const AdminCredentialsEndpoints = {
  find: (id: string) => ({
    method: Method.GET,
    path: `/admin/credentials/${id}`
  }),
  all: ({ slug, status, query, page, limit }: IUseCredentials) => ({
    method: Method.GET,
    path: `/admin/credentials?${parameterize({ slug, status, query, page, limit })}`
  }),
  update: (id: string, data: Partial<GameCredentials>) => ({
    method: Method.PUT,
    path: `/admin/credentials/${id}`,
    body: { credential: data }
  }),
  create: (data: Partial<GameCredentials>) => ({
    method: Method.POST,
    path: `/admin/credentials`,
    body: { credential: data }
  }),
}

export const AdminRelevantActionsEndpoints = {
  all: ({ page, limit }: IUseRelevantActions) => ({
    method: Method.GET,
    path: `/admin/relevant_actions?${parameterize({ page, limit })}`
  }),
  update: (id: number, data: Partial<RelevantAction>) => ({
    method: Method.PUT,
    path: `/admin/relevant_actions/${id}`,
    body: { relevantAction: data }
  }),
  create: (data: Partial<RelevantAction>) => ({
    method: Method.POST,
    path: `/admin/relevant_actions`,
    body: { relevantAction: data }
  }),
  delete: (id: number) => ({
    method: Method.DELETE,
    path: `/admin/relevant_actions/${id}`,
  }),
  find: (id: number) => ({
    method: Method.GET,
    path: `/admin/relevant_actions/${id}`
  }),
}

export const AdminAlertsEndpoints = {
  all: ({ page, limit }: IUseAlerts) => ({
    method: Method.GET,
    path: `/admin/alerts?${parameterize({ page, limit })}`
  }),
  update: (id: number, data: Partial<Alert>) => ({
    method: Method.PUT,
    path: `/admin/alerts/${id}`,
    body: { alert: data }
  }),
  create: (data: Partial<Alert>) => ({
    method: Method.POST,
    path: `/admin/alerts`,
    body: { alert: data }
  }),
  delete: (id: number) => ({
    method: Method.DELETE,
    path: `/admin/alerts/${id}`,
  }),
  find: (id: number) => ({
    method: Method.GET,
    path: `/admin/alerts/${id}`
  }),
}

export const AdminRolesEndpoints = {
  roles: (filter: { page: number, limit: number }): Endpoint => ({
    method: Method.GET,
    path: `/admin/roles?${parameterize({ ...filter })}`
  }),
  createRole: (data: Partial<Role>): Endpoint => ({
    method: Method.POST,
    path: `/admin/roles`,
    body: { role: data }
  }),
  findRole: (id: number): Endpoint => ({
    method: Method.GET,
    path: `/admin/roles/${id}`,
  }),
  updateRole: (id: number, data: Partial<Role>): Endpoint => ({
    method: Method.PUT,
    path: `/admin/roles/${id}`,
    body: { role: data }
  }),
  deleteRole: (id: number): Endpoint => ({
    method: Method.DELETE,
    path: `/admin/roles/${id}`
  }),
  rolesPermissions: (filter: { page: number, limit: number }): Endpoint => ({
    method: Method.GET,
    path: `/admin/roles_permissions?${parameterize({ ...filter })}`
  }),
  createRolesPermission: (data: Partial<RolePermission>): Endpoint => ({
    method: Method.POST,
    path: `/admin/roles_permissions`,
    body: { permission: data }
  }),
  findRolesPermission: (id: number): Endpoint => ({
    method: Method.GET,
    path: `/admin/roles_permissions/${id}`,
  }),
  updateRolesPermission: (id: number, data: Partial<RolePermission>): Endpoint => ({
    method: Method.PUT,
    path: `/admin/roles_permissions/${id}`,
    body: { permission: data }
  }),
  deleteRolesPermission: (id: number): Endpoint => ({
    method: Method.DELETE,
    path: `/admin/roles_permissions/${id}`
  }),
}

export const AdminRouletteEndpoints = {
  all: (): Endpoint => ({
    method: Method.GET,
    path: `/admin/roulette_values`
  }),
  update: (id: number, data: Partial<RouletteValue>): Endpoint => ({
    method: Method.PUT,
    path: `/admin/roulette_values/${id}`,
    body: { roulette_value: data }
  }),
}

export type IPaymentsGetAllProps = {
  status?: string;
  userId: number;
  budget: string;
  page: number;
  limit: number;
};

export const AdminPaymentsEndpoints = {
  all: (filter: IPaymentsGetAllProps) => ({
    method: Method.GET,
    path: `/admin/payments?${parameterize(filter)}`
  }),
  export: () => ({
    method: Method.GET,
    path: `/admin/payments/export`
  }),
  new: (data: any) =>
  ({
    method: Method.POST,
    path: '/admin/payments',
    body: data
  } as Endpoint<any>),
  update: (id: any, data: any) =>
  ({
    method: Method.PUT,
    path: `/admin/payments/${id}`,
    body: data
  } as Endpoint<any>),
  bulk_create: (data: BatchPaymentsUpload) =>
  ({
    method: Method.POST,
    path: `/admin/payments/bulk_create`,
    body: data,
    isForm: true
  } as Endpoint<any>),
  bulk_update: (data: BatchPaymentsUpload) =>
  ({
    method: Method.PUT,
    path: `/admin/payments/bulk_update`,
    body: data,
    isForm: true
  } as Endpoint<any>),
  createPaymentsForTournamentWinners: (tournamentId: number, network: string) => ({
    method: Method.POST,
    path: `/admin/payments/create_payments_for_tournament_winners`,
    body: { tournamentId, network }
  }) as Endpoint<any>,
  resume: () => ({
    method: Method.GET,
    path: '/admin/payments/resume'
  }),
  bulk_update_after_airdrop: (data: any) =>
  ({
    method: Method.PUT,
    path: `/admin/payments/bulk_update`,
    body: data,
    isForm: false
  } as Endpoint<any>),
}

export const AdminTreasuresGameEndpoints = {
  index: (params: IUseTreasuresGame): Endpoint => ({
    method: Method.GET,
    path: `/admin/treasures_games?${parameterize(params)}&include_beta_tested=true`
  }),
  find: (id: string) => ({
    method: Method.GET,
    path: `/admin/treasures_games/${id}`
  }),
  show: (slug: string): Endpoint => ({
    method: Method.GET,
    path: `/admin/treasures_games/${slug}`
  }),
  create: (data: Partial<TreasuresGame>) => ({
    method: Method.POST,
    path: '/admin/treasures_games',
    body: data,
  }),
  update: (id: string, data: Partial<TreasuresGame>) => ({
    method: Method.PUT,
    path: `/admin/treasures_games/${id}`,
    body: data,
  }),
  destroy: (id: string) => ({
    method: Method.DELETE,
    path: `/admin/treasures_games/${id}`,
  }),
  movements: (id: string) => ({
    method: Method.GET,
    path: `/admin/treasures_games/${id}/treasures_games_movements`
  }),
  exportMovements: (id: string) => ({
    method: Method.GET,
    path: `/admin/treasures_games/${id}/treasures_games_movements/export`
  }),
}


export const AdminSurveysEndpoints = {
  all: (filter: IPaymentsGetAllProps) => ({
    method: Method.GET,
    path: `/admin/surveys?${parameterize(filter)}`
  }),
  find: (id: any) =>
  ({
    method: Method.GET,
    path: `/admin/surveys/${id}`,
  } as Endpoint<any>),
  new: (data: any) =>
  ({
    method: Method.POST,
    path: '/admin/surveys',
    body: data
  } as Endpoint<any>),
  update: (id: any, data: any) =>
  ({
    method: Method.PUT,
    path: `/admin/surveys/${id}`,
    body: data
  } as Endpoint<any>)
}

export type IPreferencesGetAllProps = {
  name?: string;
  longName?: string;
  page: number;
  limit: number;
};

export type IStoriesGetAllProps = {
  title?: string;
  page: number;
  limit: number;
};

export const AdminUserPreferencesEndpoints = {
  gameCategories: {
    all: (filter: IPreferencesGetAllProps) => ({
      method: Method.GET,
      path: `/admin/game_categories?${parameterize(filter)}`
    }),
    find: (id: any) =>
    ({
      method: Method.GET,
      path: `/admin/game_categories/${id}`,
    } as Endpoint<any>),
    new: (data: any) =>
    ({
      method: Method.POST,
      path: '/admin/game_categories',
      body: data
    } as Endpoint<any>),
    update: (id: any, data: any) =>
    ({
      method: Method.PUT,
      path: `/admin/game_categories/${id}`,
      body: data
    } as Endpoint<any>),
    delete: (id: string) => ({
      method: Method.DELETE as Method.DELETE,
      path: `/admin/game_categories/${id}`
    }),
  },
  opportunities: {
    all: (filter: IPreferencesGetAllProps) => ({
      method: Method.GET,
      path: `/admin/opportunities?${parameterize(filter)}`
    }),
    find: (id: any) =>
    ({
      method: Method.GET,
      path: `/admin/opportunities/${id}`,
    } as Endpoint<any>),
    new: (data: any) =>
    ({
      method: Method.POST,
      path: '/admin/opportunities',
      body: data
    } as Endpoint<any>),
    update: (id: any, data: any) =>
    ({
      method: Method.PUT,
      path: `/admin/opportunities/${id}`,
      body: data
    } as Endpoint<any>),
    delete: (id: string) => ({
      method: Method.DELETE as Method.DELETE,
      path: `/admin/opportunities/${id}`
    }),
  },
  platforms: {
    all: (filter: IPreferencesGetAllProps) => ({
      method: Method.GET,
      path: `/admin/gaming_platforms?${parameterize(filter)}`
    }),
    find: (id: any) =>
    ({
      method: Method.GET,
      path: `/admin/gaming_platforms/${id}`,
    } as Endpoint<any>),
    new: (data: any) =>
    ({
      method: Method.POST,
      path: '/admin/gaming_platforms',
      body: data
    } as Endpoint<any>),
    update: (id: any, data: any) =>
    ({
      method: Method.PUT,
      path: `/admin/gaming_platforms/${id}`,
      body: data
    } as Endpoint<any>),
    delete: (id: string) => ({
      method: Method.DELETE as Method.DELETE,
      path: `/admin/gaming_platforms/${id}`
    }),
  }
}

export const AdminStoriesEndpoints = {
  all: (filter: IStoriesGetAllProps) => ({
    method: Method.GET,
    path: `/admin/stories?${parameterize(filter)}`,
  }),
  find: (id: any) =>
  ({
    method: Method.GET,
    path: `/admin/stories/${id}`,
  } as Endpoint<any>),
  create: (data: any) =>
  ({
    method: Method.POST,
    path: '/admin/stories',
    body: data
  } as Endpoint<any>),
  update: (id: any, data: any) =>
  ({
    method: Method.PUT,
    path: `/admin/stories/${id}`,
    body: data
  } as Endpoint<any>),
  delete: (id: any) =>
  ({
    method: Method.DELETE,
    path: `/admin/stories/${id}`,
  } as Endpoint<any>),
}

export type IStoryCategoriesGetAllProps = {
  name?: string;
  page: number;
  limit: number;
};

export type IBlockchainTokenCatalogGetAllProps = {
  name?: string;
  network?: string;
  tokenType?: string;
  page: number;
  limit: number;
}

export type IBlockchainNetworkGetAllProps = {
  name?: string;
  page: number;
  limit: number;
}

export const AdminStoryCategoriesEndpoints = {
  all: ({ name, limit, page }: IStoryCategoriesGetAllProps) => ({
    method: Method.GET,
    path: `/admin/story_categories?${parameterize({ name, limit, page })}`
  }),
  find: (id: number) =>
  ({
    method: Method.GET,
    path: `/admin/story_categories/${id}`
  } as Endpoint<any>),
  update: (id: number, data: any) =>
  ({
    method: Method.PUT,
    path: `/admin/story_categories/${id}`,
    body: data
  } as Endpoint<any>),
  create: (data: any) =>
  ({
    method: Method.POST,
    path: '/admin/story_categories',
    body: data
  } as Endpoint<any>),
  delete: (id: number) => ({
    method: Method.DELETE,
    path: `/admin/story_categories/${id}`
  }),
}

export const AdminBlockchainTokenCatalog = {
  all: ({ name, network, tokenType, limit, page }: IBlockchainTokenCatalogGetAllProps) => ({
    method: Method.GET,
    path: `/admin/blockchain_tokens?${parameterize({ name, network, tokenType, limit, page })}`
  }),
  find: (id: number) =>
  ({
    method: Method.GET,
    path: `/admin/blockchain_tokens/${id}`
  }),
  update: (id: number, data: any) =>
  ({
    method: Method.PUT,
    path: `/admin/blockchain_tokens/${id}`,
    body: data
  }),
  create: (data: any) =>
  ({
    method: Method.POST,
    path: `/admin/blockchain_tokens`,
    body: data
  }),
  delete: (id: any) =>
  ({
    method: Method.DELETE,
    path: `/admin/blockchain_tokens/${id}`
  })
}

export const AdminBlockchainNetwork = {
  all: ({ name, limit, page }: IBlockchainNetworkGetAllProps) => ({
    method: Method.GET,
    path: `/admin/blockchain_token_networks?${parameterize({ name, limit, page })}`
  }),
  find: (id: number) =>
  ({
    method: Method.GET,
    path: `/admin/blockchain_token_networks/${id}`
  }),
  update: (id: number, data: any) =>
  ({
    method: Method.PUT,
    path: `/admin/blockchain_token_networks/${id}`,
    body: data
  }),
  create: (data: any) =>
  ({
    method: Method.POST,
    path: `/admin/blockchain_token_networks`,
    body: data
  }),
  delete: (id: any) =>
  ({
    method: Method.DELETE,
    path: `/admin/blockchain_token_networks/${id}`
  })
}
import { AdminStoriesEndpoints, fetcher } from "@olagg/api-hooks";

const useStories = () => {
    const getAll = async ({ title, page, limit }: { title?: string, page: number, limit: number }) => {
        const response = await fetcher(AdminStoriesEndpoints.all({ title, page, limit }));
        return {
            stories: response.stories,
            count: response.meta.count
        };
    }

    const getStory = async (id: number) => {
        const response = await fetcher(AdminStoriesEndpoints.find(id));
        return response.story;
    }

    const deleteStory = async (id: number) => {
        await fetcher(AdminStoriesEndpoints.delete(id));
    }

    return {
        getAll,
        getStory,
        deleteStory
    }
};

export default useStories;

import type { InferType } from 'yup';
import * as Yup from 'yup';

export const createQuestSchema = Yup.object({
  action: Yup
    .string()
    .required('El link del botón es requerido')
    .min(5, 'El link del botón debe ser de mínimo 5 caracteres'),
  buttonText: Yup
    .string()
    .required('El texto del botón es requerido')
    .min(5, 'El texto del botón debe ser de mínimo 5 caracteres')
    .max(20, 'El texto del botón debe ser de máximo 20 caracteres'),
  title: Yup
    .string()
    .required('El título es requerido')
    .min(5, 'El título debe ser de mínimo 5 caracteres')
    .max(50, 'El título debe ser de máximo 50 caracteres'),
  banner: Yup.string(),
  icon: Yup
    .string()
    .required('El link del icono es requerido')
    .min(5, 'El link del icono debe ser de mínimo 5 caracteres')
    .optional()
    .nullable(),
  type: Yup.string().required('El tipo de Quest es requerido'),
  score: Yup
    .number()
    .required('El score es requerido')
    .min(1, 'El score debe ser de mínimo 1')
    .max(200, 'El score debe ser de máximo 200'),
  description: Yup.string().test(
    'empty-or-lengthy',
    'Description must be at least 8 characters and max 200',
    password => !password || password.length >= 8 && password.length <= 200,
  ),
  recursive: Yup.boolean().optional().default(false).nullable(),
  multiplier: Yup
    .number()
    .required('El multiplicador es requerido')
    .min(1, 'El multiplicador debe ser de mínimo 1')
    .max(1000, 'El multiplicador debe ser de máximo 1000'),
  ownerId: Yup.string().required('El ownerId es requerido'),
  trigger: Yup.string(),
  dependsOn: Yup.string().optional().nullable(),
  callback: Yup
    .string()
    .optional()
    .nullable(),
  visible: Yup.boolean().optional(),
  secretWord: Yup
    .string()
    .optional()
    .nullable(),
  secretWordInstructions: Yup
    .string()
    .optional()
    .nullable(),
  secretWordMaxAttempts: Yup
    .number()
    .typeError('Ingresa un numero')
    .required()
    .default(0),
  order: Yup
    .number()
    .typeError('El orden debe ser un numero entero y mayor o igual a 0')
    .required(),
  availableFrom: Yup.date().optional().nullable(),
  availableTo: Yup.date().optional().nullable(),
  extraData: Yup.object({
    subtype: Yup.string().when('type', (type, schema) => {
      return type[0] === 'TWITTER' ? schema.required('El subtipo es requerido') : schema
    }),
    tweetText: Yup.string().when('subtype', (subtype, schema) => {
      // @ts-ignore
      return subtype[0]?.value === 'tweet' ? schema.required('El texto del tweet es requerido') : schema
    }),
    tweetId: Yup.string().when('subtype', (subtype, schema) => {
      // @ts-ignore
      return (subtype[0]?.value && subtype[0]?.value !== 'tweet' && subtype[0]?.value !== 'follow') ? schema.required('El ID del tweet es requerido') : schema
    }),
    username: Yup.string().when('subtype', (subtype, schema) => {
      // @ts-ignore
      return subtype[0]?.value === 'follow' ? schema.required('El username es requerido') : schema
    }),
    en: Yup.object({
      title: Yup.string().optional().nullable(),
      buttonText: Yup.string().optional().nullable(),
      secretWordInstructions: Yup.string().optional().nullable(),
      description: Yup.string().optional().nullable(),
    }),
    pt: Yup.object({
      title: Yup.string().optional().nullable(),
      buttonText: Yup.string().optional().nullable(),
      secretWordInstructions: Yup.string().optional().nullable(),
      description: Yup.string().optional().nullable(),
    }),
  })
    .optional()
    .nullable(),
  onboarding: Yup.boolean().optional().default(false),
  blockchainNeeded: Yup.boolean().optional().default(false),
  network: Yup.string().nullable(),
  token: Yup.string().nullable(),
  tokenName: Yup.string().nullable(),
  tokenId: Yup.string().nullable(),
  contractAddress: Yup.string().nullable(),
  neededAmount: Yup
    .number()
    .typeError('Ingresa un numero igual o mayor a 0. Usa punto para la coma')
    .transform((_, val) => (val === '' || val === null) ? 0 : Number(val))
    .optional()
    .default(0),
  retryIn: Yup
    .number()
    .typeError('Ingresa un numero')
    .optional()
    .transform((_, val) => (val === '' || val === null) ? 0 : Number(val))
    .default(0),
  howToGetTokenUrl: Yup.string().nullable(),
})

export const updateQuestSchema = Yup.object({
  action: Yup
    .string()
    .required('El link del botón es requerido')
    .min(5, 'El link del botón debe ser de mínimo 5 caracteres'),
  buttonText: Yup
    .string()
    .required('El texto del botón es requerido')
    .min(5, 'El texto del botón debe ser de mínimo 5 caracteres')
    .max(20, 'El texto del botón debe ser de máximo 20 caracteres'),
  title: Yup
    .string()
    .required('El título es requerido')
    .min(5, 'El título debe ser de mínimo 5 caracteres')
    .max(50, 'El título debe ser de máximo 50 caracteres'),
  banner: Yup.string().optional().nullable(),
  icon: Yup
    .string()
    .required('El link del icono es requerido')
    .min(5, 'El link del icono debe ser de mínimo 5 caracteres')
    .optional()
    .nullable(),
  type: Yup.string().required('El tipo de Quest es requerido'),
  score: Yup
    .number()
    .required('El score es requerido')
    .min(1, 'El score debe ser de mínimo 1')
    .max(200, 'El score debe ser de máximo 200'),
  description: Yup.string().test(
    'empty-or-lengthy',
    'Description must be at least 8 characters and max 200',
    password => !password || password.length >= 8 && password.length <= 200,
  ).optional().nullable(),
  recursive: Yup.boolean().optional().default(true).nullable(),
  multiplier: Yup
    .number()
    .required('El multiplicador es requerido')
    .min(1, 'El multiplicador debe ser de mínimo 1')
    .max(1000, 'El multiplicador debe ser de máximo 1000'),
  ownerId: Yup.string().required('El ownerId es requerido'),
  trigger: Yup.string(),
  dependsOn: Yup.string().optional().nullable(),
  callback: Yup
    .string()
    .optional()
    .nullable(),
  visible: Yup.boolean().optional().default(true),
  secretWord: Yup
    .string()
    .optional()
    .nullable(),
  secretWordInstructions: Yup
    .string()
    .optional()
    .nullable(),
  secretWordMaxAttempts: Yup
    .number()
    .typeError('Ingresa un numero')
    .required()
    .default(0),
  order: Yup
    .number()
    .typeError('El orden debe ser un numero entero y mayor o igual a 0')
    .required(),
  availableFrom: Yup.date().optional().nullable(),
  availableTo: Yup.date().optional().nullable(),
  extraData: Yup.object({
    subtype: Yup.string().when('type', (type, schema) => {
      return type[0] === 'TWITTER' ? schema.required('El subtipo es requerido') : schema
    }),
    tweetText: Yup.string().when('subtype', (subtype, schema) => {
      // @ts-ignore
      return subtype[0]?.value === 'tweet' ? schema.required('El texto del tweet es requerido') : schema
    }),
    tweetId: Yup.string().when('subtype', (subtype, schema) => {
      // @ts-ignore
      return (subtype[0]?.value && subtype[0]?.value !== 'tweet' && subtype[0]?.value !== 'follow') ? schema.required('El ID del tweet es requerido') : schema
    }),
    username: Yup.string().when('subtype', (subtype, schema) => {
      // @ts-ignore
      return subtype[0]?.value === 'follow' ? schema.required('El username es requerido') : schema
    }),
    en: Yup.object({
      title: Yup.string().optional().nullable(),
      buttonText: Yup.string().optional().nullable(),
      secretWordInstructions: Yup.string().optional().nullable(),
      description: Yup.string().optional().nullable(),
    }),
    pt: Yup.object({
      title: Yup.string().optional().nullable(),
      buttonText: Yup.string().optional().nullable(),
      secretWordInstructions: Yup.string().optional().nullable(),
      description: Yup.string().optional().nullable(),
    }),
  })
    .optional()
    .nullable(),
  onboarding: Yup.boolean().optional().default(false),
  blockchainNeeded: Yup.boolean().optional().default(false),
  network: Yup.string().nullable(),
  token: Yup.string().nullable(),
  tokenName: Yup.string().nullable(),
  tokenId: Yup.string().nullable(),
  contractAddress: Yup.string().nullable(),
  neededAmount: Yup
    .number()
    .typeError('Ingresa un numero igual o mayor a 0. Usa punto para la coma')
    .transform((_, val) => (val === '' || val === null) ? 0 : Number(val))
    .optional()
    .default(0),
  retryIn: Yup
    .number()
    .typeError('Ingresa un numero')
    .optional()
    .transform((_, val) => (val === '' || val === null) ? 0 : Number(val))
    .default(0),
  howToGetTokenUrl: Yup.string().nullable(),
})

export const BatchCompleteSchema = Yup.object({
  file: Yup.mixed()
})

export type CreateQuestInput = InferType<typeof createQuestSchema>

export type UpdateQuestInput = InferType<typeof updateQuestSchema>

export type BatchComplete = InferType<typeof BatchCompleteSchema>

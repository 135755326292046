import { Flex, Tabs, TabList, Tab, TabPanels, TabPanel, Button } from "@chakra-ui/react";
import { useEffect, useState, lazy } from "react";
import { UserPreferencesStrings } from './types'

import GameCategoriesForm from "./components/gameCategories/form";
import GameCategoriesList from "./components/gameCategories/List";
import OpportunitiesForm from "./components/opportunities/form";
import OpportunitiesList from "./components/opportunities/List";
import PlatformsForm from "./components/platforms/form";
import PlatformsList from "./components/platforms/List";
import { useSearchParams } from "react-router-dom";

const UserPreferences = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const paramPreference = searchParams.get('selectedPreference')
    const [tabIndex, setTabIndex] = useState<number>(0)
    const [selectedPreference, setSelectedPreference] = useState<string>(paramPreference || 'gameCategories')

    const Form = () => {
        switch (selectedPreference) {
            case 'gameCategories':
                return <GameCategoriesForm tabIndex={tabIndex} selectedPreference={selectedPreference} />
                break;
            case 'opportunities':
                return <OpportunitiesForm tabIndex={tabIndex} selectedPreference={selectedPreference} />
                break;
            case 'platforms':
                return <PlatformsForm tabIndex={tabIndex} selectedPreference={selectedPreference} />
                break;
            default:
                return null
                break;
        }
    };

    const List = () => {
        switch (selectedPreference) {
            case 'gameCategories':
                return <GameCategoriesList tabIndex={tabIndex} selectedPreference={selectedPreference} />
                break;
            case 'opportunities':
                return <OpportunitiesList tabIndex={tabIndex} selectedPreference={selectedPreference} />
                break;
            case 'platforms':
                return <PlatformsList tabIndex={tabIndex} selectedPreference={selectedPreference} />
                break;
            default:
                return null
                break;
        }
    };

    return (
        <>
            <Flex gap='10px' px={'20px'} pb='20px'>
                <Button onClick={() => setSelectedPreference('gameCategories')} variant={selectedPreference == 'gameCategories' ? 'solid' : 'outline'} h='30px' p='1px 10px 0 10px'>{UserPreferencesStrings['gameCategories']}</Button>
                <Button onClick={() => setSelectedPreference('platforms')} variant={selectedPreference == 'platforms' ? 'solid' : 'outline'} h='30px' p='1px 10px 0 10px'>{UserPreferencesStrings['platforms']}</Button>
                <Button onClick={() => setSelectedPreference('opportunities')} variant={selectedPreference == 'opportunities' ? 'solid' : 'outline'} h='30px' p='1px 10px 0 10px'>{UserPreferencesStrings['opportunities']}</Button>
            </Flex>
            <Tabs
                index={tabIndex}
                onChange={setTabIndex}
            >
                <TabList
                    margin='0 20px 0px 20px'
                    borderColor='#343847'
                    position='relative'
                    sx={{
                        '&::-webkit-scrollbar': {
                            display: 'none !important'
                        },
                        overflowX: 'auto !important',
                        overflowY: 'hidden !important',
                    }}
                >
                    <Tab color="white" whiteSpace='nowrap' paddingBottom='4px' _selected={{ fontWeight: '800', borderBottom: '4px solid white', paddingBottom: '2px' }} fontWeight='600'>
                        Listado
                    </Tab>
                    <Tab color="white" whiteSpace='nowrap' paddingBottom='4px' _selected={{ fontWeight: '800', borderBottom: '4px solid white', paddingBottom: '2px' }} fontWeight='600'>
                        Cargar Nuevo
                    </Tab>
                </TabList>

                <TabPanels p='0'>
                    <TabPanel p='0'>
                        <List />
                    </TabPanel>
                    <TabPanel p='0'>
                        <Form />
                    </TabPanel>
                </TabPanels>
            </Tabs>

        </>
    )
};

export default UserPreferences;

import { Center, Flex, Heading } from "@chakra-ui/react";
import useAdminRoulette from '@olagg/api-hooks/queries/useAdminRoulette';
import Table from "./Table";

export default function Roulette() {
  const { values, update } = useAdminRoulette();

  return (
    <Center w='full' color='white' flexDirection='column'>
      <Heading my='5'>Ruleta</Heading>
      <Table rouletteValues={values!} update={update} />
      {values?.length && values?.reduce((prev, curr) => ({...curr, probability: prev.probability + curr.probability})).probability !== 100 && (
        <Flex
          alignItems='center' justifyContent='center' bgColor='red.500'
          color='white' fontSize='1.25rem' py='3' w='full'
        >
          ¡La suma de las probabilidades no da 100!
        </Flex>
      )}
    </Center>
  )
}

import { Flex, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getContract, readContract } from "thirdweb";
import ERC20_ABI from './erc20_abi.json'

export function CheckAllowance({
  airdropContractAddress,
  contractAddress,
  setNeedsApproval,
  setIsCheckingAllowance,
  total,
  symbol,
  count,
  walletAddress,
  client,
  chain
}: {
  airdropContractAddress: string,
  contractAddress: string,
  setNeedsApproval: (value: boolean) => void,
  setIsCheckingAllowance: (value: boolean) => void,
  total: number,
  symbol: string,
  count: number,
  walletAddress: string,
  client: any,
  chain: any
}) {

  const [allowance, setAllowance] = useState(0);
  const [decimals, setDecimals] = useState(6);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDecimals, setIsLoadingDecimals] = useState(true);

  const fetchAllowance = async () => {
    setIsLoading(true);
    setIsLoadingDecimals(true);
    setIsCheckingAllowance(true)
    try {
      const contract = getContract({
        client,
        address: contractAddress,
        chain: chain,
        abi: ERC20_ABI,
      });

      const tokenDecimals = await readContract({
        contract,
        method: "decimals",
        params: [],
      });

      const rawAllowance = await readContract({
        contract,
        method: "allowance",
        params: [walletAddress, airdropContractAddress],
      });

      const formattedAllowance = Number(rawAllowance) / Math.pow(10, tokenDecimals);
      setAllowance(formattedAllowance);
      setDecimals(tokenDecimals);
    } catch (error) {
      console.error("Error obteniendo allowance:", error);
    } finally {
      setIsLoading(false);
      setIsLoadingDecimals(false);
      setIsCheckingAllowance(false)
    }
  };

  useEffect(() => {
    fetchAllowance();
  }, [walletAddress]);

  useEffect(() => {
    count > 0 && fetchAllowance();
  }, [count]);

  useEffect(() => {
    if (!isLoading) {
      setNeedsApproval(allowance < total)
    }
  }, [allowance, count, isLoading])

  return (
    <Flex w='full' fontSize={'18px'}>
      <Flex>Allowance:</Flex>
      <Flex ml='auto' fontWeight={'700'}>
        {(isLoading && isLoadingDecimals) ? <Spinner w='22px' h='22px' color='white' /> : <>{allowance?.toString()} {symbol}</>}
      </Flex>
    </Flex>
  );
}
import { Flex, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getContract, readContract } from "thirdweb";
import ERC20_ABI from './erc20_abi.json'

export function ReadBalance({ walletAddress, contractAddress, symbol, chain, client }: { walletAddress: string, contractAddress: string, symbol: string, chain: any, client: any }) {
    const [balance, setBalance] = useState(0);
    const [decimals, setDecimals] = useState(6);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingDecimals, setIsLoadingDecimals] = useState(true);  
   
    useEffect(() => {
        const fetchBalance = async () => {
            setIsLoading(true)
            setIsLoadingDecimals(true)
            try {
                // Obtener el contrato con ABI
                const contract = getContract({
                    client,
                    address: contractAddress,
                    chain: chain,
                    abi: ERC20_ABI
                });

                // Leer los decimales del token USDT
                const tokenDecimals = await readContract({
                    contract,
                    method: "decimals",
                    params: [],
                });

                setDecimals(tokenDecimals)

                // Leer balance de la wallet conectada
                const rawBalance = await readContract({
                    contract,
                    method: "balanceOf",
                    params: [walletAddress],
                });

                // Convertir balance a formato legible
                const formattedBalance = Number(rawBalance) / Math.pow(10, tokenDecimals);
                setBalance(formattedBalance);
            } catch (error) {
                console.error("Error obteniendo balance:", error);
            } finally {
                setIsLoading(false)
                setIsLoadingDecimals(false)
            }
        };

        fetchBalance();
    }, [walletAddress]);
    

    return (
        <Flex w='full' fontSize={'18px'}>
            <Flex>Balance:</Flex>
            <Flex ml='auto' fontWeight={'700'}>
                {(isLoading && isLoadingDecimals) ?<Spinner w='22px' h='22px' color='white' /> : <>{balance?.toString()} {symbol}</>}
            </Flex>
        </Flex>
    );
}
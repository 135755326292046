import { Center, Heading, VStack } from "@chakra-ui/react";

import Form from "./Form";
import useBlockchainTokenCatalog from "../../hooks/useBlockchainTokenCatalog";

const Create = () => {
  const { createBlockchainToken } = useBlockchainTokenCatalog();

  return (
    <>
      <Center w={'full'} pb={4}>
        <VStack color={'white'}>
          <Heading my={2}>
              Crear Blockchain Token
          </Heading>
          <Form onSubmit={createBlockchainToken} />
        </VStack>
      </Center>
    </>
  )
};

export default Create;
import { AdminStoryCategoriesEndpoints, fetcher } from "@olagg/api-hooks";

type IProps = {
    name?: string;
    limit: number;
    page: number;
}
const useStoryCategories = () => {

    const getCategories = async ({ name, limit, page }: IProps) =>
        fetcher(AdminStoryCategoriesEndpoints.all({ name, limit, page }))
            .then(response => ({
                categories: response.storyCategories,
                count: response.meta.count
            }));

    const getStoryCategory = async (id: number) => {
        const response = await fetcher(AdminStoryCategoriesEndpoints.find(id));
        return response.storyCategory;
    }

    const deleteCategory = async (id: number) =>
        await fetcher(AdminStoryCategoriesEndpoints.delete(id));


    return {
        getCategories,
        getStoryCategory,
        deleteCategory
    }
};

export default useStoryCategories;
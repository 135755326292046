import { useState } from "react";

import { AdminBlockchainTokenCatalog } from "@olagg/api-hooks";
import { BlockchainToken } from "@olagg/db-types";
import { IUseBlockchainTokenCatalog } from "@olagg/api-hooks/interfaces/IUseBlockchainTokenCatalog";
import { fetcher } from "@olagg/api-hooks";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

const useBlockchainTokenCatalog = () => {
  const toast = useToast();
  const [blockchainTokens, setBlockchainTokens] = useState<BlockchainToken[]>([]);
  const [blockchainToken, setBlockchainToken] = useState<BlockchainToken>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const navigate = useNavigate();

  const all = async (filter: IUseBlockchainTokenCatalog) => {
    setIsLoading(true);
    fetcher(AdminBlockchainTokenCatalog.all(filter))
      .then(response => {
        setCount(response.meta.count);
        setBlockchainTokens(response.blockchainTokens);
        setIsLoading(false);
      })
  }

  const deleteBlockchainToken = async (id: number) => {
    fetcher(AdminBlockchainTokenCatalog.delete(id))
      .then(() => {
        toast({
          title: 'Catálogo de Blockchain Tokens',
          colorScheme: 'olaggPink',
          status: 'success',
          description: 'Blockchain Token eliminada con éxito',
          position: 'bottom-right'
        });
        window.location.reload();
      })
      .catch((error: Error) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })
  }

  const createBlockchainToken = async (data: FormData) => {
    fetcher(AdminBlockchainTokenCatalog.create(data))
      .then(() => {
        toast({
          title: 'Blockchain creada',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        })
        navigate('/blockchain-token-catalog');
      })
      .catch((error: Error) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        })
      })
  }

  const editBlockchainToken = async (id: number, data: FormData) => {
    fetcher(AdminBlockchainTokenCatalog.update(id, data))
    .then(() => {
      toast({
        title: 'Blockchain actualizada',
        status: 'success',
        duration: 3000,
        position: 'top-right',
        isClosable: true,
      })
      navigate('/blockchain-token-catalog');
    })
    .catch((error: Error) => {
      toast({
        title: 'Error',
        colorScheme: 'olaggYellow',
        status: 'error',
        description: error.message,
        position: 'bottom-right'
      })
    })
  }

  const getBlockchainToken = async (id: number) => {
    setIsLoading(true);
    fetcher(AdminBlockchainTokenCatalog.find(id))
      .then(response => {
        setBlockchainToken(response.blockchainToken);
        setIsLoading(false);
      })
  }
  
  return {
    blockchainTokens,
    isLoading,
    count,
    all,
    deleteBlockchainToken,
    createBlockchainToken,
    editBlockchainToken,
    blockchainToken,
    getBlockchainToken
  }
}

export default useBlockchainTokenCatalog;
import type { InferType } from 'yup';
import * as Yup from 'yup';

export const storyCategorySchema = Yup.object({
    name: Yup.string().required('El título es obligatorio'),
    fixed: Yup.boolean().required('El título es obligatorio'),
    thumbnail: Yup.string().nullable(),
    icon: Yup.string().nullable(),
    extraData: Yup.object({
        en: Yup.object({
            name: Yup.string().required('El título es obligatorio'),
        }).required('El título es obligatorio'),
        pt: Yup.object({
            name: Yup.string().required('El título es obligatorio'),
        }).required('El título es obligatorio'),
    }).required('Las traducciones son obligatorias')
})

export type StoryCategoryData = InferType<typeof storyCategorySchema>;

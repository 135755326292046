import { AdminBlockchainNetwork, fetcher } from "@olagg/api-hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { BlockchainNetwork } from "@olagg/db-types";
import { IUseBlockchainNetwork } from "@olagg/api-hooks/interfaces/IUseBlockchainNetwork"

const useBlockchainNetwork = () => {
  const toast = useToast();
  const [blockchainNetworks, setBlockchainNetworks] = useState<BlockchainNetwork[]>([]);
  const [blockchainNetwork, setBlockchainNetwork] = useState<BlockchainNetwork>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const navigate = useNavigate();

  const all = async (filter: IUseBlockchainNetwork) => {
    setIsLoading(true);
    fetcher(AdminBlockchainNetwork.all(filter))
      .then(response => {
        setCount(response.meta.count);
        setBlockchainNetworks(response.blockchainTokenNetworks);
        setIsLoading(false);
      })
  }

  const deleteBlockchainNetwork = async (id: number) => {
    fetcher(AdminBlockchainNetwork.delete(id))
      .then(() => {
        toast({
          title: 'Blockchain Networks',
          colorScheme: 'olaggPink',
          status: 'success',
          description: 'Blockchain Network eliminada con éxito',
          position: 'bottom-right'
        });
        window.location.reload();
      })
      .catch((error: Error) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })
  }

  const createBlockchainNetwork = async (data: FormData) => {
    fetcher(AdminBlockchainNetwork.create(data))
      .then(() => {
        toast({
          title: 'Blockchain Network creada',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        })
        navigate('/blockchain-token-catalog');
      })
      .catch((error: Error) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        })
      })
  }

  const editBlockchainNetwork = async (id: number, data: FormData) => {
    fetcher(AdminBlockchainNetwork.update(id, data))
    .then(() => {
      toast({
        title: 'Blockchain Network actualizada',
        status: 'success',
        duration: 3000,
        position: 'top-right',
        isClosable: true,
      })
      navigate('/blockchain-token-catalog');
    })
    .catch((error: Error) => {
      toast({
        title: 'Error',
        colorScheme: 'olaggYellow',
        status: 'error',
        description: error.message,
        position: 'bottom-right'
      })
    })
  }

  const getBlockchainNetwork = async (id: number) => {
    setIsLoading(true);
    fetcher(AdminBlockchainNetwork.find(id))
      .then(response => {
        setBlockchainNetwork(response.blockchainTokenNetwork);
        setIsLoading(false);
      })
  }
  
  return {
    blockchainNetworks,
    blockchainNetwork,
    isLoading,
    all,
    createBlockchainNetwork,
    editBlockchainNetwork,
    getBlockchainNetwork,
    deleteBlockchainNetwork,
    count
  }
}

export default useBlockchainNetwork;
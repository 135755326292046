import {
  TableContainer,
  Table as ChakraTable,
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  Tooltip,
} from "@chakra-ui/react"
import { AdminTournamentsEndpoints, fetcher } from "@olagg/api-hooks";
import { IUseInscriptions } from "@olagg/api-hooks/interfaces/IUseInscriptions";
import { InscriptionData, OlaggModule, PermissionEnum } from "@olagg/db-types"
import { FaAward, FaRegClone } from "react-icons/fa"
import { BsSortDownAlt, BsSortDown } from 'react-icons/bs';
import useRoles from "../../../features/Roles/hooks/useRoles";

type TableProps = {
  inscriptions: InscriptionData[];
  onUpdate?: () => void;
  filter: IUseInscriptions;
  onSortChange: (fieldName: string) => void;
}

const Table = ({ inscriptions, onUpdate = () => {}, filter, onSortChange }: TableProps) => {
  const toast = useToast();
  const { missingPermission } = useRoles();
  
  const removeInscription = (inscription: InscriptionData) => {
    fetcher(AdminTournamentsEndpoints.deleteInscription(Number(inscription.tournamentId!), inscription.id!))
      .then(() => {
        toast({
          title: 'Inscripciones',
          colorScheme: 'olaggPink',
          status: 'success',
          description: 'Usuario removido del torneo',
          position: 'bottom-right'
        });
      })
      .catch((error: Error) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })
      .finally(() => {
        onUpdate();
      });
  };

  const sortableColumn = (columnName: string, fieldName: string) => {
    return (
      <Th color="white" cursor='pointer' _hover={{ color: '#777' }} onClick={() => onSortChange(fieldName)}>
        <Flex gap='2'>
          <span>{columnName}</span>
          {filter.sortBy === fieldName && (
            filter.orderBy === 'asc' ? <BsSortDownAlt fontSize='1.3em' /> : <BsSortDown fontSize='1.3em' />
          )}
        </Flex>
      </Th>
    )
  }

  const copyToClipboard = (text: string) => () => {
    navigator.clipboard.writeText(text)
  }

  const depositWallet = (inscription: InscriptionData) => (
    inscription.user?.wallets?.filter(
      wallet => wallet.type === 'deposit'
    )[0]?.address
  );

  return (
    <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="80vw" h="60vh">
      <ChakraTable
        variant="striped"
        colorScheme="olaggPurple"
        fontSize="18px"
        position='relative'
      >
        <Thead>
          <Tr position='sticky' bg='gray.800' top='0' zIndex='100'>
            {sortableColumn('Jugador', 'user_id')}
            {sortableColumn('Ganador', 'winner')}
            {sortableColumn('Posicion', 'position')}
            {sortableColumn('XP', 'score')}
            {sortableColumn('Premios', 'prize')}
            <Th textAlign='center' color="white">Billetera depósito</Th>
            <Th textAlign='center' color="white">Acción</Th>
          </Tr>
        </Thead>
        <Tbody>
          {inscriptions.map((inscription, index) => (
            <Tr key={index}>
              <Td maxWidth='150px'>
                {`${inscription.user.name || 'Sin nombre'} (${inscription.user.id})`}
              </Td>
              <Td textAlign='center'>
                {inscription.type === 'Intention' ? 
                  'N/A (intención)' :
                  inscription.winner && <FaAward style={{ margin: '0 auto' }} color="#eeee00" /> 
                }
              </Td>
              <Td textAlign='center'>{inscription.position}</Td>
              <Td textAlign='center'>{inscription.score}</Td>
              <Td textAlign='center'>
                <Box>
                  {(inscription.prize?.blockchainToken || 0 > 0) && `${inscription.prize?.blockchainToken} ${inscription.blockchainToken?.name} `}
                  {inscription.prize?.other}
                </Box>
              </Td>
              <Td textAlign='center'>{depositWallet(inscription) && (
              <>
                <Tooltip label={depositWallet(inscription)}>
                  {`${depositWallet(inscription).slice(0, 5)}...${depositWallet(inscription).slice(-5)}`}
                </Tooltip>
                <Button
                  minW='1rem'
                  height='1rem'
                  ml='0.4rem' p='0'
                  background={'none'}
                  _hover={{ background: 'none', color: 'gray.500' }}
                  onClick={copyToClipboard(depositWallet(inscription))}
                >
                  <FaRegClone color="inherit" />
                </Button>
              </>
              )}</Td>
              <Td textAlign='center'>
                <Flex gap='3' justifyContent={'center'}>
                  <Popover>
                    <PopoverTrigger>
                      <Button
                        size={'sm'} padding='0px 8px' ml={0} colorScheme="red"
                        isDisabled={missingPermission(OlaggModule.Inscription, PermissionEnum.DELETE)}
                      >
                        Eliminar
                      </Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Button width={'100%'} colorScheme='red' onClick={() => removeInscription(inscription)}>
                            Confirmar eliminar
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  )
}

export default Table;

import { Box, Center, Heading, Spinner, VStack } from "@chakra-ui/react";

import Form from "./Form";
import useBlockchainTokenCatalog from "../../hooks/useBlockchainTokenCatalog";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const EditBlockchainToken = () => {
  const params = useParams();
  const id = params.id!
  const { editBlockchainToken, getBlockchainToken, blockchainToken, isLoading } = useBlockchainTokenCatalog();

  const onSubmit = async (data: FormData) => {
    editBlockchainToken(Number(id), data);
  }

  useEffect(() => {
    getBlockchainToken(Number(id));
  }, [id])

  return (
    <>
      <Center w={'full'} pb={4}>
        <VStack color={'white'}>
          <Heading my={2}>
              Editar Blockchain Token
          </Heading>
          {
            isLoading ? (
              <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
                <Spinner />
              </Box>
            ) : (
              <Form blockchainToken={blockchainToken} onSubmit={onSubmit} />
            )
          }
        </VStack>
      </Center>
    </>
  )
}

export default EditBlockchainToken;

import React, { useState } from 'react';
import { Input, Button, VStack, HStack, Text } from '@chakra-ui/react';
import { useFormContext, Controller } from 'react-hook-form';
import { FiPlus } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";

interface ArrayStringInputProps {
  label: string;
  controlName: string;
  required?: boolean;
  placeholder?: string;
}

const ArrayStringInput = ({ 
  label, 
  controlName, 
  required, 
  placeholder = 'Enter new value'
}: ArrayStringInputProps) => {
  const { control, trigger } = useFormContext();
  const [newValue, setNewValue] = useState('');

  const ensureArray = (value: any): string[] => {
    if (!value) return [];
    if (typeof value === 'string') return [value];
    return Array.isArray(value) ? value : [];
  };

  const handleAddValue = async (valueToAdd: string, currentValues: string[], onChange: (value: string[]) => void) => {
    if (valueToAdd.trim()) {
      const newValues = [...currentValues, valueToAdd.trim()];
      onChange(newValues);
      setNewValue('');
      await trigger(controlName);
    }
  };

  const handleRemoveValue = async (index: number, currentValues: string[], onChange: (value: string[]) => void) => {
    const newValues = currentValues.filter((_, i) => i !== index);
    onChange(newValues);
    await trigger(controlName);
  };

  return (
    <Controller
      name={controlName}
      control={control}
      defaultValue={[]}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        const values = ensureArray(value);
        
        return (
          <VStack align="stretch" spacing={1} marginBottom={2} w="full">
            <Text fontWeight="medium">
              {label} {required && <span style={{color: "red"}}>*</span>}
            </Text>
            
            <VStack align="stretch" spacing={2}>
              {values.map((item, index) => (
                <HStack key={index}>
                  <Input 
                    value={item} 
                    isReadOnly 
                    className="flex-1"
                  />
                  <Button
                    size="sm"
                    colorScheme="red"
                    variant="solid"
                    onClick={() => handleRemoveValue(index, values, onChange)}
                  >
                    <IoMdClose className="h-4 w-4" />
                  </Button>
                </HStack>
              ))}
            </VStack>

            <HStack>
              <Input
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                placeholder={placeholder}
                className="flex-1"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddValue(newValue, values, onChange);
                  }
                }}
              />
              <Button
                size={"sm"}
                colorScheme="blue"
                variant={"solid"}
                onClick={() => handleAddValue(newValue, values, onChange)}
              >
                <FiPlus className="h-4 w-4" />
              </Button>
            </HStack>

            {error && (
              <Text color="red.500" fontSize="sm" mt={1}>
                {error.message}
              </Text>
            )}

            {required && values.length === 0 && (
              <Text color="red.500" fontSize="sm" mt={1}>
                Debe agregar al menos un valor
              </Text>
            )}
          </VStack>
        );
      }}
    />
  );
};

export default ArrayStringInput;
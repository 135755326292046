import {
    Flex,
    TableContainer,
    Table as ChakraTable,
    Thead,
    Tr,
    Th,
    Tbody,
    Spinner,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import {
    fetcher,
    AdminPaymentsEndpoints,
} from '@olagg/api-hooks'

import { ThirdwebProvider } from 'thirdweb/react'
import Web3ResumeItem from "./web3ResumeItem";
import { defineChain } from "thirdweb";

const Web3Payments = ({ tabIndex }: { tabIndex: number }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [payments, setPayments] = useState([])

    const initRequest = useCallback(async () => {
        setIsLoading(true)
        await fetcher(
            AdminPaymentsEndpoints
                .resume()
        ).then((response) => {
            setPayments(response)
        })
        setIsLoading(false)
    }, [])

    useEffect(() => {
        if (tabIndex == 4) initRequest()

    }, [tabIndex])

    const PaymentItem = ({ data }: { data: {} }) => {
        let rpcUrl = data?.rpcUrl
        if (rpcUrl && rpcUrl.includes('thirdweb')) rpcUrl = `${rpcUrl}/${import.meta.env.VITE_THIRDWEB_CLIENT_ID}`
        const chain = defineChain({
            chainId: data?.chainId,
            rpc: [rpcUrl],
            name: data?.network,
            nativeCurrency: {
                name: data?.network,
                symbol: data?.symbol
            },
        });

        return (
            <ThirdwebProvider>
                <Web3ResumeItem payment={data} chain={chain} />
            </ThirdwebProvider>
        )
    }

    return (
        <>
            <Flex w={'full'} py={4} justifyContent='center' flexDirection={'column'}>
                <Flex w='full' justifyContent='center' px={'20px'} pb={'15px'}>
                    {isLoading ? (
                        <Flex w={'full'} justifyContent='center' alignItems='center' minH={'200px'}>
                            <Spinner color="white" />
                        </Flex>
                    ) : (

                        <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="100%" maxH="60vh">
                            <ChakraTable
                                variant="striped"
                                size="sm"
                                colorScheme="olaggPurple"
                                fontSize="18px"
                                position='relative'
                                whiteSpace={'normal'}
                            >
                                <Thead>
                                    <Tr position='sticky' bg='gray.800' top='0' zIndex='100'>
                                        <Th py={'20px'} color="white">Red</Th>
                                        <Th py={'20px'} color="white">Token</Th>
                                        <Th py={'20px'} color="white">Total</Th>
                                        <Th py={'20px'} color="white">Aprobados</Th>
                                        <Th py={'20px'} color="white"></Th>
                                        <Th py={'20px'} color="white"></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {payments?.map((payment, index) => (
                                        <PaymentItem key={index} data={payment} />
                                    ))}
                                </Tbody>
                            </ChakraTable>
                        </TableContainer>
                    )}
                </Flex>
            </Flex>
        </>
    )
};

export default Web3Payments;

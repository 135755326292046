import {
    Tr, Td, Flex, Button, Text, useDisclosure, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { ReadBalance } from "./web3/readBalance";
import { ReadBalanceNative } from "./web3/readBalanceNative";
import { CheckAllowance } from "./web3/checkAllowance";
import { ApproveAllowance } from "./web3/approveAllowance";
import { ExecuteAirdrop } from "./web3/executeAirdrop";
import { ExecuteAirdropNative } from "./web3/executeAirdropNative";

import { createThirdwebClient } from "thirdweb";
import { ConnectButton, useActiveWallet, useActiveWalletChain, useSwitchActiveWalletChain } from "thirdweb/react";
import s from './web3/styles/ConnectButton.module.css';

const client = createThirdwebClient({ clientId: import.meta.env.VITE_THIRDWEB_CLIENT_ID });

const Web3ResumeItem = ({ payment, chain }: { payment: any, chain: any }) => {

    const isNative = payment?.tokenType == 'NATIVE'
    const [total, setTotal] = useState<number>(0)

    const wallet = useActiveWallet();
    const activeChain = useActiveWalletChain();

    const [account, setAccount] = useState<any>(undefined)

    useEffect(() => {
        if (wallet) {
            setAccount(wallet?.getAccount())
        }
    }, [wallet])

    const switchChain = useSwitchActiveWalletChain();

    const handleSwitchChain = async () => {
        let newChain = chain
        newChain.id = chain.chainId
        delete newChain.chainId
        switchChain(newChain);
    }

    useEffect(() => {
        let calcTotal = 0
        payment?.payments.map((p: any) => {
            calcTotal += p.quantity
        })
        setTotal(calcTotal)
    }, [])

    const [count, setCount] = useState<number>(0)
    const [needsApproval, setNeedsApproval] = useState<boolean>(true)
    const [isCheckingAllowance, setIsCheckingAllowance] = useState(true)
    const [isOwnerConnected, setIsOwnerConnected] = useState(false)

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Tr color="white">
            <Td py={'20px'}>{payment?.network}</Td>
            <Td py={'20px'}>{payment?.symbol}</Td>
            <Td py={'20px'}>{payment?.total}</Td>
            <Td py={'20px'}>{payment?.quantity}</Td>
            <Td py={'20px'}>
            </Td>
            <Td py={'20px'}>
                <Button onClick={onOpen} variant={'outline'}>Operar</Button>
                <Modal isOpen={isOpen} onClose={onClose} isCentered>
                    <ModalOverlay />
                    <ModalContent mx={5} backgroundColor='#190432'>
                        <ModalCloseButton color={'white'} />
                        <ModalHeader>
                            <Text color='white'>Operar en {payment?.network}</Text>
                        </ModalHeader>
                        <ModalBody px='20px' pb='20px' color='white'>
                            <ConnectButton
                                appMetadata={{
                                    name: 'Ola GG',
                                    url: 'https://olagg.io'
                                }}
                                client={client}
                                walletConnect={{
                                    projectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID
                                }}
                                connectButton={{
                                    label: 'Conectar Wallet',
                                    className: s.connectButton,
                                }}
                                connectModal={{
                                    showThirdwebBranding: false,
                                    size: 'wide'
                                }}
                                detailsButton={{
                                    className: s.detailsButton,
                                }}
                                detailsModal={{
                                    hideBuyFunds: true,
                                    hideSwitchWallet: true,
                                    showTestnetFaucet: false,
                                }}
                                switchButton={{
                                    label: 'Conectar Wallet',
                                    className: s.connectButton,
                                }}
                            />

                            {(account?.address && activeChain?.id == payment?.chainId) && (
                                <>
                                    <Flex gap='16px' p='20px' w='full'>
                                        <Flex flexDirection={'column'} gap='10px' w='full'>
                                            <Flex w='full' fontSize={'18px'}>
                                                <Flex>Total a pagar:</Flex>
                                                <Flex ml='auto' fontWeight={'700'}>
                                                    {total} {payment?.symbol}
                                                </Flex>
                                            </Flex>
                                            {isNative ? (
                                                <ReadBalanceNative walletAddress={account?.address} chain={activeChain} client={client} />
                                            ) : (
                                                <>
                                                    <ReadBalance symbol={payment?.symbol} walletAddress={account?.address} chain={activeChain} client={client} contractAddress={payment?.contractAddress} />
                                                    <Flex gap='5px' w='full'>
                                                        <CheckAllowance
                                                            setIsCheckingAllowance={setIsCheckingAllowance}
                                                            count={count}
                                                            symbol={payment?.symbol}
                                                            total={total}
                                                            setNeedsApproval={setNeedsApproval}
                                                            airdropContractAddress={payment?.airdropContractAddress}
                                                            contractAddress={payment?.contractAddress}
                                                            walletAddress={account?.address}
                                                            chain={activeChain}
                                                            client={client}
                                                        />
                                                    </Flex>
                                                </>
                                            )}
                                        </Flex>
                                    </Flex>
                                    {isNative ? (
                                        <ExecuteAirdropNative
                                            setIsOwnerConnected={setIsOwnerConnected}
                                            airdropContractAddress={payment?.airdropContractAddress}
                                            payments={payment?.payments}
                                            rpc={payment?.rpcUrl}
                                            walletAddress={account?.address}
                                            chain={activeChain}
                                            client={client}
                                        />
                                    ) : (
                                        <>
                                            <Flex p='20px' flexDirection={'column'} gap='10px' w='full'>
                                                {needsApproval && (
                                                    <ApproveAllowance
                                                        count={count}
                                                        verifyAllowance={setCount}
                                                        airdropContractAddress={payment?.airdropContractAddress}
                                                        contractAddress={payment?.contractAddress}
                                                        payments={payment?.payments}
                                                        walletAddress={account?.address}
                                                        chain={activeChain}
                                                        client={client}
                                                    />
                                                )}

                                                {!needsApproval && (
                                                    <ExecuteAirdrop
                                                        setIsOwnerConnected={setIsOwnerConnected}
                                                        airdropContractAddress={payment?.airdropContractAddress}
                                                        contractAddress={payment?.contractAddress}
                                                        payments={payment?.payments}
                                                        walletAddress={account?.address}
                                                        chain={activeChain}
                                                        client={client}
                                                    />
                                                )}
                                            </Flex>
                                        </>
                                    )}
                                </>
                            )}

                            {(account?.address && activeChain?.id != payment?.chainId) && (
                                <Flex flexDirection={'column'} py='20px' gap='12px'>
                                    <Text>Conectado a diferente Network</Text>
                                    <Button variant={'outline'} onClick={handleSwitchChain}>Cambiar Network</Button>
                                </Flex>
                            )}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </Td>
        </Tr >
    )
};

export default Web3ResumeItem;
